import React from "react";
import ReactDOM from "react-dom";
import "./i18n.js";
import Loading from "ui321/Loading.js";
import {validateMetadataCache} from "ui321/ResourceMetadata.js";
import App from "app/App";
// import registerServiceWorker from "./registerServiceWorker";

validateMetadataCache(process.env.REACT_APP_BUILD_ID);

ReactDOM.render(
  <React.Suspense fallback={<Loading />}>
    <App />
  </React.Suspense>
  , document.getElementById("root"));
// registerServiceWorker();
// unregister previous service workers
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for(let registration of registrations) {
      registration.unregister();
    }
  });
}
