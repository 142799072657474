import i18n from "i18n.js";
import en_i18n from "./i18n/en.ACLRoles.json";
import ru_i18n from "./i18n/ru.ACLRoles.json";
import FullView from "./FullView.js";

const resourceType = "ACLRoles";

i18n.addResourceBundle("en", resourceType, en_i18n);
i18n.addResourceBundle("ru", resourceType, ru_i18n);

const ACLRoles = {
  resourceType,
  FullView,
  titleField: "name",
  columns: [
    "name",
    "description",
    "date_entered",
    "created_by",
    "date_modified",
    "modified_user_id",
  ],
  creationAccess: true,
};

export default ACLRoles
