import React from 'react';
import { Icon, Header, Segment } from 'semantic-ui-react'

const PageNotImplemented = () =>
  <Segment placeholder>
    <Header icon>
      <Icon name='rocket' />
      Страница в разработке&hellip;
    </Header>
  </Segment>

export default PageNotImplemented
