/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import {useTranslation} from "react-i18next";

function ResourceTypeLabel(props) {
  const {t} = useTranslation(props.resourceType);
  return t(props.resourceType, {context: "resourceType"});
}

export default ResourceTypeLabel
