import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Card, Divider, Dropdown, Grid, Header, Icon, Menu, Search, Segment, Tab} from "semantic-ui-react";
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/funnel";
import "echarts/lib/chart/sunburst";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";

function Home(props) {
  const {t} = useTranslation();
  const panes = [
  {
    menuItem: t("Bookmarks"),
    render: () => (
      <Header>
        {props.title}
      </Header>
    )
  },
  {
    menuItem: t("Bookmarks") + " 2",
    render: () => <Tab1 />,
  },
  {
    menuItem: t("Statistics"),
    render: () => <Tab2 />,
  },
  {
    menuItem: (
      <Menu.Item key="settings" position="right">
        <Icon name="cog" />
      </Menu.Item>
    ),
    render: () => <Tab.Pane attached={false}>Настройка панели&hellip;</Tab.Pane>,
  },
];
  return (
  <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
  );
}

const Tab1 = props =>
  <>
    <Segment placeholder>
      <Grid columns={2} stackable textAlign="center">
        <Divider vertical>Или</Divider>

        <Grid.Row verticalAlign="middle">
          <Grid.Column>
            <Header icon>
              <Icon name="search" />
              Поиск клиента
            </Header>

            <Search placeholder="ИНН..." />
          </Grid.Column>

          <Grid.Column>
            <Header icon>
              <Icon name="plus" />
              Создать
            </Header>
            <div>
            <Button.Group positive labeled icon>
              <Button positive>
                <Icon name="user plus" /> Предконтакт
              </Button>
              <Dropdown floating labeled button className="icon"
                icon="plus cart"
                trigger={<span>Заявка <Icon name="caret down" /></span>}
              >
                <Dropdown.Menu>
                  {[
                    { value: "save1", text: "РКО"},
                    { value: "save2", text: "З/П"},
                    { value: "save3", text: "Кредитная"},
                  ].map((option) => (
                    <Dropdown.Item key={option.value} {...option} />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Button.Group>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </>

const Tab2 = props =>
  <>
    <Card.Group itemsPerRow={2} stackable>
      <Card>
        <Card.Content>
          <Card.Header>Воронка</Card.Header>
          <Card.Description>
            <LeadsProductsStatusesFunnel theme="light" />
          </Card.Description>
        </Card.Content>
      </Card>
      <Card>
        <Card.Content>
          <Card.Header>Круговая диаграмма</Card.Header>
          <Card.Description>
            <LeadsProductsStatusesSunburst theme="light" />
          </Card.Description>
        </Card.Content>
      </Card>
      <Card>
        <Card.Content>
          <Card.Header>Полосовая диаграмма</Card.Header>
          <Card.Description>
            <LeadsProductsStatusesBars theme="light" />
          </Card.Description>
        </Card.Content>
      </Card>
      <Card>
        <Card.Content>
          <Card.Header>Количество звонков/встреч</Card.Header>
          <Card.Description>
            <ActivitiesBars theme="light" />
          </Card.Description>
        </Card.Content>
        <Card.Content extra textAlign="center">
          <Button.Group basic>
            <Button>Год</Button>
            <Button>Месяц</Button>
            <Button active>Неделя</Button>
          </Button.Group>
        </Card.Content>
      </Card>
    </Card.Group>

    {/*
    <Card.Group>
      <Card>
        <Card.Content extra>
            <a>
              <Icon name="caret right" title="Развернуть" floated="right" />
              Стадии продаж по месяцам
            </a>
        </Card.Content>
      </Card>
      <Card>
        <Card.Content extra>
            <a>
              <Icon name="caret right" title="Развернуть" floated="right" />
              Все заявки по источникам
            </a>
        </Card.Content>
      </Card>
      <Card>
        <Card.Content extra>
            <a>
              <Icon name="caret right" title="Развернуть" floated="right" />
              Диаграмма стадий моих продаж
            </a>
        </Card.Content>
      </Card>
      <Card>
        <Card.Content extra>
            <a>
              <Icon name="caret right" title="Развернуть" floated="right" />
              Диаграмма стадий общих продаж
            </a>
        </Card.Content>
      </Card>
      <Card>
        <Card.Content extra>
            <a>
              <Icon name="caret right" title="Развернуть" floated="right" />
              Заявки з/п в работе
            </a>
        </Card.Content>
      </Card>
      <Card>
        <Card.Content extra>
            <a>
              <Icon name="caret right" title="Развернуть" floated="right" />
              Мои открытые задачи
            </a>
        </Card.Content>
      </Card>
    </Card.Group>
    */}
  </>

class LeadsProductsStatusesFunnel extends React.Component {
  componentDidMount() {
    this.chart = echarts.init(this.elem, this.props.theme);
    const options = {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c}%"
      },
      legend: {
        data: ["Создано","Перешли в работу","Заявка","Продукт оформлен"]
      },
      calculable: true,
      series: [
        {
          name:"Всего",
          type:"funnel",
          left: "10%",
          top: 60,
          bottom: 60,
          width: "70%",
          min: 0,
          max: 100,
          minSize: "0%",
          maxSize: "100%",
          sort: "descending",
          gap: 2,
          label: {
                normal: {
                    formatter: "{b}"
                },
                emphasis: {
                    position:"inside",
                    formatter: "{b}: {c}%"
                }
            },
          labelLine: {
              normal: {
                  show: false
              }
          },
          itemStyle: {
              normal: {
                  opacity: 0.7
              }
          },
          emphasis: {
            label: {
              fontSize: 20
            }
          },
          data: [
            {value: 100, name: "Создано"},
            {value: 80, name: "Перешли в работу"},
            {value: 30, name: "Заявка"},
            {value: 10, name: "Продукт оформлен"},
          ]
        },
        {
            name: 'Относительно "Создано"',
            type: "funnel",
            left: "10%",
            width: "70%",
            maxSize: "80%",
            label: {
                normal: {
                    position: "inside",
                    formatter: "{c}%",
                    textStyle: {
                        color: "#fff"
                    }
                },
                emphasis: {
                    position:"inside",
                    formatter: "{b}: {c}%"
                }
            },
            itemStyle: {
                normal: {
                    opacity: 0.5,
                    borderColor: "#fff",
                    borderWidth: 2
                }
            },
            data: [
              {value: 100, name: "Создано"},
              {value: 60, name: "Перешли в работу"},
              {value: 20, name: "Заявка"},
              {value: 5, name: "Продукт оформлен"},
            ]
        }
      ]
    };

    this.chart.setOption(options);
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  render() {
    return <div ref={el => this.elem = el} style={{height:"400px"}} />;
  }
}

class LeadsProductsStatusesSunburst extends React.Component {
  componentDidMount() {
    this.chart = echarts.init(this.elem, this.props.theme);

    const data = [{
      name: "Москва",
      children: [{
            name: "Москва-ЮГ",
            value: 90,
        }, {
            name: "Москва-Север",
            value: 60,
        }]
      }, {
        name: "СЗРД",
        children: [
          {
            name: "Архангельская ТД",
            children: [{
                name: 'Операционный офис "Архангельский"',
                value: 30
            }]
          },
          {
            name: "Калининградская ТД",
            children: [{
                name: 'Операционный офис "Калининградский"',
                value: 20
            }]
          },
          {
            name: "Петербургская ТД",
            children: [{
                name: "Операционный офис в г.Сыктывкар",
                value: 20
            }, {
                name: 'Филиал ОАО "УРАЛСИБ" в г.Санкт-Петербург',
                value: 60
            }]
        }
        ]
    }];

    const options = {
    series: {
        type: "sunburst",
        highlightPolicy: "ancestor",
        data: data,
        radius: [0, "90%"],
        label: {
            rotate: "0"
        },
    }
};

    this.chart.setOption(options);
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  render() {
    return <div ref={el => this.elem = el} style={{height:"400px"}} />;
  }
}

class LeadsProductsStatusesBars extends React.Component {
  componentDidMount() {
    this.chart = echarts.init(this.elem, this.props.theme);

    const options = {
    tooltip : {
        trigger: "axis",
        axisPointer : {
            type : "shadow"
        }
    },
    legend: {
        data: ["База", "ДКО","Клиент банка","Самоход","Агент"]
    },
    grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true
    },
    xAxis:  {
        type: "value"
    },
    yAxis: {
        type: "category",
        data: ["Отказ банка","Отказ клиента","Продукт оформлен","Заявка","Перешли в работу","Создано"]
    },
    series: [
        {
            name: "База",
            type: "bar",
            stack: "Количество",
            label: {
                normal: {
                    show: true,
                    position: "insideRight"
                }
            },
            data: [5, 10, 50, 60, 100, 110]
        },
        {
            name: "ДКО",
            type: "bar",
            stack: "Количество",
            label: {
                normal: {
                    show: true,
                    position: "insideRight"
                }
            },
            data: [10, 20, 60, 70, 110, 120]
        },
        {
            name: "Клиент банка",
            type: "bar",
            stack: "Количество",
            label: {
                normal: {
                    show: true,
                    position: "insideRight"
                }
            },
            data: [15, 21, 55, 66, 108, 115]
        },
        {
            name: "Самоход",
            type: "bar",
            stack: "Количество",
            label: {
                normal: {
                    show: true,
                    position: "insideRight"
                }
            },
            data: [16, 21, 56, 67, 107, 117]
        },
        {
            name: "Агент",
            type: "bar",
            stack: "Количество",
            label: {
                normal: {
                    show: true,
                    position: "insideRight"
                }
            },
            data: [17, 25, 61, 71, 121, 125]
        }
    ]
};

    this.chart.setOption(options);
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  render() {
    return <div ref={el => this.elem = el} style={{height:"400px"}} />;
  }
}

class ActivitiesBars extends React.Component {
  componentDidMount() {
    this.chart = echarts.init(this.elem, this.props.theme);

    const app = {
      config: {
        rotate: 90,
        align: "left",
        verticalAlign: "middle",
        position: "insideBottom",
        distance: 15,
      }
    };

    const labelOption = {
      normal: {
          show: true,
          position: app.config.position,
          distance: app.config.distance,
          align: app.config.align,
          verticalAlign: app.config.verticalAlign,
          rotate: app.config.rotate,
          formatter: "{c}  {name|{a}}",
          fontSize: 16,
          rich: {
              name: {
                  textBorderColor: "#fff"
              }
          }
      }
  };

    const options = {
    // color: ["#003366", "#006699", "#4cabce", "#e5323e"],
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "shadow"
        }
    },
    legend: {
        data: ["Звонки", "Встречи"]
    },
    toolbox: {
        show: true,
        orient: "vertical",
        left: "right",
        top: "center",
        feature: {
            mark: {show: true},
            dataView: {show: true, readOnly: false},
            magicType: {show: true, type: ["line", "bar", "stack", "tiled"]},
            restore: {show: true},
            saveAsImage: {show: true}
        }
    },
    calculable: true,
    xAxis: [
        {
            type: "category",
            axisTick: {show: false},
            data: ["Пн", "Вт", "Ср", "Чт", "Пт"]
        }
    ],
    yAxis: [
        {
            type: "value"
        }
    ],
    series: [
        {
            name: "Звонки",
            type: "bar",
            barGap: 0,
            label: labelOption,
            data: [320, 332, 301, 334, 390]
        },
        {
            name: "Встречи",
            type: "bar",
            label: labelOption,
            data: [80, 99, 90, 85, 75]
        }
    ]
};

    this.chart.setOption(options);
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  render() {
    return <div ref={el => this.elem = el} style={{height:"400px"}} />;
  }
}

export default Home
