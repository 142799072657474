/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ResourceMetadata from "ui321/ResourceMetadata.js";
import CollectionNavigation, {defaultUrlBuilder} from "ui321/collection/CollectionNavigation.js";
import JsonApiSchema from "ui321/json/JsonApiSchema.js";
import DataTable from "ui321/collection/DataTable.js";
import ColumnHead from "ui321/collection/ColumnHead.js";
import FieldCell from "ui321/collection/FieldCell.js";
import FilterField from "ui321/fields/FilterField.js"
import {filterToAndList} from "ui321/nav/Filtering.js";
import {useResourceSettings} from "ui321/ResourceSettings.js";
import {ResourceCollectionContext} from "ui321/single/SingleResource.js";
import {CreationButton, useCreatedResourcesFilter,
  ResourceCreationContext} from "ui321/single/ResourceCreation.js";

function ResourceCollectionNavigation(props) {
  return (
    <ResourceMetadata
      resourceType={props.resourceType}
      render={metadata =>
        <CollectionNavigationWithMetadata
          {...props}
          metadata={metadata}
        />
      }
    />
  );
}

function CollectionNavigationWithMetadata(props) {
  const resourceType = props.resourceType;
  const {t} = useTranslation(resourceType);
  const settings = useResourceSettings(resourceType);

  const metadata = props.metadata;
  const jsonSchema = props.metadata.jsonSchema;
  const urlBuilder = state => {
    const url = defaultUrlBuilder(state);
    const includeResources =
      (Object.keys((((jsonSchema.properties || {}).relationships || {}).properties || {})))
        .filter(field => {
          const relSchema = JsonApiSchema.findRelationshipSchema(field, jsonSchema);
          // const relatedResourceType = JsonApiSchema.getRelationshipResourceType(relSchema);
          // const relatedResourceType1 = relatedResourceType && typeof relatedResourceType === "object"
          //   ? relatedResourceType[0] : relatedResourceType;
          return relSchema.type !== "array"
            // && !!this.props.allSettings.get(relatedResourceType1).titleField
            // && this.props.fieldNames.indexOf(field) !== -1
        });
      return url + "&include=" + includeResources.join(",")
  };

  const settingsFieldNames = settings.columns
    ? settings.columns.map(col => typeof col === "string" ? col : col.key)
    : settings.fieldNames;
  const settingsColumnsMap = (settings.columns || []).reduce((m, col) =>
    typeof col === "object" ? {...m, [col.key]: col} : m, {});
  const fieldNames = settingsFieldNames || JsonApiSchema.listFieldNames(jsonSchema);
  const sortableFields = settings.sortableFields || fieldNames.filter(fieldName => {
    const attrSchema = JsonApiSchema.findAttributeSchema(fieldName, jsonSchema);
    const attrSchema1 = JsonApiSchema.fromNullable(attrSchema);
    return attrSchema1 && !attrSchema.enum && !attrSchema1.enum;
  });
  const filterableFields = settings.filterableFields || fieldNames.filter(fieldName => {
    const attrSchema = JsonApiSchema.findAttributeSchema(fieldName, jsonSchema);
    const attrSchema1 = JsonApiSchema.fromNullable(attrSchema);
    const relSchema = JsonApiSchema.findRelationshipSchema(fieldName, jsonSchema);
    const relatedResourceType = relSchema ? JsonApiSchema.getRelationshipResourceType(relSchema) : null;
    return (relSchema && typeof relatedResourceType === "string")
      || (attrSchema1 && attrSchema1.type === "string" && !attrSchema1.format)
      || (attrSchema1 && (attrSchema1.type === "string" || attrSchema1.type === "number")
                      && (attrSchema.enum || attrSchema1.enum))
  });
  const filtersOptions = filterableFields.map(fieldName => {
    const attrSchema = JsonApiSchema.findAttributeSchema(fieldName, jsonSchema);
    const attrSchema1 = JsonApiSchema.fromNullable(attrSchema);
    const isStringFilter = attrSchema1 && attrSchema1.type === "string" && !attrSchema.enum && !attrSchema1.enum
    const filterType = isStringFilter ? "StartsWith_CI" : "Equals";
    return {
      key: fieldName,
      label: t(fieldName, {context: "fieldLabel"}),
      filters: [[filterType, fieldName, ""]],
    };
  });

  const columns = fieldNames.map(fieldName => ({
    key: fieldName,
    renderHead: (settingsColumnsMap[fieldName] || {}).renderHead !== undefined
      ? (settingsColumnsMap[fieldName] || {}).renderHead
      : (props => (
      <ColumnHead {...props}
        text={t(props.columnKey, {context: "fieldLabel"})}
        sortable={sortableFields.includes(props.columnKey)}
        filtersToAdd={(filtersOptions.find(v => v.key === props.columnKey) || {}).filters}
      />
    )),
    renderCell: (settingsColumnsMap[fieldName] || {}).renderCell !== undefined
      ? (settingsColumnsMap[fieldName] || {}).renderCell
      : FieldCell,
  }));

  const prependedColumns = props.prependColumns || defaultPrependColumns;
  const allColumns = [...prependedColumns, ...columns];

  const createdResourcesFilter = useCreatedResourcesFilter();
  const creationState = React.useContext(ResourceCreationContext);
  const middleButtons = (
    <>
      {props.middleButtons ? React.createElement(props.middleButtons) : null}
      {creationState.creationAccess !== undefined ? <CreationButton /> : null}
    </>
  );

  const defaultFilter = createdResourcesFilter || settings.defaultFilter;
  const defaultFilterList = defaultFilter && filterToAndList(defaultFilter);

  return (
    <CollectionNavigation {...props}
      className={`${resourceType}-collection-navigation`}
      baseUrl={props.baseUrl || `/${resourceType}`}
      urlBuilder={urlBuilder}
      filtersOptions={filtersOptions}
      filterTemplate={() =>
        <FilterField resourceType={resourceType} jsonSchema={jsonSchema} />
      }
      defaultParams={{
        filterList: defaultFilterList,
        sort: settings.defaultSort,
      }}
      defaultPageSize={settings.pageSize}
      middleButtons={middleButtons}
      renderResponse={props =>
        <CollectionResponse {...props}
          mergeMeta={metadata}
          renderCollection={props => {
            return (
              <DataTable
                columns={allColumns}
                data={props.data}
                emptinessLabel={t(["Not found", "ui321:Not found"])}
              />
            );
          }}
        />
      }
    />
  );
}

const defaultPrependColumns = [{
  key: "_resource_actions",
  className: "resource-actions-column",
  renderCell: ResourceActionsCell,
}];

function ResourceActionsCell(props) {
  const {t} = useTranslation("ui321");
  return (
    <div className="tr-hover-place">
      <div className="tr-hover-buttons">
        <Link className="ui button follow" to={`/${props.rowData.type}/${props.rowData.id}`}>
          {t("Follow")}
        </Link>
      </div>
    </div>
  );
}

const defaultIncluded = [];

function CollectionResponse(props) {
  const data = props.mergeMeta
    ? props.response.data.map(res => ({...res, meta: {...props.mergeMeta, ...res.meta}}))
    : props.response.data;
  return (
    <ResourceCollectionContext.Provider value={props.response.included || defaultIncluded}>
      { React.createElement(props.renderCollection, {
        data: data,
      }) }
    </ResourceCollectionContext.Provider>
  );
}

export {CollectionResponse};
export default ResourceCollectionNavigation
