/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Button, Dropdown, Icon, Modal} from "semantic-ui-react";
import {err401, useRequest} from "ui321/Request.js";
import {ResourceSettingsContext} from "ui321/ResourceSettings.js";
import ResourceCollectionNavigation from "ui321/json-api/ResourceCollectionNavigation.js";
import ResourceTypeLabel from "ui321/ResourceTypeLabel.js";
import JsonApiSchema from "ui321/json/JsonApiSchema.js";
import {ResourceContext, ResourceDispatchContext, ResourceCollectionContext,
  ResourceEditorContext, defaultEditor} from "ui321/single/SingleResource.js";
import {WithResourceCreation} from "ui321/single/ResourceCreation.js";
import {ApiConfigContext} from "ui321/json/Request.js";
import {ConnectionContext} from "ui321/Connection.js";
import {repairLinks} from "ui321/JsonApiSpec.js";
import {CollectionSelectionContext} from "ui321/collection/CollectionSelection.js";

function useSearchLink(resourceType, query) {
  const apiConfig = React.useContext(ApiConfigContext);
  const allSettings = React.useContext(ResourceSettingsContext);
  if (query === null) {
    return null;
  }
  const resourceSettings = allSettings.get(resourceType);
  const titleField = resourceSettings.titleField || "id";
  const titleFilterType = resourceSettings.titleFilterType || "StartsWith_CI";
  const titleFilter = query ? [titleFilterType, titleField, query] : null;
  const defaultFilter = resourceSettings.defaultFilter;
  const filtering = titleFilter && defaultFilter ? ["And", titleFilter, defaultFilter] : titleFilter || defaultFilter;
  const pageSize = resourceSettings.pageSize;
  //TODO: show user what the filter is
  const sorting = resourceSettings.defaultSort || [[titleField, "asc"]];
  const include = null;
  const link = `${apiConfig.urlPrefix}/${resourceType}`
        + (filtering || sorting || pageSize || include ? "?" : "")
        + (filtering ? "filter=" + encodeURIComponent(JSON.stringify(filtering)) : "")
        + (sorting ? "&sort=" + (
            typeof sorting === "string" ? sorting : sorting.map(s => s[1] === "desc" ? "-" + s[0] : s[0]).join(",")
        ) : "")
        + (pageSize ? "&page[size]=" + pageSize : "")
        + (include ? "&include=" + include.join(",") : "")
  return link;
}

function useSearch(page) {
  const {t} = useTranslation("ui321");
  const apiConfig = React.useContext(ApiConfigContext);
  const connection = React.useContext(ConnectionContext);
  const reconnect = connection.reconnect;
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const loads = React.useRef({});
  React.useEffect(() => {
    setError(null);
    if (!page || !page.selfLink) {
      setData(null);
    }
    else if (!page.data && !loads.current[page.selfLink]) {
      setIsLoading(true);
      loads.current[page.selfLink] = true;
      fetch(page.selfLink, apiConfig.init)
      .then(response => {
        if (!response.ok) {
          const message = response.status === 401 ? err401 : t(response.status, {context: "statusCode"});
          throw new Error(message || "Error");
        }
        return response;
      })
      .then(response => response.json())
      .then(json => {
        setData({
          data: json.data,
          firstLink: page.firstLink,
          selfLink: page.selfLink,
          nextLink: (repairLinks(json.links, page.selfLink) || {}).next,
        });
      })
      .catch(e => {
        loads.current[page.selfLink] = false;
        if (e.message === err401) {
          setError(t("401", {context: "statusCode"}));
          reconnect(() => {
            setError(null);
          }, t("401", {context: "statusCode"}));
        }
        else {
          setError(e.message || "ERROR");
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
    }
  }, [page, reconnect, t, apiConfig.init]);
  return [data, error, isLoading];
}

function usePagedSearch(firstLink) {
  const [allPages, setAllPages] = React.useState([]);
  const currentPages = firstLink ? allPages.filter(page => page.firstLink === firstLink) : [];
  React.useEffect(() => {
    if (firstLink && !currentPages.length) {
      setAllPages([...allPages, {
        data: null,
        firstLink: firstLink,
        selfLink: firstLink,
        nextLink: null,
      }]);
    }
  }, [allPages, firstLink, currentPages.length]);

  const lastPage = currentPages.length > 0 ? currentPages[currentPages.length - 1] : null;
  const [fetchedPage, error, isLoading] = useSearch(lastPage);
  React.useEffect(() => {
    const oldPage = fetchedPage ? allPages.find(page => page.selfLink === fetchedPage.selfLink) : null;
    if (!error && fetchedPage && fetchedPage.data && oldPage && !oldPage.data) {
      setAllPages(allPages.map(page =>
        page.selfLink === fetchedPage.selfLink ? fetchedPage : page,
      ));
    }
  }, [fetchedPage, allPages, isLoading, error]);

  const loadNextPage = lastPage && lastPage.nextLink ? () => {
    setAllPages([...allPages, {
      data: null,
      firstLink: lastPage.firstLink,
      selfLink: lastPage.nextLink,
      nextLink: null,
    }]);
  } : null;
  const items = currentPages.map(page => page.data || []).flat();
  return [items, error, isLoading, loadNextPage];
}

function itemProps(value, content) {
  return {key: value, value: value, text: content, content: content}
}

function itemContent(item, template, titleField, searchQuery) {
  return template
    ? React.createElement(template, {
      data: item,
      placeType: searchQuery === null ? PlaceTypes.DROPDOWN_VALUE : PlaceTypes.DROPDOWN_OPTION,
    })
    : ((titleField && titleField !== "id" && item.attributes && item.attributes[titleField])
       || item.id || item);
}

function ToOneRelationshipDropdown(props) {
  const resource = React.useContext(ResourceContext);
  const {t} = useTranslation(resource.type);
  const allSettings = React.useContext(ResourceSettingsContext);
  const titleField = allSettings.get(props.resourceType).titleField || "id";
  const contextIncluded = React.useContext(ResourceCollectionContext);
  const [searchQuery, setSearchQuery] = React.useState(null);
  //TODO: make requests with fixed minimal time interval (not on every letter typed)
  const searchLink = useSearchLink(props.resourceType, searchQuery);
  const [items, error, isLoading, loadNextPage] = usePagedSearch(searchLink);
  const valueIsInItems = !props.multiple && props.value && props.value.id && items.find(item => item.id === props.value.id);
  const valueAsInContext = !props.multiple && JsonApiSchema.findResource(props.value, props.localIncluded.concat(contextIncluded));
  const viewIsDefault = !props.template && (!titleField || titleField === "id");
  const requestByIdNeeded = !props.multiple && props.value && props.value.id
    && searchQuery === null && !viewIsDefault
    && !valueIsInItems && !valueAsInContext; //When somebody loads resource parts, he should set included first and then resource itself
  const {response: responseById} = useRequest(requestByIdNeeded ? `/${props.resourceType}/${props.value.id}` : null);
  const valueAsInResponseById = (responseById || {}).data;

  const {setLocalIncluded} = props;
  React.useEffect(() => {
    if (valueAsInResponseById) {
      setLocalIncluded([valueAsInResponseById]);
    }
  }, [valueAsInResponseById, setLocalIncluded]);

  React.useEffect(() => {if (error) console.warn(error)}, [error]);

  const empty = t([`Empty_${props.name}`, "ui321:Empty"], {context: "option"});
  const options = [
    ...(searchQuery === "" && !props.multiple ? [itemProps(null, empty)] : []),
    ...items.map(item => itemProps(item.id, itemContent(item, props.template, titleField, searchQuery))),
    ...(searchQuery === null && !props.multiple && props.value && props.value.id && !valueIsInItems
      ? [itemProps(props.value.id, itemContent(
          valueAsInContext || valueAsInResponseById || props.value
          , props.template, titleField, searchQuery
        ))]
      : []),
    ...(props.multiple && Array.isArray(props.value)
      ? props.value.map(idObj => itemProps(idObj.id, itemContent(
          JsonApiSchema.findResource(idObj, props.localIncluded.concat(contextIncluded))
          || idObj.id
          , props.template, titleField, searchQuery
        )))
      : []),
  ];
  const dropdownValue = Array.isArray(props.value) || props.multiple
    ? (props.value || []).map(idObj => idObj.id)
    : (props.value ? props.value.id : null);

  const handleChange = (e, {value}) => {
    if (Array.isArray(value)) {
      const idObjects = value.map(id => ({id: id, type: props.resourceType}));
      const resources = idObjects.map(idObj => JsonApiSchema.findResource(idObj, items)).filter(r => !!r);
      props.onChange(idObjects, resources);
    } else {
      const idObj = value ? {type: props.resourceType, id: value} : null;
      const resource = idObj && JsonApiSchema.findResource(idObj, items);
      props.onChange(idObj, resource);
    }
  }

  return (
    <Dropdown
      multiple={props.multiple}
      clearable={props.multiple}
      fluid
      selection
      className="to-one-relationship"
      error={!!error}
      options={options}
      placeholder={empty}
      value={dropdownValue}
      selectOnBlur={false}
      selectOnNavigation={false}
      onChange={handleChange}
      searchQuery={searchQuery || ""}
      onSearchChange={(e, {searchQuery}) => {
        setSearchQuery(searchQuery)
      }}
      onOpen={() => {
        if (searchQuery === null) {
          setSearchQuery("");
        }
      }}
      onClose={() => {
        setSearchQuery(null);
      }}
      loading={isLoading}
      noResultsMessage={t("ui321:Not found")}
      search={(options, query) => options}
      header={
        loadNextPage &&
        <Dropdown.Header>
          <Button type="button"
            size="mini" compact
            onClick={e => {
              loadNextPage();
              // onBlur triggered, then onClose, setSearchQuery(null), onOpen, so use timeout to restore searchQuery
              setTimeout(() => {
                setSearchQuery(searchQuery)
              }, 10);
              }}>
            {t("ui321:Load more")}
          </Button>
        </Dropdown.Header> }
    />
  );
}

function ToOneRelationshipChooser(props) {
  const {t} = useTranslation("ui321");
  const [opened, setOpened] = React.useState(false);
  const onChange = props.onChange;
  const selection = props.multiple ? {
    selected: props.selected,
    updateSelection: action => {
      if (action.type === "SELECT") {
        if (action.value.length) {
          const newSelected = [...props.selected];
          const newIncluded = [];
          action.value.forEach(res => {
            if (!newSelected.find(r => r.id === res.id)) {
              newSelected.push(res);
              newIncluded.push(res);
            }
          });
          onChange(newSelected, newIncluded);
        }
        return;
      }
      if (action.type === "DESELECT_ALL") {
        onChange([]);
        return;
      }
      throw new Error(`Unknown action ${JSON.stringify(action)} in updateSelection.`);
    }
  } : null;
  return (
    <>
      <Button type="button"
        icon
        basic
        title={t("ui321:Selection")}
        onClick={() => setOpened(true)}
        disabled={props.readonly}
        >
        <Icon name="folder open outline" />
      </Button>
      <Modal closeIcon
        size="fullscreen"
        centered={false}
        onSubmit={event => {event.stopPropagation()}}
        open={opened}
        onClose={() => setOpened(false)}>
        <Modal.Header>
          <ResourceTypeLabel resourceType={props.resourceType} />
        </Modal.Header>
        <Modal.Content>
          <ResourceEditorContext.Provider value={defaultEditor}>
          <CollectionSelectionContext.Provider value={selection}>
          <WithResourceCreation resourceType={props.resourceType}>
            <ResourceCollectionNavigation
              resourceType={props.resourceType}
              prependColumns={[{
                key: "_select_button",
                className: "selection-row",
                renderCell: ps => {
                  const resource = ps.rowData;
                  const isSelected = props.selected && (
                    Array.isArray(props.selected)
                    ? props.selected.find(res => res.id === resource.id)
                    : props.selected.id === resource.id
                  );
                  return (
                    <Link className={"ui button select-resource" + (isSelected ? " selected" : "")}
                      title={isSelected ? t("Reset selection") : undefined}
                      to={`/${resource.type}/${resource.id}`}
                      onClick={event => {
                        event.preventDefault();
                        if (props.multiple) {
                          if (isSelected) {
                            onChange(props.selected.filter(res => res.id !== resource.id));
                          } else {
                            onChange([...props.selected, {type: resource.type, id: resource.id}], resource);
                          }
                        } else {
                          if (isSelected) {
                            onChange(null);
                          } else {
                            onChange({type: resource.type, id: resource.id}, resource);
                            setOpened(false);
                          }
                        }
                      }}
                    >
                      <Icon name={props.multiple
                        ? (isSelected ? "check square" : "square outline")
                        : (isSelected ? "check circle" : "circle outline")}
                      />
                      {isSelected ? t("Selected") : t("Select")}
                    </Link>
                  );
                },
              }]}
            />
          </WithResourceCreation>
          </CollectionSelectionContext.Provider>
          </ResourceEditorContext.Provider>
        </Modal.Content>
      </Modal>
    </>
  );
}

function ToOneRelationshipLink(props) {
  const included = React.useContext(ResourceCollectionContext);
  const allSettings = React.useContext(ResourceSettingsContext);
  if (!props.value) {
    return null;
  }
  if (!props.value.type) {
    return props.value.id || null;
  }
  const resourceSettings = allSettings.get(props.value.type);
  let valueToDisplay = "";
  const titleField = resourceSettings.titleField;
  const template = props.template || resourceSettings.titleTemplate;
  if (template) {
    const relatedResource = JsonApiSchema.findResource(props.value, props.included || included);
    if (relatedResource) {
      valueToDisplay = React.createElement(template, {
        data: relatedResource,
        placeType: PlaceTypes.LINK,
      });
    }
  }
  else if (titleField) {
    const relatedResource = JsonApiSchema.findResource(props.value, props.included || included);
    if (relatedResource) {
      valueToDisplay = ((relatedResource || {}).attributes || {})[titleField];
    }
  }
  return (
    <Link to={`/${props.value.type}/${props.value.id}`}>{valueToDisplay || props.value.id}</Link>
  );
}

//TODO: now this file contains components for to-one-relationship and to-many-relationship,
// so name them just RelationshipDropdown, RelationshipChooser, etc

function ToOneRelationship(props) {
  const apiConfig = React.useContext(ApiConfigContext);
  const contextIncluded = React.useContext(ResourceCollectionContext);
  const [included, setIncluded] = React.useState([]);
  const resourceDispatch = React.useContext(ResourceDispatchContext);
  const resourceIncludedAdd = included => {resourceDispatch({type: "INCLUDED", value: included0 => included.concat(included0)})};
  const allSettings = React.useContext(ResourceSettingsContext);
  const resourceSettings = allSettings.get(props.resourceType);
  const titleField = resourceSettings.titleField;
  const template = props.template || resourceSettings.titleTemplate;
  const relatedResource = JsonApiSchema.findResource(props.value, included.concat(contextIncluded));
  const valueToDisplay = titleField ? ((relatedResource || {}).attributes || {})[titleField] : null;
  return (
    <>
      <ToOneRelationshipDropdown
        name={props.name}
        value={props.value}
        valueToDisplay={valueToDisplay || (props.value || {}).id}
        multiple={props.multiple}
        localIncluded={included}
        setLocalIncluded={setIncluded}
        readonly={props.readonly}
        resourceType={props.resourceType}
        titleField={titleField || "id"}
        template={template}
        onChange={(value, resource) => {
          if (resource) {
            const resources = Array.isArray(resource) ? resource : [resource];
            setIncluded([...included, ...resources]);
            resourceIncludedAdd(resources); //for usage by other fields
          }
          props.onChange(props.name, value);
        }}
        apiConfig={apiConfig}
      />
      <ToOneRelationshipChooser
        name={props.name}
        multiple={props.multiple}
        readonly={props.readonly}
        resourceType={props.resourceType}
        onChange={(value, resource) => {
          if (resource) {
            const resources = Array.isArray(resource) ? resource : [resource];
            setIncluded([...included, ...resources]);
            resourceIncludedAdd(resources);
          }
          props.onChange(props.name, value);
        }}
        selected={props.value}
        apiConfig={apiConfig}
      />
    </>
  );
}

const PlaceTypes = {
  LINK: "LINK",
  DROPDOWN_VALUE: "DROPDOWN_VALUE",
  DROPDOWN_OPTION: "DROPDOWN_OPTION",
};

export {ToOneRelationshipLink, PlaceTypes as ToOneRelationshipTemplatePlaceTypes};
export default ToOneRelationship
