/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Icon, Segment} from "semantic-ui-react";
import {ResourceContext, ResourceStateContext, ResourceDispatchContext,
  ResourceEditorContext, ResourceEditorDispatchContext} from "./SingleResource.js";
import {useResourceApi} from "ui321/ResourceApi.js";

function Buttons(props) {
  const resource = React.useContext(ResourceContext);
  const editor = React.useContext(ResourceEditorContext);
  const isEditing = editor.editing;
  if (!resource) {
    return null;
  }
  if (isEditing) {
    return null;
  }
  return (
    <Segment basic vertical className="single-view-buttons">
      <ButtonEdit />
      <ButtonDelete />
    </Segment>
  );
}

function ButtonEdit(props) {
  const {t} = useTranslation("ui321");
  const resource = React.useContext(ResourceContext);
  const resourceState = React.useContext(ResourceStateContext);
  const resourceDispatch = React.useContext(ResourceDispatchContext);
  const editorDispatch = React.useContext(ResourceEditorDispatchContext);
  return (
    <Button type="button"
      primary
      disabled={(resource.meta || {}).updateAccess === false || resourceState.status !== "IDLE"}
      onClick={() => {
        resourceDispatch(["CLEAR_MESSAGES"]);
        editorDispatch(["TURN", "ON"]);
      }}>
      <Icon name="edit" /> {t("Edit")}
    </Button>
  );
}

function ButtonDelete(props) {
  const {t} = useTranslation("ui321");
  const resource = React.useContext(ResourceContext);
  const resourceState = React.useContext(ResourceStateContext);
  const {deleteResource} = useResourceApi();
  return (
    <Button type="button"
      negative
      disabled={(resource.meta || {}).deletionAccess === false || resourceState.status !== "IDLE"}
      loading={resourceState.status === "DELETING"}
      onClick={() => {
        if (window.confirm(t("Confirm deletion"))) {
          deleteResource(resource);
        }
      }}>
      {t("Delete")}
    </Button>
  );
}

export {ButtonEdit, ButtonDelete};
export default Buttons
