/**
 * Перевод сообщений валидации (изменение ajv-i18n).
 * Код основан на https://github.com/ajv-validator/ajv-i18n
 *   node_modules/ajv-i18n/localize/ru/index.js
 */
function ajv_localize_ru2(errors) {
  if (!(errors && errors.length)) return;
  for (var i = 0; i < errors.length; i++) {
    var e = errors[i];
    var out;
    switch (e.keyword) {
      case 'required':
        out = 'Пропущено обязательное поле';
        break;
      case 'enum':
        out = 'необходимо выбрать из списка';
        break;
      case 'if':
        out = e.params.failingKeyword === 'then' ?
          '...либо уберите условие'
          : (e.params.failingKeyword === 'else'
            ? '...либо добавьте условие'
            : 'должно соответствовать схемe "' + (e.params.failingKeyword) + '"'
          );
        break;
      case 'type':
        out = 'должно быть ' + (e.params.type === 'object' ? 'заполнено'
          : (e.params.type === "number" ? 'число' : e.params.type)
        );
        break;
      default:
        continue;
    }
    e.message = out;
  }
}

export default ajv_localize_ru2
