/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {Dropdown} from "semantic-ui-react";
import ToOneRelationship from "./ToOneRelationship.js";

function EnumToOneRelationship(props) {
  const {t} = useTranslation("ui321");
  const labelText = t(props.parentResourceType + ":" + props.name, {context: "fieldLabel"})
  const resourceType = props.value ? props.value.type : null;
  const options =
    [{key: null, value: null, text: t("Empty", {context: "option"})}]
    .concat(props.resourceTypes.map(type =>
      ({
        key: type,
        value: type,
        text: t(props.parentResourceType + ":" + type, {context: props.name + "_relationshipType"}),
      })
    ));
  const isEmpty = !props.value || !props.value.type || !props.value.id;
  const className = (props.required ? "required" : "") + (isEmpty ? " empty-value" : "");
  const onChange = (event, data) => {
    props.onChange(props.name, data.value ? {type: data.value, id: null} : null);
  }
  if (!resourceType) {
    return (
      <>
        <label>{labelText}</label>
        <Dropdown
          selection
          fluid
          className={className}
          value={resourceType}
          options={options}
          onChange={onChange}
        />
      </>
    );
  }
  return (
    <>
      <Dropdown
        button
        className="relationship-type-enum"
        value={resourceType}
        options={options}
        onChange={onChange}
      />
      <ToOneRelationship {...props} resourceType={resourceType} />
    </>
  );
}

export default EnumToOneRelationship
