import i18n from "i18n.js";
import en_i18n from "./i18n/en.Users.json";
import ru_i18n from "./i18n/ru.Users.json";
import FullView from "./FullView.js";
import UserFullName from "./UserFullName.js";

const resourceType = "Users";

i18n.addResourceBundle("en", resourceType, en_i18n);
i18n.addResourceBundle("ru", resourceType, ru_i18n);

const Users = {
  resourceType,
  FullView,
  titleField: "last_name",
  titleTemplate: UserFullName,
  defaultFilter: ["Equals", "status", "Active"],
  defaultSort: [["user_name", "asc"]],
  fieldNames: [
    "user_name",
    "first_name",
    "last_name",
    "date_entered",
    "created_by",
    "date_modified",
    "modified_user_id",
  ],
  itemViewFields: {
    "meta": [
    ],
    "description": [
      "first_name",
      "last_name",
    ],
    "extra": [
        "date_entered",
        "created_by",
        "date_modified",
        "modified_user_id",
    ]
  },
  reverseRelationships: {
  },
  creationAccess: true,
};

export default Users
