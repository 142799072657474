/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {Link, Route, Switch} from "react-router-dom";
import {Breadcrumb} from "semantic-ui-react";
import ResourceCollectionNavigation from "ui321/json-api/ResourceCollectionNavigation.js";
import ResourceTypeLabel from "./ResourceTypeLabel.js";
import SingleResource from "./single/SingleResource.js";
import {WithResourceCreation} from "./single/ResourceCreation.js";
import {UuidString} from "./fields/Uuid.js";

const isAuxFrame = (route, pageRoute) => route.location.pathname !== pageRoute.location.pathname

function ResourceRoutes(props) {
  return (
    <Route render={pageRoute =>
      <Switch location={props.location}>

        <Route path="/:resource/:id" render={route =>
          // We use resource type as namespace in react-i18next translations.
          // But useTranslation hook does not update t function when namespace is changed.
          // So we use key to disable component reuse.
          <React.Fragment key={route.match.params.resource}>
            <Breadcrumb>
              {(!isAuxFrame(route, pageRoute) ||
                (pageRoute.location.pathname !== `/${route.match.params.resource}` &&
                !pageRoute.location.pathname.startsWith(`/${route.match.params.resource}/`))) &&
              <>
              <Breadcrumb.Section>
                <Link to={`/${route.match.params.resource}`}>
                  <ResourceTypeLabel resourceType={route.match.params.resource} />
                </Link>
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              </>
              }
              {pageRoute.location.pathname !== `/${route.match.params.resource}/${route.match.params.id}` ?
                <Breadcrumb.Section>
                  <Link to={`/${route.match.params.resource}/${route.match.params.id}`}>
                    #<UuidString value={route.match.params.id} folded testFormat />
                  </Link>
                </Breadcrumb.Section> :
                <Breadcrumb.Section active>
                  #<UuidString value={route.match.params.id} folded testFormat />
                </Breadcrumb.Section>
              }
            </Breadcrumb>
            <SingleResource
              resourceType={route.match.params.resource}
              id={route.match.params.id}
            />
          </React.Fragment>
        } />
        <Route exact path="/:resource" render={route =>
          <React.Fragment key={route.match.params.resource}>
            <Breadcrumb>
              <Breadcrumb.Section active>
                <ResourceTypeLabel resourceType={route.match.params.resource} />
              </Breadcrumb.Section>
            </Breadcrumb>
            <WithResourceCreation resourceType={route.match.params.resource}>
              <ResourceCollectionNavigation
                resourceType={route.match.params.resource}
              />
            </WithResourceCreation>
          </React.Fragment>
        } />
      </Switch>
    }/>
  );
}

export default ResourceRoutes
