import i18n from "i18n.js";
import en_i18n from "./i18n/en.SecurityGroups.json";
import ru_i18n from "./i18n/ru.SecurityGroups.json";
import FullView from "./FullView.js";
import FetchCreationAccess from "app/FetchCreationAccess.js";

const resourceType = "SecurityGroups";

i18n.addResourceBundle("en", resourceType, en_i18n);
i18n.addResourceBundle("ru", resourceType, ru_i18n);

const SecurityGroups = {
  resourceType,
  FullView,
  titleField: "name",
  defaultSort: [["name", "asc"]],
  fieldNames: [
    "name",
    "date_entered",
    "created_by",
    "date_modified",
    "modified_user_id"
  ],
  itemViewFields: {
    "meta": [
    ],
    "description": [
        "name",
    ],
    "extra": [
        "date_entered",
        "created_by",
        "date_modified",
        "modified_user_id",
    ]
  },
  creationAccess: FetchCreationAccess,
  reverseRelationships: {
  },
};

export default SecurityGroups
