/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";

function useFormData(initialData) {
  return React.useReducer(formDataReducer, initialData);
}

function FormDataProperty(props) {
  const dispatch = React.useContext(FormDataDispatchContext);
  const formData = React.useContext(FormDataContext);
  const propName = props.propName;
  const localDispatch = React.useCallback(action => {
    if (typeof action === "function") {
      const propUpdate = action;
      action = data => ({...data, [propName]: propUpdate((data || {})[propName])});
    } else if (action.type === "REMOVE") {
      action = data => ({...data, [propName]: undefined});
    }
    dispatch(action);
  }, [dispatch, propName]);
  const propData = (formData || {})[propName];
  return (
    <FormDataDispatchContext.Provider value={localDispatch}>
    <FormDataContext.Provider value={propData}>
      {props.children}
    </FormDataContext.Provider>
    </FormDataDispatchContext.Provider>
  );
}

function FormDataArray(props) {
  const formData = React.useContext(FormDataContext) || [];
  if (!Array.isArray(formData)) {
    console.warn("FormDataArray: FormDataContext provides not an array, but ", formData);
  }
  const template = props.children;
  const dataRef = React.useRef([]);
  const dataWithKeys = zipWithPrevKeys(formData, dataRef.current);
  dataRef.current = dataWithKeys;
  return (
    dataWithKeys.map(([item, key], index) =>
      <FormDataArrayItem key={key} index={index}>
        {template}
      </FormDataArrayItem>
    )
  );
}

/** No guarantee that keys will match previous keys, if some values are same. */
function zipWithPrevKeys(dataArray, prevArrayWithKeys) {
  let dataWithKeys = [];
  const maxValue = arr => arr.length ? Math.max(...arr) : -1;
  dataArray.forEach((v, i) => {
    let key = i;
    const vk0 = prevArrayWithKeys.find(([v0]) => v0 === v);
    if (vk0) {
      key = vk0[1];
    }
    const keys = dataWithKeys.map(([,k]) => k);
    if (keys.includes(key)) {
      key = maxValue(keys) + 1;
    }
    dataWithKeys.push([v, key]);
  });
  return dataWithKeys;
}

function FormDataArrayItem(props) {
  const formData = React.useContext(FormDataContext) || [];
  if (!Array.isArray(formData)) {
    console.warn("FormDataArray: FormDataContext provides not an array: ", formData);
  }
  const dispatch = React.useContext(FormDataDispatchContext);
  const itemIndex = props.index;
  const itemData = formData[itemIndex]
  const localDispatch = React.useCallback(action => {
    if (typeof action === "function") {
      const itemUpdate = action;
      action = data => {
        if (!(itemIndex in data))
          data[itemIndex] = undefined;
        return (data || []).map((item, j) => j === itemIndex ? itemUpdate(item) : item);
      }
    } else if (action.type === "REMOVE") {
      action = data => (data || []).filter((v, j) => j !== itemIndex);
    }
    dispatch(action);
  }, [itemIndex, dispatch]);
  return (
    <FormDataDispatchContext.Provider value={localDispatch}>
    <FormDataContext.Provider value={itemData}>
      {props.children}
    </FormDataContext.Provider>
    </FormDataDispatchContext.Provider>
  );
}

function formDataReducer(state, action) {
  if (typeof action === "function") {
    return action(state);
  }
  if (action.type === "REMOVE") {
    return undefined;
  }
  throw new Error(`Unknown action ${JSON.stringify(action)} in formDataReducer.`);
}

const FormDataContext = React.createContext(undefined);
const FormDataDispatchContext = React.createContext(() => {
  console.warn("Consuming FormDataDispatchContext without Provider");
});

export {useFormData, FormDataProperty, FormDataArray, FormDataArrayItem,
  FormDataContext, FormDataDispatchContext};
