/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

function findAttributeSchema(attrName, jsonSchema) {
    return ((((jsonSchema || {}).properties || {}).attributes || {}).properties || {})[attrName];
}

function findRelationshipSchema(relName, jsonSchema) {
    return ((((jsonSchema || {}).properties || {}).relationships || {}).properties || {})[relName];
}

function getToOneRelationshipFields(jsonSchema) {
  return Object.keys((((jsonSchema.properties || {}).relationships || {}).properties || {}))
    .filter(field => {
      const relSchema = JsonApiSchema.findRelationshipSchema(field, jsonSchema);
      return relSchema.type !== "array"
    });
}

function schemaIsToMany(relSchema) {
  return ((relSchema.properties || {}).data || {}).type === "array";
}

function getRelationshipResourceType(relSchema) {
  //TODO: const may be "enum": [_]
  if (schemaIsToMany(relSchema)) {
    return (((relSchema.properties.data.items || {}).properties || {}).type || {}).enum
      || (((((relSchema.properties.data.items || {}).anyOf || {})[0] || {}).properties || {}).type || {}).enum
      || (((((relSchema.properties.data.items || {}).anyOf || {})[1] || {}).properties || {}).type || {}).enum
  }
  const idSchemaIndex = 1 //TODO: search index in schema, anyOf/oneOf/allOf
  const typeEnum = ((((relSchema.properties || {}).data || {}).properties || {}).type || {}).const
    || ((((((relSchema.properties || {}).data || {}).anyOf || [])[0] || {}).properties || {}).type || {}).const
    || ((((((relSchema.properties || {}).data || {}).anyOf || [])[0] || {}).properties || {}).type || {}).enum
    || ((((((relSchema.properties || {}).data || {}).anyOf || [])[idSchemaIndex] || {}).properties || {}).type || {}).const
    || ((((relSchema.properties || {}).data || {}).properties || {}).type || {}).enum
    || ((((((relSchema.properties || {}).data || {}).anyOf || [])[idSchemaIndex] || {}).properties || {}).type || {}).enum;
  return typeof typeEnum === "object" && typeEnum.length === 1 ? typeEnum[0] : typeEnum;
}

function getToManyRelationshipNames(jsonSchema) {
  return Object.keys((((jsonSchema || {}).properties || {}).relationships || {}).properties || {})
    .filter(relName => (((jsonSchema.properties.relationships.properties[relName] || {}).properties || {}).data || {}).type === "array")
}

function fromNullable(schema) {
  if (schema && (schema.anyOf || schema.oneOf)) {
    for (const sch of (schema.anyOf || schema.oneOf)) {
      if (sch.type === "null") {
        continue;
      }
      return sch;
    }
  }
  return schema;
}
function isNullable(schema) {
  if (schema && (schema.anyOf || schema.oneOf)) {
    for (const sch of (schema.anyOf || schema.oneOf)) {
      if (sch.type === "null") {
        return true;
      }
    }
  }
  return false;
}

function isReadOnly(fieldName, schema) {
  if (!schema) {
    return undefined;
  }
  const attrSchema = findAttributeSchema(fieldName, schema);
  if (attrSchema && attrSchema.readOnly !== undefined) {
    return attrSchema.readOnly;
  }
  const attrSchema1 = fromNullable(attrSchema); // TODO: actually we need to evaluate each "anyOf" schema
  if (attrSchema1 && attrSchema1.readOnly !== undefined) {
    return attrSchema1.readOnly;
  }
  const relSchema = findRelationshipSchema(fieldName, schema);
  if (relSchema && relSchema.readOnly !== undefined) {
    return relSchema.readOnly;
  }
  if (schema.allOf) {
    for (const sch of schema.allOf) {
      const ro = isReadOnly(fieldName, sch);
      if (ro !== undefined) {
        return ro;
      }
    }
  }
  return undefined;
}

function makeReadOnly(data) {
  const schema = {};
  if (data.attributes) {
    for (const field in data.attributes) {
      if (!schema.properties) {
        schema.properties = {};
      }
      if (!schema.properties.attributes) {
        schema.properties.attributes = {};
      }
      if (!schema.properties.attributes.properties) {
        schema.properties.attributes.properties = {};
      }
      schema.properties.attributes.properties[field] = {
        readOnly: true,
      }
    }
  }
  if (data.relationships) {
    for (const field in data.relationships) {
      if (!schema.properties) {
        schema.properties = {};
      }
      if (!schema.properties.relationships) {
        schema.properties.relationships = {};
      }
      if (!schema.properties.relationships.properties) {
        schema.properties.relationships.properties = {};
      }
      schema.properties.relationships.properties[field] = {
        readOnly: true,
      }
    }
  }
  return schema;
}

//TODO: use jsonSchema param rather than metadata
function resourceFromDefaults(resourceType, metadata) {
  return {
    type: resourceType,
    attributes:
      Object.keys((((metadata.jsonSchema || {}).properties || {}).attributes || {}).properties || {})
      .reduce((defaults, fieldName) => {
        const fieldSchema = metadata.jsonSchema.properties.attributes.properties[fieldName];
        return fieldSchema.default !== undefined ? {...defaults, [fieldName]: fieldSchema.default} : defaults;
      }, {}),
    relationships:
      Object.keys((((metadata.jsonSchema || {}).properties || {}).relationships || {}).properties || {})
      .reduce((defaults, fieldName) => {
        const fieldSchema = metadata.jsonSchema.properties.relationships.properties[fieldName];
        return fieldSchema.default !== undefined ? {...defaults, [fieldName]: {data: fieldSchema.default}} : defaults;
      }, {}),
    meta: metadata,
  };
}

function listFieldNames(jsonSchema) {
  return Object.keys(((((jsonSchema || {}).properties || {}).attributes || {}).properties || {}))
    .concat(Object.keys(((((jsonSchema || {}).properties || {}).relationships || {}).properties || {})))
    .filter((v, i, a) => a.indexOf(v) === i) //distinct
    .filter(fieldName => {
      const relSchema = findRelationshipSchema(fieldName, jsonSchema);
      return !relSchema || !schemaIsToMany(relSchema || {});
    })
    .concat(["id"])
}

function resourceEquals(a, b) {
  return a === b || (a && b && a.type === b.type && a.id === b.id);
}

function findResource(identifierObject, collection) {
  return (collection || []).find(res => resourceEquals(res, identifierObject));
}

function appendSchema(schema1, schema2) {
  if (!schema1) {
    return schema2;
  }
  const allOf = [...(schema1.allOf || [])];
  allOf.push(schema2);
  return {...schema1, allOf: allOf};
}

const JsonApiSchema = {
  findAttributeSchema,
  fromNullable,
  isNullable,
  isReadOnly,
  makeReadOnly,
  findRelationshipSchema,
  getToOneRelationshipFields,
  findResource,
  getRelationshipResourceType,
  getToManyRelationshipNames,
  resourceFromDefaults,
  listFieldNames,
  schemaIsToMany,
  appendSchema,
};

export default JsonApiSchema
