/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {ConnectionContext} from "./Connection.js";
import {err401, responseErrorMessage} from "./Request.js";
import {ResourceDispatchContext} from "./single/SingleResource.js";
import {ApiConfigContext} from "./json/Request.js";

function useResourceApi(conf = {}) {
  const {t} = useTranslation("ui321");
  const apiConfig = React.useContext(ApiConfigContext);
  const connection = React.useContext(ConnectionContext);
  const resourceDispatch = React.useContext(ResourceDispatchContext);

  const saveResource = (resource, patch, isNew) => {
    resourceDispatch(["CLEAR_MESSAGES"]);
    resourceDispatch(["STATUS", "SAVING"]);
    const promise = isNew ?
      fetch(`${apiConfig.urlPrefix}/${resource.type}`, {
        ...apiConfig.init,
        method: "POST",
        body: JSON.stringify({
          data: {
            type: resource.type,
            attributes: patch.attributes,
            relationships: patch.relationships,
          },
        }),
      })
      :
      fetch(conf.updateUrl ? `${apiConfig.urlPrefix}/${conf.updateUrl.replace(/^\//, '')}` : `${apiConfig.urlPrefix}/${resource.type}/${resource.id}`, {
        ...apiConfig.init,
        method: "PATCH",
        body: JSON.stringify({
          data: {
            type: resource.type,
            id: resource.id,
            attributes: patch.attributes,
            relationships: patch.relationships,
          },
          meta: {
            optimisticLocking: (resource.meta || {}).optimisticLocking,
          },
        }),
      })
    promise
      .then(checkResponseOk(t))
      .then(response => response.json().catch(e => ({})))
      .then(json => {
        if (json.data) {
          resourceDispatch(["RESOURCE", {...json.data, meta: resource.meta}]);
        }
        else {
          resourceDispatch(["REFRESH"]);
        }
      })
      .catch(handleApiErrors(connection.reconnect, t, msg => {
        resourceDispatch(["ERROR", msg]);
      }))
      .finally(() => {
        resourceDispatch(["STATUS", "IDLE"]);
      })
  }
  const createResource = (resource, patch) => {saveResource(resource, patch, true)};
  const updateResource = (resource, patch) => {saveResource(resource, patch, false)};
  //TODO: create function that return promise without status changes

  const deleteResource = resource => {
    if (!resource.type || !resource.id) {
      console.warn("Unsuccessful trying to delete resource ", resource);
      return;
    }
    resourceDispatch(["CLEAR_MESSAGES"]);
    resourceDispatch(["STATUS", "DELETING"]);
    fetch(`${apiConfig.urlPrefix}/${resource.type}/${resource.id}`, {
      ...apiConfig.init,
      method: 'DELETE',
    })
    .then(response => {
      if (!response.ok) {
        const message = response.status === 401 ? err401 : t(response.status, {context: "statusCode"});
        throw new Error(message || "Error");
      }
      return response;
    })
    .then(() => {
      resourceDispatch(["RESOURCE", null]);
      resourceDispatch(["SUCCESS", t("Record deleted successfully")]);
    })
    .catch(e => {
      if (e.message === err401) {
        resourceDispatch(["ERROR", t("401", {context: "statusCode"})]);
        connection.reconnect(() => {
          resourceDispatch(["CLEAR_MESSAGES"]);
        }, t("401", {context: "statusCode"}));
      }
      else {
        resourceDispatch(["ERROR", e.message]);
      }
    })
    .finally(() => {
      resourceDispatch(["STATUS", "IDLE"]);
    })
  };

  return {createResource, updateResource, deleteResource};
}

/** Must be called with "await" or in Promise chain */
function checkResponseOk(t) {
  return response => {
    if (!response.ok) {
      return responseErrorMessage(response, t).then(message => {
        throw new Error(message);
      })
    }
    return response;
  }
}

function handleApiErrors(reconnect, t, setError) {
  return e => {
    if (e.message === err401) {
      setError(t("401", {context: "statusCode"}));
      if (reconnect) {
        reconnect(() => {
          setError([]); // same as "CLEAR_MESSAGES"
          //no automatic retries here
        }, t("401", {context: "statusCode"}));
      }
    }
    else {
      setError(e.message);
    }
  };
}

export {useResourceApi, checkResponseOk, handleApiErrors};
