/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Dimmer, Dropdown, Form, Icon, Message, Sticky} from "semantic-ui-react";
import {useRequestLauncher} from "ui321/Request.js";
import {useFormData, FormDataProperty, FormDataArray, FormDataContext, FormDataDispatchContext} from "ui321/form/FormData.js";
import {FormArrayItem} from "ui321/form/Form.js";
import FieldTemplate from "ui321/form/FieldTemplate.js";
import Pagination from "ui321/nav/Pagination.js";
import {filterToQueryString, andListToFilter, filterToAndList,
  isTrueCondition,
  filterAndListDistinctByColumn, filterAndListsAreEquals,
  filterIsEmpty} from "ui321/nav/Filtering.js";
import {sortToQueryString, sortsAreEquals} from "ui321/nav/Sorting.js";
import {ResourceDispatchContext} from "ui321/single/SingleResource.js";
import {CollectionSelectionPanel} from "./CollectionSelection.js";

function CollectionNavigation(props) {
  const baseUrl = props.baseUrl;
  const defaultParams = props.defaultParams || {};
  const urlBuilder = props.urlBuilder || defaultUrlBuilder;
  const [navState, navStateDispatch] = useFormData({
    baseUrl: baseUrl,
    params: props.lazyStart ? undefined : defaultParams,
    editedParams: defaultParams,
    defaultPageSize: props.defaultPageSize,
    defaultSort: defaultParams.sort,
    urlBuilder: urlBuilder,
    version: 1,
  });
  const url = navState.params ? urlBuilder(navState) : null;
  const {response, error, isLoading, launch, onUnmount} = useRequestLauncher(url, null, navState.version);

  React.useEffect(() => {
    navStateDispatch(state => ({
        ...state,
        currentCount: undefined,
    }));
    launch();
    return onUnmount;
  }, [launch, onUnmount, navStateDispatch]);

  const readResponseState = props.readResponseState || defaultReadResponseState;
  React.useEffect(() => {
    if (response) {
      const responseState = readResponseState(response);
      const responseParams = responseState.params || {};
      navStateDispatch(state => {
        const params = {
          ...state.params,
          ...responseParams,
        };
        return {
          ...state,
          ...responseState,
          params: params,
          editedParams: params,
          serverDefaultSort: state.serverDefaultSort || ((state.params || {}).sort ? null : responseParams.sort),
          defaultSort: state.defaultSort || ((state.params || {}).sort ? null : responseParams.sort),
        };
      });
    }
  }, [response, readResponseState, navStateDispatch]);

  const elemRef = React.useRef(null);

  const responseComponent = React.useMemo(() =>
    response && React.createElement(props.renderResponse, {
      response: response,
    })
  , [response, props.renderResponse]);

  return (
    <NavStateDispatchContext.Provider value={navStateDispatch}>
    <NavStateContext.Provider value={navState}>
    <div className={"collection-navigation" + (props.className ? " " + props.className : "")}>
      <NavParamsForm
        editedParams={navState.editedParams}
        isLoading={isLoading}
        filterTemplate={props.filterTemplate}
        filtersOptions={props.filtersOptions}
        middleButtons={props.middleButtons}
        rightButtons={<NavStatePagination />}
        contextRef={elemRef}
      />
      { error &&
        <Message error content={error} /> }
      {/* { isLoading && layout === "minimal" &&
        <Loading placeholder="paragraph" rows={3} /> } */}
      <div ref={elemRef} className="dimmable response-dimmable">
        <Dimmer inverted active={isLoading} />
        {responseComponent}
      </div>
    </div>
    </NavStateContext.Provider>
    </NavStateDispatchContext.Provider>
  );
}

function NavStatePagination(props) {
  const navState = React.useContext(NavStateContext);
  const navStateDispatch = React.useContext(NavStateDispatchContext);
  const links = {
    self: {meta: {page: {number: navState.pageNumber, size: (navState.params || {}).pageSize || 0}}},
    first: navState.pageNumber > 1 ? {...navState, pageNumber: 1} : null,
    prev: navState.pageNumber > 1 ? {...navState, pageNumber: navState.pageNumber - 1} : null,
    next: navState.isLastPage === false ? {...navState, pageNumber: navState.pageNumber + 1} : null,
    last: null,
  };
  const loadLink = state => {
    navStateDispatch(() => state);
  }
  return (
    <div className="page-buttons">
      <Pagination links={links} fetch={loadLink} count={navState.currentCount} />
    </div>
  );
}

function NavParamsForm(props) {
  const navState = React.useContext(NavStateContext);
  const navStateDispatch = React.useContext(NavStateDispatchContext);
  const navParamsEdited = navState.editedParams;
  const navParamsEditedDispatch = action => {
    navStateDispatch(state => ({
      ...state,
      editedParams: action(state.editedParams),
    }));
    //no REMOVE case
  }

  return (
    <ResourceDispatchContext.Provider value={voidResourceDispatch}>{/* filter fields can use ResourceDispatchContext; but when collection is in single resource, error happens */}
    <FormDataDispatchContext.Provider value={navParamsEditedDispatch}>
    <FormDataContext.Provider value={navParamsEdited}>
      <Form className="collection-navigation-form" loading={props.isLoading}>
        <div className="collection-navigation-filters form-fields editing">
          <FormDataProperty propName="filterList">
            <FormDataArray>
              <FormArrayItem removable>
                {React.createElement(props.filterTemplate || FilterDefaultTemplate)}
              </FormArrayItem>
            </FormDataArray>
          </FormDataProperty>
        </div>
        <div className="collection-navigation-buttons-panel">
          <Sticky context={props.contextRef}>
            <div className="collection-navigation-buttons">
              <div className="search-buttons">
                <SearchButton />
                <ResetButton />
                { !!props.filtersOptions && !!props.filtersOptions.length &&
                  <FormDataProperty propName="filterList">
                    <AddFilterButton filtersOptions={props.filtersOptions} />
                  </FormDataProperty>
                }
              </div>
              <div className="middle-buttons">
                {props.middleButtons}
              </div>
              <div className="right-buttons">
                {props.rightButtons}
              </div>
            </div>
            <CollectionSelectionPanel />
          </Sticky>
        </div>
      </Form>
    </FormDataContext.Provider>
    </FormDataDispatchContext.Provider>
    </ResourceDispatchContext.Provider>
  );
}

function SearchButton(props) {
  const {t} = useTranslation("ui321");
  const navState = React.useContext(NavStateContext);
  const navParamsEdited = React.useContext(FormDataContext);
  const navStateDispatch = React.useContext(NavStateDispatchContext);
  const paramsChanged = !navParamsAreEquals(navParamsEdited, navState.params || {});
  return (
    <Button type="submit" primary
      className={paramsChanged ? "attention" : ""}
      onClick={() => {
        navStateDispatch(state => ({
          ...state,
          params: navParamsEdited,
          editedParams: navParamsEdited,
          pageNumber: 1,
          version: state.version + 1,
        }));
      }}
    >
      <Icon name="search" /> {t("Find")}
    </Button>
  );
}

function ResetButton(props) {
  const {t} = useTranslation("ui321");
  const navState = React.useContext(NavStateContext);
  const navParamsEdited = React.useContext(FormDataContext);
  const dispatch = React.useContext(FormDataDispatchContext);
  const clearParams = {
    filterList: [],
    sort: navState.defaultSort || [],
  };
  const isClear = navParamsAreEquals(navParamsEdited, clearParams);
  return (
    <Button type="button"
      disabled={isClear}
      onClick={() => {
        dispatch(() => clearParams);
      }}
    >
      {t("Reset")}
    </Button>
  );
}

function AddFilterButton(props) {
  const {t} = useTranslation("ui321");
  const dispatch = React.useContext(FormDataDispatchContext);
  return (
    <Dropdown text={t("Add filter")} button floating>
      <Dropdown.Menu>
        { props.filtersOptions.map(option =>
          <Dropdown.Item key={option.key}
            data-name={option.key}
            text={option.label || option.key}
            onClick={event => {
              event.stopPropagation();
              dispatch(arr => (arr || []).concat(option.filters));
            }}
          />
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function defaultUrlBuilder(state) {
  const {baseUrl, params, pageNumber, serverDefaultSort, defaultPageSize} = state;
  const filterList = ((params || {}).filterList || []).filter(f => !filterIsEmpty(f));
  const filterList1 = filterAndListDistinctByColumn(filterList);
  const filter = andListToFilter(filterList1);
  const sort = (params || {}).sort;
  const pageSize = (params || {}).pageSize || defaultPageSize;
  const page = (pageNumber || 1) === 1 && !defaultPageSize ? null : {number: pageNumber || 1, size: pageSize};
  return baseUrl
    + "?filter=" + filterToQueryString(filter)
    + (sort && (!sortsAreEquals(sort, serverDefaultSort) || (page && page.number !== 1)) ? "&sort=" + sortToQueryString(sort) : "")
    + (page ? "&page[number]=" + page.number + "&page[size]=" + pageSize : "")
}

function defaultReadResponseState(response) {
  const state = {params: {}};
  const selfLinkMeta = ((response.links || {}).self || {}).meta || {};
  if (selfLinkMeta.filter) {
    state.params.filterList = filterToAndList(selfLinkMeta.filter).filter(f => !isTrueCondition(f));
  }
  if (selfLinkMeta.sort) {
    state.params.sort = selfLinkMeta.sort;
  }
  if (selfLinkMeta.page && selfLinkMeta.page.size) {
    state.params.pageSize = selfLinkMeta.page.size;
  }
  if (selfLinkMeta.page && selfLinkMeta.page.number) {
    state.pageNumber = selfLinkMeta.page.number;
  }
  if (response.links && response.links.next !== undefined) {
    state.isLastPage = !response.links.next;
  }
  if (Array.isArray(response.data)) {
    state.currentCount = response.data.length;
  }
  return state;
}

function navParamsAreEquals(params1, params2) {
  return filterAndListsAreEquals(
    (params1.filterList || []).filter(f => !filterIsEmpty(f))
    , (params2.filterList || []).filter(f => !filterIsEmpty(f))
  )
    && sortsAreEquals(params1.sort || [], params2.sort || [])
}

function FilterDefaultTemplate(props) {
  const filter = React.useContext(FormDataContext);
  return (
    <FieldTemplate editing>
      {JSON.stringify(filter)}
    </FieldTemplate>
  );
}

const voidResourceDispatch = () => {};

const NavStateContext = React.createContext({});
const NavStateDispatchContext = React.createContext(() => {
  console.warn("Consuming NavStateDispatchContext without Provider");
});

export {NavStateContext, NavStateDispatchContext, defaultUrlBuilder, defaultReadResponseState, FilterDefaultTemplate,
  navParamsAreEquals};
export default CollectionNavigation
