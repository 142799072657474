import React from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import ruLocale from '@fullcalendar/core/locales/ru';
import './Calendar.scss'
import moment from 'moment';

const CalendarPage = props =>
  <FullCalendar
    plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
    defaultView="timeGridWeek"
    locales={[ruLocale]}
    locale="en"
    header={{
      left: 'dayGridMonth,timeGridWeek,timeGridDay,list',
      center: 'title',
      right: 'today prev,next'
    }}
    allDaySlot={false}
    businessHours={{
      daysOfWeek: [1, 2, 3, 4, 5],
      startTime: '9:00',
      endTime: '18:00',
    }}
    scrollTime="08:00:00"
    timeGridEventMinHeight={20}
    events={[
      { title: 'Звонок по базе 1',
        start: moment().subtract(2, 'days').hours(12).minutes(0).startOf('minute').valueOf(),
        end: moment().subtract(2, 'days').hours(12).minutes(45).startOf('minute').valueOf(),
        classNames: ['call-event'] },
      { title: 'Звонок по базе 2',
        start: moment().subtract(1, 'day').hours(10).minutes(0).startOf('minute').valueOf(),
        end: moment().subtract(1, 'day').hours(10).minutes(15).startOf('minute').valueOf(),
        classNames: ['call-event'] },
      { title: 'Звонок по базе 3',
        start: moment().subtract(1, 'day').hours(10).minutes(30).startOf('minute').valueOf(),
        end: moment().subtract(1, 'day').hours(10).minutes(45).startOf('minute').valueOf(),
        classNames: ['call-event'] },
      { title: 'Звонок по базе 4',
        start: moment().subtract(1, 'day').hours(12).minutes(30).startOf('minute').valueOf(),
        classNames: ['call-event'] },
      { title: 'Встреча с клиентом с утра',
        start: moment().hours(9).startOf('hour').valueOf(),
        end: moment().hours(10).startOf('hour').valueOf(),
        classNames: ['meeting-event'] },
      { title: 'Позвонить в бухгалтерию',
        start: moment().hours(14).startOf('hour').valueOf(),
        classNames: ['call-event'] },
      { title: 'Позвонить на склад',
        start: moment().hours(14).minutes(15).startOf('minute').valueOf(),
        classNames: ['call-event'] },
      { title: 'Позвонить курьеру',
        start: moment().hours(14).minutes(30).startOf('minute').valueOf(),
        classNames: ['call-event'] },
      { title: 'Семинар',
        start: moment().add(1, 'day').hours(9).startOf('hour').valueOf(),
        end: moment().add(1, 'day').hours(18).startOf('hour').valueOf(),
        classNames: ['meeting-event'] },
      { title: 'Новый семинар',
        start: moment().add(1, 'week').add(1, 'day').hours(9).startOf('hour').valueOf(),
        end: moment().add(1, 'week').add(1, 'day').hours(14).startOf('hour').valueOf(),
        classNames: ['meeting-event'] },
    ]}
  />

export default CalendarPage
