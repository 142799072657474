/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";

function LeadFullName(props) {
  if (!props.data) {
    return null;
  }
  if (!props.data.attributes) {
    return props.data.id || null;
  }
  return (
    <span className="lead-full-name">
      <span>{props.data.attributes.last_name}</span>
      {' '}
      <span>{props.data.attributes.first_name}</span>
    </span>
  );
}

export default LeadFullName
