import React from "react";
import {useTranslation} from "react-i18next";
import Folder from "ui321/Folder.js";
import Field from "ui321/fields/Field.js";
import ToManyRelationshipAll from "ui321/fields/ToManyRelationshipAll.js";
import RecordGroup from "./RecordGroup.js"

function RecordGroupsFolder(props) {
  const {t} = useTranslation("SecurityGroupsRecords");
  return (
    <Folder title={t("record_id-securitygroupsrecords", {context: "folderTitle"})}>
      <Field
        name={props.fieldName || "record_id-securitygroupsrecords"}
        as={ToManyRelationshipAll.componentName}
        fieldProps={{
          sorting: "securitygroup_id",
          template: RecordGroup,
          emptinessLabel: t("No groups"),
        }}
      />
    </Folder>
  );
}

export default RecordGroupsFolder
