/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {Icon} from "semantic-ui-react";

function UserFullName(props) {
  const {t} = useTranslation("Users");
  if (!props.data) {
    return null;
  }
  if (!props.data.attributes) {
    return props.data.id || null;
  }
  return (
    <span className="user-full-name">
      <span>{props.data.attributes.first_name}</span>
      {' '}
      <span>{props.data.attributes.last_name}</span>

      { !!props.data.attributes.is_group &&
        <>
          {' '}<Icon name="group" />
        </>
      }
      { !!props.data.attributes.status && props.data.attributes.status !== "Active" &&
        <span style={{color: "red"}}>
          {' '}{t(props.data.attributes.status, {context: "status_fieldValue"})}
        </span>
      }
      { !!props.data.attributes.employee_status && props.data.attributes.employee_status !== "Active" &&
        <span style={{color: "red"}}>
          {' '}{t(props.data.attributes.employee_status, {context: "employee_status_fieldValue"})}
        </span>
      }
    </span>
  );
}

export default UserFullName
