/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import JsonApiSchema from "ui321/json/JsonApiSchema.js";
import {FormDataArrayItem, FormDataContext, FormDataDispatchContext} from "ui321/form/FormData.js";
import {filterIsEmpty, isColumnFilter} from "ui321/nav/Filtering.js";
import FieldTemplate from "ui321/form/FieldTemplate.js";
import {FormInput, FormDropdown} from "ui321/form/Form.js";
import {FilterDefaultTemplate} from "ui321/collection/CollectionNavigation.js";
import {JustCreatedFilterField, useCreatedResourcesFilter} from "ui321/single/ResourceCreation.js";
import ToOneRelationship from "ui321/fields/ToOneRelationship.js";

function FilterField(props) {
  const resourceType = props.resourceType;
  const {t} = useTranslation(resourceType);
  const filter = React.useContext(FormDataContext);
  const createdResourcesFilter = useCreatedResourcesFilter();
  let component = null;
  const fieldName = isColumnFilter(filter) ? filter[1] : null;
  const labelText = fieldName && t(fieldName, {context: "fieldLabel"});
  const isEmpty = filterIsEmpty(filter);

  if (filter[0] === "Equals") {
    const attrSchema = JsonApiSchema.findAttributeSchema(fieldName, props.jsonSchema);
    const attrSchema1 = JsonApiSchema.fromNullable(attrSchema);
    const relSchema = JsonApiSchema.findRelationshipSchema(fieldName, props.jsonSchema);
    const relatedResourceType = relSchema ? JsonApiSchema.getRelationshipResourceType(relSchema) : null;
    //TODO: сделать вызов src/ui321/fields/Field.js, чтобы не копировать все типы полей
    if (relSchema && typeof relatedResourceType === "string") {
      component = <ToOneRelationshipFilter name={fieldName} resourceType={relatedResourceType} />
    } else if (attrSchema1 && (attrSchema1.type === "string" || attrSchema1.type === "number")
                           && (attrSchema.enum || attrSchema1.enum)) {
      component = <EnumFilter name={fieldName} attrSchema={attrSchema} resourceType={resourceType} />
    } else {
      component = <FormInput name={fieldName} />
    }
  } else if (filter[0] === "StartsWith" || filter[0] === "StartsWith_CI") {
    component = <FormInput name={fieldName} />
  } else if (JSON.stringify(filter) === JSON.stringify(createdResourcesFilter)) {
    return <JustCreatedFilterField />
  }
  return component ?
    <FieldTemplate name={fieldName} label={labelText} editing empty={isEmpty}>
      <FormDataArrayItem index={2}>
        {component}
      </FormDataArrayItem>
    </FieldTemplate>
    :
    <FilterDefaultTemplate />
}

function ToOneRelationshipFilter(props) {
  const formDataDispatch = React.useContext(FormDataDispatchContext);
  const fieldName = props.name;
  const fieldValue = React.useContext(FormDataContext);
  const resourceType = props.resourceType;
  return (
    <ToOneRelationship
      name={fieldName}
      value={fieldValue ? {type: resourceType, id: fieldValue} : null}
      resourceType={resourceType}
      onChange={(name, value) => {
        formDataDispatch(() => value ? value.id : null);
      }}
    />
  );
}

function EnumFilter(props) {
  const resourceType = props.resourceType;
  const {t} = useTranslation(resourceType);
  const fieldName = props.name;
  const itemProps = (value, content) => (
    {key: value, value: value, text: content, content: content}
  );
  const itemContent = value => (
    <span data-value={value}>
      {t(value, {context: fieldName + "_fieldValue"})}
    </span>
  );
  const values = ((props.attrSchema || {}).enum || (JsonApiSchema.fromNullable(props.attrSchema) || {}).enum || [])
    .map(v => `${v}`) //TODO: use actual data types in filter
  return (
    <FormDropdown fluid selection
      name={fieldName}
      options={[
        itemProps(null, ""),
        ...values.map(val => itemProps(val, itemContent(val))),
      ]}
    />
  );
}

export {ToOneRelationshipFilter, EnumFilter};
export default FilterField
