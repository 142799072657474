/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 * Author Dmitriy Yankovskiy <ydv@lab321.ru>
 */

import Ajv from "ajv";
import JsonApiSchema from "ui321/json/JsonApiSchema";

function jsonSchemaValidator(jsonSchema) {
  const ajv = new Ajv({allErrors: true, jsonPointers: true});
  require("ajv-errors")(ajv);
  return ajv.compile(jsonSchema);
}

function applyValidator(validate, resource, patch, localize) {
  const allData = mergeResourcePatch(resource, patch);
  const valid = validate(allData);
  if (!valid) {
    if (localize) {
      localize(validate.errors);
    }
    for (let err of validate.errors) {
      err.fieldName = errorFieldName(err);
      if (err.fieldName) {
        err.fieldValue = allData.relationships[err.fieldName] || allData.attributes[err.fieldName];
      }
    }
    chooseAnyOfErrors(validate);
    console.log("validate.errors", validate.errors, "data", allData);
    return validate.errors;
  }
  return [];
};

/** Keep errors where errorMessage is set for anyOf keyword */
function chooseAnyOfErrors(validate) {
  if (validate.errors.length >= 2) {
    const anyOfSchemaPaths = validate.errors
      .filter(err => err.keyword === "anyOf").map(err => err.schemaPath);
    const hiddenErrors = [];
    for (const schemaPath of anyOfSchemaPaths) {
      const disjunctErrors = validate.errors.filter(err => err.schemaPath.startsWith(schemaPath)); //disjuncts plus anyOf itself
      const errorWithMessage = disjunctErrors.find(err => err.keyword === "errorMessage");
      if (errorWithMessage) {
        for (const err of disjunctErrors) {
          if (err.keyword !== "errorMessage") {
            hiddenErrors.push(err);
          }
        }
      }
    }
    if (hiddenErrors.length) {
      console.log("Other errors", hiddenErrors);
      validate.errors = validate.errors.filter(err => hiddenErrors.indexOf(err) === -1);
    }
  }
}

function errorFieldName(err) {
  if (err.schemaPath === "#/properties/attributes/required"
    || err.schemaPath === "#/properties/relationships/required"
    || err.dataPath === "/attributes"
    || err.dataPath === "/relationships") { //schemaPath = "#/then/properties/relationships/required", e.g.
    if (err.params.missingProperty) {
      return err.params.missingProperty;
    }
  }
  if (err.dataPath) {
    const matches = err.dataPath.match(/^\/(attributes|relationships)\/([^\\.\\[\]\\/]+).*$/)
    if (matches) {
      return matches[2];
    }
  }
  const paramErrors = (err.params || {}).errors || [];
  for (const err1 of paramErrors) {
    const name = errorFieldName(err1);
    if (name) {
      return name;
    }
  }
  return null;
}

// move to Edit.js or some file with json-api
function mergeResourcePatch(resource, patch) {
  return resource && patch ? {
    ...resource,
    type: patch.type !== undefined ? patch.type : resource.type,
    id: patch.id !== undefined ? patch.id : resource.id,
    attributes: {...resource.attributes, ...patch.attributes},
    relationships:
      [...Object.keys(resource.relationships || {}), ...Object.keys(patch.relationships || {})]
      .reduce((mergedRels, relName) => {
        const resourceRel = (resource.relationships || {})[relName];
        const patchRel = (patch.relationships || {})[relName];
        return {
          ...mergedRels,
          [relName]: (resourceRel || {}).data && (patchRel || {}).data
            ? {...resourceRel,
              data: Array.isArray(patchRel.data)
                ? patchRel.data.map(r =>
                    r.id ? mergeResourcePatch(JsonApiSchema.findResource(r, resourceRel.data), r) : r )
                : mergeResourcePatch(resourceRel.data, patchRel.data) }
            : (patchRel != null && patchRel.data === undefined ? {} :
              ((patchRel || {}).data !== undefined ? patchRel : resourceRel))
          }
      }, {}),
  } : resource || patch;
}

export {jsonSchemaValidator, applyValidator, mergeResourcePatch};
