/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {ResourceSettingsContext} from "ui321/ResourceSettings.js";
import {ResourceContext} from "ui321/single/SingleResource.js";
import {WithResourceCreation} from "ui321/single/ResourceCreation.js";
import JsonApiSchema from "ui321/json/JsonApiSchema.js";
import ResourceCollectionNavigation from "ui321/json-api/ResourceCollectionNavigation.js";

function ToManyRelationshipNav(props) {
  const resource = React.useContext(ResourceContext);
  const allSettings = React.useContext(ResourceSettingsContext);

  if (!resource || !resource.id) {
    return null;
  }

  const resourceSettings = allSettings.get(resource.type);
  const relName = props.name;
  const relSchema = JsonApiSchema.findRelationshipSchema(relName, resource.meta.jsonSchema);
  const relatedResourceType = relSchema ? JsonApiSchema.getRelationshipResourceType(relSchema) : null;
  const toResourceType = typeof relatedResourceType === "object" ? relatedResourceType[0] : relatedResourceType;
  const reverseRelationship = (resourceSettings.reverseRelationships || {})[relName];
  const constData = reverseRelationship ? {
    "relationships": {
      [reverseRelationship]: {
        data: {
          type: resource.type,
          id: resource.id,
        }
      }
    }
  } : undefined;
  const baseUrl = `/${resource.type}/${resource.id}/${relName}`;
  return ( constData ?
    <WithResourceCreation
      resourceType={toResourceType}
      constData={constData}
      defaultData={props.defaultData}
    >
      <ResourceCollectionNavigation
        resourceType={toResourceType}
        baseUrl={baseUrl}
      />
    </WithResourceCreation>
    :
    <ResourceCollectionNavigation
      resourceType={toResourceType}
      baseUrl={baseUrl}
    />
  );
}

export default ToManyRelationshipNav
