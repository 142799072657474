import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en_ui321 from "ui321/i18n/en.ui321.json";
import ru_ui321 from "ui321/i18n/ru.ui321.json";
import en_app from "app/i18n/en.app.json";
import ru_app from "app/i18n/ru.app.json";
import moment from "moment";
import "moment/locale/ru";
import ajv_localize_en from "ajv-i18n/localize/en";
import ajv_localize_ru from "ajv-i18n/localize/ru";
import ajv_localize_ru2 from "./ajv_localize_ru2.js";

const resources = {
  en: {
    ui321: en_ui321,
    app: en_app,
  },
  ru: {
    ui321: ru_ui321,
    app: ru_app,
  }
};

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
  debug: process.env.REACT_APP_I18N_DEBUG !== undefined
    ? process.env.REACT_APP_I18N_DEBUG === "true"
    : process.env.NODE_ENV === "development",
  resources,
  defaultNS: "app",
  fallbackNS: "app",
  keySeparator: false,
  detection: {
    lookupLocalStorage: "i18nextLng",
  },
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

moment.locale(i18n.languages);
i18n.on("languageChanged", function(lng) {
  moment.locale(lng);
  // TODO: moment locale is not changed globally
  // https://github.com/moment/moment/issues/1797
});

i18n.validation = i18n.ajv = chooseAjvLocale(i18n.languages);

function chooseAjvLocale(languages) {
  for (const lang of languages) {
    if (lang.match(/^en(-.*)?$/)) return ajv_localize_en;
    if (lang.match(/^ru(-.*)?$/)) return errs => {ajv_localize_ru(errs); ajv_localize_ru2(errs)};
  }
  return ajv_localize_en;
}

export default i18n
