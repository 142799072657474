/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import i18n from "i18n.js";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Breadcrumb, Modal} from "semantic-ui-react";
import ResourceTypeLabel from "ui321/ResourceTypeLabel.js";
import CollectionNavigation, {defaultReadResponseState} from "ui321/collection/CollectionNavigation.js";
import ColumnHead from "ui321/collection/ColumnHead.js";
import DataTable, {dataRowSpans} from "ui321/collection/DataTable.js";
import FieldCell from "ui321/collection/FieldCell.js";
import Field from "ui321/fields/Field.js";
import {UuidString} from "ui321/fields/Uuid.js";
import {CollectionResponse} from "ui321/json-api/ResourceCollectionNavigation.js";
import ResourceMetadata from "ui321/ResourceMetadata.js";
import AuditMetadata from "./AuditMetadata.json";
import en_i18n from "./i18n/en.Audit.json";
import ru_i18n from "./i18n/ru.Audit.json";
import {ResourceContext} from "ui321/single/SingleResource.js";
import JsonApiSchema from "ui321/json/JsonApiSchema.js";
import AuditFilter from "./AuditFilter.js";

i18n.addResourceBundle("en", "Audit", en_i18n);
i18n.addResourceBundle("ru", "Audit", ru_i18n);

function ResourceAuditButton(props) {
  const resource = React.useContext(ResourceContext);
  return (
    <AuditButton resourceType={resource.type} id={resource.id} />
  );
}

function AuditButton(props) {
  const {t} = useTranslation("Audit");
  const parentType = props.resourceType;
  const parentId = props.id;
  return (
    <AuditModalState
      resourceType={parentType}
      id={parentId}
      trigger={props => (
        <Link className="ui button"
          to={`/${parentType}/${parentId}/audit`}
          onClick={event => {
            event.preventDefault();
            props.setOpened(true);
          }}
        >
          {t("Audit", {context: "button"})}
        </Link>
      )} />
  );
}

function AuditPage(props) {
  const {t} = useTranslation("Audit");
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section>
          <Link to={`/${props.resourceType}`}>
            <ResourceTypeLabel resourceType={props.resourceType} />
          </Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section>
          <Link to={`/${props.resourceType}/${props.id}`}>
            #<UuidString value={props.id} folded testFormat />
          </Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section active>
          {t("Audit", {context: "resourceType"})}
        </Breadcrumb.Section>
      </Breadcrumb>
      <Audit
        resourceType={props.resourceType}
        id={props.id}
      />
    </>
  );
}

function AuditModalState(props) {
  const [opened, setOpened] = React.useState(false);
  return (
    <>
      { props.trigger &&
        React.createElement(props.trigger, {setOpened: setOpened}) }
      <AuditModal {...props}
        opened={opened}
        setOpened={setOpened}
      />
    </>
  );
}

function AuditModal(props) {
  const {t} = useTranslation("Audit");
  return (
    <Modal className="audit-modal"
      open={props.opened}
      onClose={() => props.setOpened(false)}
      closeIcon closeOnDimmerClick={false}
      size="fullscreen"
      centered={false}
    >
      <Modal.Header>{t("Audit", {context: "resourceType"})}</Modal.Header>
      <Modal.Content>
        <Audit {...props} />
      </Modal.Content>
    </Modal>
  );
}

function filtersOptions(t){
  return [
    {
      key: "field_name",
      label: t('field_name', {context: "fieldLabel"}),
      filters: [["Equals", "field_name", ""]],
    },
    {
      key: "created_by",
      label: t('created_by', {context: "fieldLabel"}),
      filters: [["Equals", "created_by", ""]],
    },
  ];
}

function Audit(props) {
  const {t} = useTranslation("Audit");
  const parentType = props.resourceType;
  const [auditedFields, setAuditedFields] = React.useState([]);
  const readResponseState = response => {
    if (Array.isArray(((response || {}).meta || {}).auditedFields)) {
      setAuditedFields(response.meta.auditedFields);
    }
    return defaultReadResponseState(response);
  };
  return (
    <ResourceMetadata resourceType={parentType} render={parentMetadata =>
      <CollectionNavigation
        baseUrl={`/${parentType}/${props.id}/audit`}
        filtersOptions={filtersOptions(t)}
        filterTemplate={() =>
          <AuditFilter resourceType={parentType} auditedFields={auditedFields} />
        }
        readResponseState={readResponseState}
        renderResponse={props =>
          <CollectionResponse
            {...props}
            mergeMeta={AuditMetadata}
            renderCollection={props =>
              <DataTable
                className="audit-table"
                data={props.data}
                rowSpans={auditDataRowSpans(props.data)}
                emptinessLabel={t("No changes")}
                columns={[
                  {
                    key: "field_name",
                    renderHead: AuditColumnHead,
                    renderCell: props => <FieldNameCell {...props} resourceType={parentType} />,
                  },
                  {
                    key: "before_value",
                    renderHead: AuditColumnHead,
                    renderCell: props => <FieldValueCell {...props} resourceType={parentType} metadata={parentMetadata} />,
                  },
                  {
                    key: "after_value",
                    renderHead: AuditColumnHead,
                    renderCell: props => <FieldValueCell {...props} resourceType={parentType} metadata={parentMetadata} />,
                  },
                  {
                    key: "created_by",
                    renderHead: AuditColumnHead,
                    renderCell: FieldCell,
                  },
                  {
                    key: "date_created",
                    renderHead: AuditColumnHead,
                    renderCell: FieldCell,
                  },
                ]}
              />
            }
          />
        }
      />
    }/>
  );
}

const sortableColumns = [
  "date_created",
];

function AuditColumnHead(props) {
  const {t} = useTranslation("Audit");
  return (
    <ColumnHead
      {...props}
      text={t(props.columnKey, {context: "fieldLabel"})}
      sortable={sortableColumns.includes(props.columnKey)}
      filtersToAdd={(filtersOptions(t).find(v => v.key === props.columnKey) || {}).filters}
    />
  );
}

function FieldNameCell(props) {
  const {t} = useTranslation(props.resourceType);
  return t(props.rowData.attributes.field_name, {context: "fieldLabel"});
}

function FieldValueCell(props) {
  const fieldName = props.rowData.attributes.field_name;
  const isRel = !!JsonApiSchema.findRelationshipSchema(fieldName, props.metadata.jsonSchema);
  const parentData = {
    type: props.resourceType,
    attributes: {},
    relationships: {},
    meta: props.metadata,
  };
  if (fieldName === "id") {
    parentData.id = props.rowData.attributes[props.columnKey];
  } else {
    parentData[isRel ? "relationships" : "attributes"][fieldName] = props.rowData.attributes[props.columnKey];
  }
  return (
    <ResourceContext.Provider value={parentData}>
      <Field name={fieldName} />
    </ResourceContext.Provider>
  );
}

function auditDataRowSpans(auditRows) {
  const rows = auditRows.map(row => ({
    date_created: row.attributes.date_created,
    created_by: (row.relationships.created_by.data || {}).id,
  }))
  const rowSpans = dataRowSpans(rows, ["date_created"]);
  for (const iRow in rowSpans) {
    rowSpans[iRow].created_by = 1;
  }
  for (let iRow = rows.length - 2; iRow >= 0; iRow--) {
    if (rows[iRow].created_by === rows[iRow + 1].created_by && rows[iRow].date_created === rows[iRow + 1].date_created) {
      rowSpans[iRow].created_by += rowSpans[iRow + 1].created_by;
      rowSpans[iRow + 1].created_by = -1;
    }
  }
  return rowSpans;
}

export {AuditButton, ResourceAuditButton, AuditPage};
export default Audit
