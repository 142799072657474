/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {Message} from "semantic-ui-react";
import { ConnectionContext } from "./Connection.js";
import {ApiConfigContext} from "./json/Request.js";
import Loading from "./Loading.js";
import {err401, responseErrorMessage} from "./Request.js";

function storeMetadata(resourceType, metadata) {
  try {
    getStorage().setItem(`${resourceType}-metadata-v1`, JSON.stringify(metadata));
  } catch (e) {
    console.warn(e);
  }
}

function loadMetadata(resourceType) {
  const str = getStorage().getItem(`${resourceType}-metadata-v1`);
  return str ? JSON.parse(str) : null;
}

/** It searches stored buildId and then returns localStorage or sessionStorage.
  * sessionStorage returned when buildId is empty, it happens in dev mode.
  * Call validateMetadataCache prior to getStorage.
  */
function getStorage() {
  const buildId = localStorage.getItem("metadata-v1-version");
  return buildId ? localStorage : sessionStorage;
}

function validateMetadataCache(buildId) {
  if (!buildId) {
    try {
      localStorage.setItem("metadata-v1-version", "");
    } catch (e) {
      console.warn(e);
    }
    return;
  }
  const storedBuildId = localStorage.getItem("metadata-v1-version");
  if (storedBuildId !== buildId) {
    clearMetadataCache(localStorage);
    try {
      localStorage.setItem("metadata-v1-version", buildId);
    } catch (e) {
      console.warn(e);
    }
  }
}

function clearMetadataCache(storage) {
  const keys = [];
  for (let i = 0; i < storage.length; i++) {
    const key = storage.key(i);
    if (key.match(/.*-metadata-v1$/)) {
      keys.push(key);
    }
  }
  keys.forEach(key => {
    storage.removeItem(key);
  });
}

function useMetadata(resourceType) {
  const {t} = useTranslation("ui321");
  const apiConfig = React.useContext(ApiConfigContext);
  const connection = React.useContext(ConnectionContext);
  const reconnect = connection.reconnect;
  const [data, setData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [errors, setErrors] = React.useState([]);
  React.useEffect(() => {
    async function fetchMetadata() {
      setIsLoading(true);
      if (!window.ui321_metadataFetchCallbacks) {
        window.ui321_metadataFetchCallbacks = {};
      }
      if (!window.ui321_metadataFetchCallbacks[resourceType]) {
        window.ui321_metadataFetchCallbacks[resourceType] = [];
      }
      const url = `${apiConfig.urlPrefix}/resource-metadata/${resourceType}`;
      try {
        const response = await fetch(url, apiConfig.init);
        if (!response.ok) {
          const message = await responseErrorMessage(response, t);
          throw new Error(message || "Error");
        }
        const json = await response.json();
        const metadata = json.data.attributes;
        setData(metadata);
        storeMetadata(resourceType, metadata);
        for (const callback of window.ui321_metadataFetchCallbacks[resourceType]) {
          callback(metadata);
        }
      } catch (error) {
        if (error.message === err401) {
          setErrors([t("401", {context: "statusCode"})]);
          reconnect(() => {
            setErrors([]);
            fetchMetadata();
          }, t("401", {context: "statusCode"}));
        } else {
          setErrors([`Metadata fetching error (${resourceType}): ${error.message}`]);
          for (const callback of window.ui321_metadataFetchCallbacks[resourceType]) {
            callback(false);
          }
        }
      }
      setIsLoading(false);
      delete window.ui321_metadataFetchCallbacks[resourceType];
    }

    const fromStorage = loadMetadata(resourceType);
    if (fromStorage) {
      setData(fromStorage);
      setIsLoading(false);
    }
    else {
      if (resourceType) {
        if ((window.ui321_metadataFetchCallbacks || {})[resourceType]) { //prevent multiple same requests
          window.ui321_metadataFetchCallbacks[resourceType].push(metadata => {
            if (metadata) {
              setData(metadata);
            } else {
              setErrors(["Metadata fetching error"]);
            }
            setIsLoading(false);
          });
        } else {
          fetchMetadata();
        }
      }
      else {
        setErrors(["Metadata fetching error: type is empty"]);
        setIsLoading(false);
      }
    }
  }, [resourceType, reconnect, t, apiConfig.urlPrefix, apiConfig.init]);
  return [data, isLoading, errors];
}

function ResourceMetadata(props) {
  const [metadata, metadataIsLoading, metadataFetchingErrors] = useMetadata(props.resourceType);
  if (metadataFetchingErrors.length) {
    return <Message error list={metadataFetchingErrors} />;
  }
  if (metadataIsLoading) {
    return <Loading />;
  }
  return props.render(metadata);
}

export {useMetadata, validateMetadataCache, clearMetadataCache};
export default ResourceMetadata
