/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {Dropdown, Icon} from "semantic-ui-react";
import {NavStateContext, NavStateDispatchContext} from "ui321/collection/CollectionNavigation.js";

function ColumnHead(props) {
  const {t} = useTranslation("ui321");
  const navState = React.useContext(NavStateContext);
  const dispatch = React.useContext(NavStateDispatchContext);
  const sort = ((navState || {}).params || {}).sort || [];
  const headContent = (
    <>
      {props.text !== undefined ? props.text : t(props.columnKey, {context: "fieldLabel"})}
      { !!props.columnKey && !!sort.length && sort[0][0] === props.columnKey && sort[0][1] === "asc" &&
        <Icon name="caret up" /> }
      { !!props.columnKey && !!sort.length && sort[0][0] === props.columnKey && sort[0][1] === "desc" &&
        <Icon name="caret down" /> }
    </>
  );
  const sortable = !!props.sortable && !!props.columnKey;
  const filterable = !!props.filtersToAdd && !!props.filtersToAdd.length;
  if (!sortable && !filterable) {
    return headContent;
  }
  return (
    <Dropdown trigger={headContent} icon={null}>
      {/* a table can have "overflow auto", so we use one line menu in the head */}
      <Dropdown.Menu className="one-line-menu">
        <Dropdown.Item
          disabled={!sortable}
          onClick={() => {
            dispatch(state => {
              const params = {
                ...state.params,
                sort: [[props.columnKey, "asc"]],
              };
              return {
                ...state,
                params: params,
                editedParams: params,
                pageNumber: 1,
              };
            });
          }}
          title={t("Ascending")}
        >
          <Icon fitted name="caret up" />
        </Dropdown.Item>
        <Dropdown.Item
          disabled={!sortable}
          onClick={() => {
            dispatch(state => {
              const params = {
                ...state.params,
                sort: [[props.columnKey, "desc"]],
              };
              return {
                ...state,
                params: params,
                editedParams: params,
                pageNumber: 1,
              };
            });
          }}
          title={t("Descending")}
        >
          <Icon fitted name="caret down" />
        </Dropdown.Item>
        <Dropdown.Item
          disabled={!filterable}
          onClick={() => {
            dispatch(state => ({
              ...state,
              editedParams: {
                ...state.editedParams,
                filterList: [...state.editedParams.filterList, ...props.filtersToAdd],
              }
            }));
          }}
          title={t("Add filter")}
        >
          <Icon fitted name="filter" />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ColumnHead
