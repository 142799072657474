import React from "react";
import {useTranslation} from "react-i18next";
import {Segment} from "semantic-ui-react";
import Folder from "ui321/Folder.js";
import Editable from "ui321/single/Editable.js";
import Field from "ui321/fields/Field.js";
import RecordGroupsFolder from "app/resources/SecurityGroupsRecords/RecordGroupsFolder.js"
import {ResourceContext, ResourceEditorContext} from "ui321/single/SingleResource.js";
import {ButtonEdit, ButtonDelete} from "ui321/single/Buttons.js";
import {ResourceAuditButton} from "app/resources/Audit/Audit.js";

function FullView(props) {
  const {t} = useTranslation("Calls");
  return (
    <Editable>
      <Buttons />
      <Folder title={t("Overview", {context: "folderTitle"})}>
        <Field name="name" />
        <Field name="direction" />
        <Field name="status" />
        <Field name="date_start" />
        <Field name="duration_hours" />
        <Field name="parent" />
        <Field name="description" />
      </Folder>
      <Folder title={t("Other", {context: "folderTitle"})}>
        <Field name="date_entered" />
        <Field name="created_by" />
        <Field name="date_modified" />
        <Field name="modified_user_id" />
        <Field name="assigned_user_id" />
      </Folder>
      <Folder title={t("Extra", {context: "folderTitle"})} folded>
        <Field name="email_reminder_sent" />
      </Folder>
      <RecordGroupsFolder />
      {/* <Folder title={t("Groups again", {context: "folderTitle"})} folded>
        <Field name="record_id-securitygroupsrecords" />
      </Folder> */}
    </Editable>
  );
}

function Buttons(props) {
  const resource = React.useContext(ResourceContext);
  const editor = React.useContext(ResourceEditorContext);
  const isEditing = editor.editing;
  if (!resource) {
    return null;
  }
  if (isEditing) {
    return null;
  }
  return (
    <Segment basic vertical className="single-view-buttons">
      <ButtonEdit />
      <ResourceAuditButton />
      <ButtonDelete />
    </Segment>
  );
}

export default FullView
