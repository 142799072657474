/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";

const uuidRegexp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
const uuidRegexpStupid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i

function UuidString(props) {
  const {t} = useTranslation("ui321");
  const [folded, setFolded] = React.useState(props.folded !== undefined ? props.folded : true);
  const isUuid = !props.testFormat || uuidRegexpStupid.test(props.value);
  if (!folded || !isUuid) {
    return props.value;
  }
  const SUBSTR_LEN = 9;
  const value = props.value || "";
  return (
    <>
      {value.substr(0, SUBSTR_LEN)}
      { value.length > SUBSTR_LEN &&
        <button type="button"
          className="expand-hellip"
          title={t("Show full id")}
          onClick={event => {setFolded(false); event.preventDefault()}}
        >
          &hellip;
        </button> }
    </>
  );
}

export {UuidString, uuidRegexp, uuidRegexpStupid};
