/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {ConnectionContext} from "./Connection.js";
import {ApiConfigContext} from "ui321/json/Request.js";
  // TODO: move ApiConfigContext here

const err401 = "401 Unauthorized";

async function responseErrorMessage(response, t) {
  if (response.status === 401) {
    return err401;
  }
  let message;
  try {
    const json = await response.json();
    console.warn(json);
    if (json && json.errors) { //jsonapi format
      message = json.errors.map(err => err.title).join("\n");
    } else if (json && json.error && json.error.id) { //play format
      message = t(response.status, {context: "statusCode"}) + `: ${json.error.id}`;
    } else if (json && json.error && json.error.message) { //play format
      message = t(response.status, {context: "statusCode"}) + `: ${json.error.message}`;
    }
  } catch (e) {
  }
  if (!message) {
    message = t(response.status, {context: "statusCode"});
  }
  return message === err401 ? "Error " + err401 : message || "Error";
}

function useRequestLauncher(url, init, version) {
  const {t} = useTranslation("ui321");
  const [response, setResponse] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const apiConfig = React.useContext(ApiConfigContext);
  const connection = React.useContext(ConnectionContext);
  const reconnect = connection.reconnect;
  const [launch, onUnmount] = React.useMemo(() => {
    // let mounted = true;
    let mounted = true || version; //<- version here to suppress warning "React Hook React.useMemo has an unnecessary dependency"
    return [
    async (newUrl, newInit, options) => {
      if (newUrl || url) {
        setIsLoading(true);
        setError(null);
        try {
          const fetchResponse = await fetch(`${apiConfig.urlPrefix}${newUrl || url}`, {...apiConfig.init, ...(newInit || init)})
          if (!fetchResponse.ok) {
            const message = await responseErrorMessage(fetchResponse, t);
            throw new Error(message || "Error");
          }
          const json = await fetchResponse.json();
          if (mounted) {
            setResponse(json);
            if (options && options.onComplete) {
              options.onComplete(json);
            }
          }
        } catch(e) {
          if (mounted) {
            if (e.message === err401) {
              setError(t("401", {context: "statusCode"}));
              reconnect(() => {
                const method = (newInit || {}).method || (init || {}).method || "GET";
                if (method === "GET") {
                  launch(newUrl, newInit, options);
                }
              }, t("401", {context: "statusCode"}));
            }
            else {
              setError(e.message || "ERROR");
            }
            if (options && options.onError) {
              options.onError(e);
            }
          }
        }
        if (mounted) {
          setIsLoading(false);
        }
      }
    }, () => {
      mounted = false;
    }
  ]}, [url, init, version, reconnect, t, apiConfig.urlPrefix, apiConfig.init]);
  return {response, error, isLoading, launch, onUnmount};
}

function useRequest(url, init, version, launchOptions) {
  const launcher = useRequestLauncher(url, init, version);
  const {launch, onUnmount} = launcher;
  React.useEffect(() => {
    launch(undefined, undefined, launchOptions);
    return onUnmount;
  }, [launch, onUnmount, launchOptions]);
  return launcher;
}

export {useRequest, useRequestLauncher, err401, responseErrorMessage};
