/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import JsonApiSchema from "ui321/json/JsonApiSchema.js";

function updateResourcePatch(resource, resourcePatch, fieldName, fieldValue) {
  const attrSchema = JsonApiSchema.fromNullable(
    JsonApiSchema.findAttributeSchema(fieldName, resource.meta.jsonSchema)
  );
  const relSchema = JsonApiSchema.findRelationshipSchema(fieldName, resource.meta.jsonSchema);
  const resourceAttributes = resource.attributes || {};
  const resourceRelationships = resource.relationships || {};
  let patchAttributes = resourcePatch.attributes || {};
  let patchRelationships = resourcePatch.relationships || {};
  if (relSchema) {
    const prevValue = (resourceRelationships[fieldName] || {}).data;
    if ((fieldValue && prevValue
          && !Array.isArray(fieldValue) && fieldValue.id === prevValue.id && fieldValue.type === prevValue.type
          && !Object.keys(fieldValue.attributes || {}).length && !Object.keys(fieldValue.relationships || {}).length)
      || fieldValue === prevValue) {
      delete patchRelationships[fieldName];
    }
    else {
      patchRelationships[fieldName] = {data: fieldValue};
    }
    if (!Object.keys(patchRelationships).length) {
      delete resourcePatch.relationships;
    }
    else {
      resourcePatch.relationships = patchRelationships;
    }
  }
  else if (attrSchema) {
    if (fieldValue === "" && JsonApiSchema.isNullable(attrSchema)) {
      fieldValue = null;
    }
    if (attrSchema.type === "number" && typeof fieldValue === "string") {
      if (fieldValue.trim() === "" || fieldValue.slice(-1) === ".") {
        fieldValue = fieldValue === "" ? null : fieldValue;
      }
      else {
        let num = Number(fieldValue);
        fieldValue = isNaN(num) ? fieldValue : num;
      }
    }
    if (fieldValue === resourceAttributes[fieldName]
      || (attrSchema.type === "string" && !fieldValue && !resourceAttributes[fieldName])
    ) {
      delete patchAttributes[fieldName];
    }
    else {
      patchAttributes[fieldName] = fieldValue;
    }
    if (!Object.keys(patchAttributes).length) {
      delete resourcePatch.attributes;
    }
    else {
      resourcePatch.attributes = patchAttributes;
    }
  }
  else {
    console.warn("No schema for field " + fieldName);
  }
  return resourcePatch;
}

function updateCollectionPatch(collection, collectionPatch, key, resourcePatch) {
  let patch1 = collectionPatch
    ? [...collectionPatch]
    : collection.map(res => (res.id ? {type: res.type, id: res.id} : res));
  if (resourcePatch) {
    patch1[key] = {...(patch1[key] || {}), ...resourcePatch};
  }
  else {
    patch1.splice(key, 1);
  }
  //TODO: set undefined if no changes
  return patch1;
}

function updatedCollection(collection, collectionPatch, template) {
  if (!collectionPatch) {
    return collection;
  }
  return collectionPatch.map(resourcePatch =>
    resourcePatch.id ? JsonApiSchema.findResource(resourcePatch, collection) || resourcePatch : template
  );
}

function mergeData(data1, data2) {
  if (!data1) {
    return data2;
  }
  if (!data2) {
    return data1;
  }
  return {
    ...data1,
    attributes: {
      ...data1.attributes,
      ...data2.attributes,
    },
    relationships: {
      ...data1.relationships,
      ...data2.relationships,
    },
  };
}

export {updateResourcePatch, updateCollectionPatch, updatedCollection, mergeData};
