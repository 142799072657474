/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Icon, Form, Segment, Sticky} from "semantic-ui-react";
import {useResourceApi} from "ui321/ResourceApi.js";
import {jsonSchemaValidator, applyValidator} from "ui321/single/Validate.js";
import Errors from "./Errors.js";
import {ResourceContext, ResourceStateContext, ResourceDispatchContext,
  ResourceEditorContext, ResourceEditorDispatchContext} from "./SingleResource.js";

function Editable(props) {
  const resource = React.useContext(ResourceContext);
  const resourceState = React.useContext(ResourceStateContext);
  const editor = React.useContext(ResourceEditorContext);
  const isEditing = editor.editing;
  const elemRef = React.useRef(null);
  const className = `single-resource single-resource-${resource.type} full-view`
    + (resourceState.isNew ? " creating " : "")
    + ` ${isEditing ? "editing" : "reading"} ui error form`;
  const sticky = (
    <Sticky context={elemRef} className="resource-sticky">
      <Segment className="resource-sticky-segment">
        {props.sticky !== undefined ? props.sticky : <DefaultSticky />}
      </Segment>
    </Sticky>
  );
  return (
    <div ref={elemRef} className={className} data-status={resourceState.status}>
      { isEditing ?
      <SingleResourceForm updateUrl={props.updateUrl} onSubmit={props.onSubmit}>
        {props.buttonsAt === "bottom"
         ? <>
          {props.children}
          {sticky}
         </>
         : <>
          {sticky}
          {props.children}
         </>
        }
      </SingleResourceForm>
      :
      <>
        {sticky}
        {props.children}
      </>
      }
    </div>
  );
}

function SingleResourceForm(props) {
  const resource = React.useContext(ResourceContext);
  const resourceState = React.useContext(ResourceStateContext);
  const resourceDispatch = React.useContext(ResourceDispatchContext);
  const {createResource, updateResource} = useResourceApi({updateUrl: props.updateUrl});
  const editor = React.useContext(ResourceEditorContext);
  const validateResource = useResourceValidator();
  const defaultOnSubmit = () => {
    resourceDispatch(["CLEAR_MESSAGES"]);
    const errors = validateResource();
    if (errors.length) {
      resourceDispatch(["ERROR", errors]);
      return;
    }
    if (resourceState.isNew) {
      createResource(resource, editor.patch);
    }
    else {
      updateResource(resource, editor.patch);
    }
  };
  return (
    <Form onSubmit={props.onSubmit || defaultOnSubmit}>
      {props.children}
    </Form>
  );
}

const trueSchema = {};

function useResourceValidator() {
  const {i18n} = useTranslation("ui321");
  const resource = React.useContext(ResourceContext);
  const editor = React.useContext(ResourceEditorContext);
  const jsonSchema = resource.meta ? resource.meta.jsonSchema : trueSchema;
  // TODO: show error instead of permitting validator    
  if (!resource.meta) {
    console.warn("Cannot create validator: resource.meta is empty");
  }
  const validate = React.useMemo( //memoize compile step
    () => jsonSchemaValidator(jsonSchema)
    , [jsonSchema]
  );
  return () => applyValidator(validate, resource, editor.patch, i18n.validation);
}

function ButtonSubmit(props) {
  const {t} = useTranslation("ui321");
  const resourceState = React.useContext(ResourceStateContext);
  const editor = React.useContext(ResourceEditorContext);
  const isModified = editor.patch.attributes || editor.patch.relationships;
  return (
    <Button type="submit"
      positive
      disabled={resourceState.status !== "IDLE" || (!resourceState.isNew && !isModified)}
      loading={resourceState.status === "SAVING"}
      >
      <Icon name="save" /> {props.label || t("Save")}
    </Button>
  );
}

function ButtonCancel(props) {
  const {t} = useTranslation("ui321");
  const resourceState = React.useContext(ResourceStateContext);
  const resourceDispatch = React.useContext(ResourceDispatchContext);
  const editorDispatch = React.useContext(ResourceEditorDispatchContext);
  return (
    <Button type="button"
      disabled={resourceState.status !== "IDLE"}
      onClick={() => {
        resourceDispatch(["CLEAR_MESSAGES"]);
        editorDispatch(["TURN", "OFF"]);
      }}>
      {t("Cancel")}
    </Button>
  );
}

function ButtonReset(props) {
  const {t} = useTranslation("ui321");
  const resourceState = React.useContext(ResourceStateContext);
  const resourceDispatch = React.useContext(ResourceDispatchContext);
  const editor = React.useContext(ResourceEditorContext);
  const editorDispatch = React.useContext(ResourceEditorDispatchContext);
  return (
    <Button type="button"
      disabled={resourceState.status !== "IDLE" || !Object.keys(editor.patch).length}
      onClick={() => {
        resourceDispatch(["CLEAR_MESSAGES"]);
        editorDispatch(["TURN", "OFF"]);
        editorDispatch(["TURN", "ON"]);
      }}>
      {t("Reset")}
    </Button>
  );
}

function EditorErrors(props) {
  const resourceState = React.useContext(ResourceStateContext);
  return (
   <Errors errors={resourceState.errorMessages} />
  );
}

function DefaultSticky(props) {
  const editor = React.useContext(ResourceEditorContext);
  if (!editor.editing) {
    return null;
  };
  return (
    <>
      <Button.Group>
        <ButtonSubmit />
        <ButtonCancel />
      </Button.Group>
      <EditorErrors />
    </>
  );
}

export {ButtonSubmit, ButtonCancel, ButtonReset, EditorErrors, useResourceValidator};
export default Editable
