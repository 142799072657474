import React from "react";
import Field from "ui321/fields/Field.js";

function RecordGroup(props) {
  return (
    <>
      <Field name="securitygroup_id" />
    </>
  );
}

export default RecordGroup
