/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils from "react-day-picker/moment";
import moment from "moment";

/**
 * Value in parent state contains date/datetime in api format when string is fully parsed.
 * And in user format when editing is in progress.
 */
const API_DATE_FORMAT = "YYYY-MM-DD";
const API_DATE_TIME_FORMAT = ["YYYY-MM-DDTHH:mm:ss.SSSSSSZ", "YYYY-MM-DDTHH:mm:ss.SSSZ", "YYYY-MM-DDTHH:mm:ssZ"];
const USER_DATE_FORMAT = moment.localeData()._longDateFormat.L;
const USER_DATE_TIME_FORMAT = moment.localeData()._longDateFormat.L + " HH:mm:ss";

//default react-day-picker format
//TODO: day-picker should not give me a date in its format
const DAY_PICKER_FORMAT = "YYYY-M-D";

function formatDate(date, format) {
  return moment(date).format(Array.isArray(format) ? format[0] : format);
}

function parseDate(str, format) {
  if (!str || typeof str !== "string" || !str.trim()) {
    return null;
  }
  const m = moment(str.trim(), format || USER_DATE_FORMAT, moment.locale(), true);
  return m.isValid() ? m.toDate() : null;
}

function parseDateTime(str, format) {
  return parseDate(str, format || USER_DATE_TIME_FORMAT);
}

function copyTime(dateFrom, dateTo) {
  const momentFrom = moment(dateFrom);
  return moment(dateTo)
    .hours(momentFrom.hours()).minutes(momentFrom.minutes()).seconds(momentFrom.seconds())
    .toDate();
}

function dayEquals(date1, date2) {
  return date1.getDate() === date2.getDate()
   && date1.getMonth() === date2.getMonth()
   && date1.getFullYear() === date2.getFullYear()
}

function translateDateFormat(format, t) {
  return format
    .replace(/Y/g, t("Year_dateTimeFormat", "Y"))
    .replace(/M/g, t("Month_dateTimeFormat", "M"))
    .replace(/D/g, t("Day_dateTimeFormat", "D"))
    .replace(/H/g, t("Hour_dateTimeFormat", "H"))
    .replace(/m/g, t("Minute_dateTimeFormat", "m"))
    .replace(/s/g, t("Second_dateTimeFormat", "s"))
}

function DateInput(props) {
  const {t} = useTranslation("ui321");
  let stringValue = props.value || "";
  const dateValue = parseDate(stringValue, API_DATE_FORMAT);
  if (dateValue) {
    stringValue = formatDate(dateValue, USER_DATE_FORMAT);
  }
  // see comment in DateTimeInput
  const dayPickerModifiers = {
    highlighted: day => !!dateValue && dayEquals(day, dateValue),
    disabled: day => !!dateValue && dayEquals(day, dateValue),
  }
  const userDateFormatTranslated = translateDateFormat(USER_DATE_FORMAT, t);
  return (
    <DayPickerInput
      value={stringValue}
      dayPickerProps={{
        locale: moment.locale(),
        localeUtils: MomentLocaleUtils,
        modifiers: dayPickerModifiers,
      }}
      parseDate={parseDate}
      placeholder={userDateFormatTranslated}
      format={USER_DATE_FORMAT}
      inputProps={{autoComplete: "off", ...props.inputProps}}
      onDayChange={(day, modifiers, dayPickerInput) => {
        const input = dayPickerInput.getInput();
        let newValue = input.value;
        let errors = undefined;
        if (input.value && input.value.trim()) {
          let newDate = parseDate(input.value, USER_DATE_FORMAT) || parseDate(input.value, DAY_PICKER_FORMAT);
          if (newDate) {
            newValue = formatDate(newDate, API_DATE_FORMAT);
            errors = [];
          }
          if (errors === undefined) {
            errors = [t("Expected date format") + ": " + userDateFormatTranslated];
          }
        }
        else {
          newValue = null;
        }
        props.onChange(newValue, errors);
      }}
    />
  );
}

function DateTimeInput(props) {
  const {t} = useTranslation("ui321");
  let stringValue = props.value || "";
  const dateTimeValue = parseDate(stringValue, API_DATE_TIME_FORMAT);
  if (dateTimeValue) {
    stringValue = formatDate(dateTimeValue, USER_DATE_TIME_FORMAT);
  }
  const dateValue = dateTimeValue || parseDate(stringValue, USER_DATE_FORMAT);
  // Very strange behavior when clicking on already selected day.
  // Value in parent state and this input become different.
  // It can be hacked with new String(props.value) constructor.
  // But I prefer to disable clicking and implementing own highlighting.
  const dayPickerModifiers = {
    highlighted: day => !!dateValue && dayEquals(day, dateValue),
    disabled: day => !!dateValue && dayEquals(day, dateValue),
  }
  const userDateTimeFormatTranslated = translateDateFormat(USER_DATE_TIME_FORMAT, t);
  return (
    <DayPickerInput
      value={stringValue}
      dayPickerProps={{
        locale: moment.locale(),
        localeUtils: MomentLocaleUtils,
        modifiers: dayPickerModifiers,
      }}
      parseDate={parseDateTime}
      placeholder={userDateTimeFormatTranslated}
      format={USER_DATE_TIME_FORMAT}
      onDayChange={(day, modifiers, dayPickerInput) => {
        const input = dayPickerInput.getInput();
        let newValue = input.value;
        let errors = undefined;
        if (input.value && input.value.trim()) {
          let newDateTime = parseDate(input.value, USER_DATE_TIME_FORMAT);
          if (newDateTime) {
            newValue = formatDate(newDateTime, API_DATE_TIME_FORMAT);
            errors = [];
          }
          else {
            const newDate = parseDate(input.value, DAY_PICKER_FORMAT);
            if (newDate) {
              if (dateTimeValue) {
                const newDateTime = copyTime(dateTimeValue, newDate);
                newValue = formatDate(newDateTime, API_DATE_TIME_FORMAT);
                errors = [];
              }
              else {
                newValue = formatDate(newDate, USER_DATE_FORMAT + " ");
              }
            }
          }
          if (errors === undefined) {
            errors = [t("Expected date/time format") + ": " + userDateTimeFormatTranslated];
          }
        }
        else {
          newValue = null;
        }

        props.onChange(newValue, errors);
      }}
      inputProps={props.inputProps}
    />
  );
}

function DateString(props) {
  let stringValue = props.value || "";
  const dateValue = parseDate(stringValue, API_DATE_FORMAT);
  if (dateValue) {
    stringValue = formatDate(dateValue, USER_DATE_FORMAT);
  }
  return (
    <span title={props.value}>
      {stringValue}
    </span>
  );
}

function DateTimeString(props) {
  let stringValue = props.value || "";
  const dateTimeValue = parseDate(stringValue, API_DATE_TIME_FORMAT);
  if (dateTimeValue) {
    stringValue = formatDate(dateTimeValue, USER_DATE_TIME_FORMAT);
  }
  return (
    <span title={props.value}>
      {stringValue}
    </span>
  );
}

const DateField = {
  DateInput,
  DateTimeInput,
  DateString,
  DateTimeString,
};

export default DateField
