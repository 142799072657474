/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Leon Nikitin <nlv@lab321.ru>
 * Author Dmitriy Yankovskiy <ydv@lab321.ru>
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {Segment} from "semantic-ui-react";
import Folder from "ui321/Folder.js";
import Editable from "ui321/single/Editable.js";
import {ButtonEdit, ButtonDelete} from "ui321/single/Buttons.js";
import {ResourceContext, ResourceEditorContext} from "ui321/single/SingleResource.js";
import Field from "ui321/fields/Field.js";
import RecordGroupsFolder from "app/resources/SecurityGroupsRecords/RecordGroupsFolder";
// import {ClientValidationClosed} from "app/resources/ClientValidations/ClientValidation.js";
import {ResourceAuditButton} from "app/resources/Audit/Audit.js";
import {ConnectionContext} from "ui321/Connection.js";

function FullView(props) {
  const {t} = useTranslation("Leads");
  const editor = React.useContext(ResourceEditorContext);
  const isEditing = editor.editing;
  const connection = React.useContext(ConnectionContext);
  return (
    <Editable>
      <LeadButtons />
      <Folder title={t("Overview", {context: "folderTitle"})}>
        <Field name="last_name" />
        <Field name="first_name" />
        <Field name="description" />
      </Folder>
      <Folder title={t("Organization", {context: "folderTitle"})}>
        <Field name="org_name" />
        <Field name="org_shortname" />
        <Field name="resident" />
        <Field name="inn" />
        <Field name="kio" />
        <Field name="kpp" />
        <Field name="ogrn" />
        <Field name="date_registration" />
      </Folder>
      <Folder title={t("FLD", {context: "folderTitle"})}>
        <Field name="fld_citizenship" />
        <Field name="fld_lastname" />
        <Field name="fld_firstname" />
        <Field name="fld_patronymic" />
        <Field name="fld_type_of_identity" />
        <Field name="fld_passport_serial" />
        <Field name="fld_passport_number" />
        <Field name="fld_birthdate" />
        <Field name="fld_birthplace" />
      </Folder>
      <Folder title={t("Other", {context: "folderTitle"})}>
        <Field name="date_entered" />
        <Field name="created_by" />
        <Field name="date_modified" />
        <Field name="modified_user_id" />
        <Field name="assigned_user_id" />
      </Folder>
      <RecordGroupsFolder />
      { !isEditing &&
        <Folder title={t("calls", {context: "folderTitle"})} folded>
          <Field name="calls"
            fieldProps={{
              defaultData: {
                relationships: {
                  assigned_user_id: {
                    data: connection && connection.auth && connection.auth.userId ? {
                      type: "Users",
                      id: connection.auth.userId,
                    } : null,
                  },
                },
              }
            }}
          />
        </Folder>
      }
    </Editable>
  );
}

function LeadButtons(props) {
  const resource = React.useContext(ResourceContext);
  const editor = React.useContext(ResourceEditorContext);
  const isEditing = editor.editing;
  if (!resource) {
    return null;
  }
  if (isEditing) {
    return null;
  }
  return (
    <Segment basic vertical className="single-view-buttons">
      {/* <GoSugarButton resource={resource} /> */}
      <ButtonEdit />
      {/* <ClientValidationClosed
        parentType="Leads"
        parentId={resource.id}
        defaultActiveIndex={2}
        trigger={props => (
          <Button type="button" onClick={() => {props.setOpened(true)}}>Проверка</Button>
        )}
      /> */}
      <ButtonDelete />
      <ResourceAuditButton />
    </Segment>
  );
}

export default FullView
