/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Leon Nikitin <nlv@lab321.ru>
 */

import i18n from "i18n.js";
import ru_i18n from "./i18n/ru.Leads.json";
import FullView from "./FullView.js";
import LeadFullName from "./LeadFullName.js";
import FetchCreationAccess from "app/FetchCreationAccess.js";

const resourceType = "Leads";

i18n.addResourceBundle("ru", resourceType, ru_i18n);

const Leads = {
  resourceType,
  FullView,
  titleField: "last_name",
  titleTemplate: LeadFullName,
  defaultSort: [["date_entered", "desc"]],
  fieldNames: [
    "last_name",
    "first_name",
    "org_name",
    "org_shortname",
    "resident",
    "inn",
    "kio",
    "kpp",
    "ogrn",
    "date_registration",
    "fld_citizenship",
    "fld_lastname",
    "fld_firstname",
    "fld_patronymic",
    "fld_type_of_identity",
    "fld_passport_serial",
    "fld_passport_number",
    "fld_birthdate",
    "fld_birthplace",
    "description",
    "assigned_user_id",
    "date_entered",
    "created_by",
    "date_modified",
    "modified_user_id",
  ],
  itemViewFields: {
    "meta": [
    ],
    "description": [
      "name",
      "description",
      "assigned_user_id",
    ],
    "extra": [
        "date_entered",
        "created_by",
    ]
  },
  creationAccess: FetchCreationAccess,
  reverseRelationships: {
    "calls": "parent",
  },
};

export default Leads
