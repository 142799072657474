/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";

const checkStatus = response => checkStatus_(false)(response)

//TODO: use ui321/Request.js instead
const checkStatus_ = skipAuthError => response => {
  if (response.status === 401/*  || response.status === 403 */) {
    if (skipAuthError) {
      return new Response(false);
    }
    return response.text().then(text => {
      throw new Error403(text)
    })
  }
  // if (response.status === 404) {
  //   return response.text().then(text => {
  //     throw new Error404(text)
  //   })
  // }
  // if (response.status >= 500 && response.status <= 599) {
  //   var message = 'Ошибка сервера';
  //   if (response.status === 504) {
  //     message = 'Gateway Time-out';
  //   }
  //   throw new Error5xx(message);
  // }
  if (!response.ok) {
    return response.text().then(text => {
      let message = '';
      try {
        message = JSON.parse(text).errors.map(err => err.title).join("; ");
      } catch(pe) {
        const elem = document.createElement('html'); //is it safe?
        elem.innerHTML = text;
        const body = elem.getElementsByTagName('body');
        message = (body.length && body[0].textContent) || elem.textContent;
      }
      throw new Error(message)
    })
  }
  return response;
};

class Error403 extends Error {
  name = 'Error403';
}

const jsonHeaders = new Headers({
  "Content-Type": "application/json",
  "X-Requested-With": "fetch",
});

const ApiConfigContext = React.createContext({
  urlPrefix: "",
  headers: jsonHeaders,
});

export {checkStatus, checkStatus_, jsonHeaders};
export {ApiConfigContext};
