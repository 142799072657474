/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {withTranslation} from "react-i18next";
import {Route, Link} from "react-router-dom";
import {Button, Icon, Input, Placeholder, Menu} from "semantic-ui-react";

const appendTreeLink = (tree, link) => {
  if (link.group && link.group.length) {
    const groupName = link.group[0];
    const link1 = {...link, group: link.group.slice(1)}
    if (tree.length && tree[tree.length - 1].header === groupName) {
      tree[tree.length - 1].items = appendTreeLink(tree[tree.length - 1].items, link1);
    }
    else {
      tree.push({header: groupName, items: appendTreeLink([], link1)});
    }
  }
  else {
    tree.push({link: link});
  }
  return tree;
}

const renderTreeMenu = treeMenu => treeMenu.map((node, idx) =>
    node.header !== undefined ?
      <Menu.Item key={idx}>
        <Menu.Header>{node.header}</Menu.Header>
        <Menu.Menu>
          {renderTreeMenu(node.items)}
        </Menu.Menu>
      </Menu.Item> :
      <Route key={idx} path={node.link.path} exact={node.link.exact} children={({ match }) => (
        <Link to={node.link.path} className={(match ? "active " : "") + "item"}>
          {node.link.label}
        </Link>
      )} />
    )

class ResourcesMenu extends React.Component {
  state = {
    filtering: "",
  }

  setFiltering = filtering => this.setState({filtering})

  render() {
    const props = this.props;
    const t = this.props.t;
    const appLinksResources = (props.appLinks || []).filter(l => !!l.resource).map(l => l.resource)
    const otherResources = (props.resources || []).filter(r => appLinksResources.indexOf(r.name) === -1)
    const otherGroup = props.appLinks && props.appLinks.length ? [t("Other")] : [];
    const resourcesLinks = otherResources.map(r =>
      ({
        path: `/${r.name}`,
        label: r.label || r.name,
        group: r.group || otherGroup,
      })
    )
    const allLinks = (props.appLinks || []).concat(resourcesLinks);
    const filteringLC = this.state.filtering.toLowerCase();
    const links = this.state.filtering ?
      allLinks.filter(link =>
        link.label.toLowerCase().indexOf(filteringLC) !== -1
        || link.path.toLowerCase().indexOf(filteringLC) !== -1
      ) : allLinks;
    const treeMenu = links.reduce(appendTreeLink, []);
    return <>
      <Route path="/" exact children={({ match }) => (
        <Link to="/" className={(match ? "active " : "") + "item"}>
          <Icon name="home" /> {props.homeLinkText || t("Homepage")}
        </Link>
      )} />
      <Menu.Item>
        <Input placeholder={t("Menu filter")}
          icon={!this.state.filtering && "filter"}
          value={this.state.filtering}
          onChange={(e, {name, value}) => this.setFiltering(value)}
          label={ () => this.state.filtering &&
            <Button type="button"
              basic icon className="clear-input" onClick={() => this.setFiltering("")}>
              <Icon name="delete" />
            </Button> }
        />
      </Menu.Item>
      { renderTreeMenu(treeMenu) }
      { props.resources === null &&
      <Menu.Item disabled>
        <Placeholder fluid inverted>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
    </Menu.Item> }
    </>
  }
}

const addLabelsFromMenu = (menu, resources) => menu.reduce((res, link) =>
  link.resource && link.label && (!res[link.resource] || !res[link.resource].resourceLabel) ?
    {...res, [link.resource]: {...(res[link.resource] || {}), resourceLabel: link.label} } :
    res
  , resources)

export { addLabelsFromMenu };
export default withTranslation("ui321")(ResourcesMenu)
