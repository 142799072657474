/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Icon, Loader, Message} from "semantic-ui-react";
import {NavStateContext, navParamsAreEquals}from "ui321/collection/CollectionNavigation.js";
import {useRequest} from "ui321/Request.js";

function CollectionSelectionPanel(props) {
  const {t} = useTranslation("ui321");
  const selection = React.useContext(CollectionSelectionContext);
  if (!selection) {
    return null;
  }
  return (
    <div className="collection-selection-panel">
      { selection.selected &&
        <span className="collection-selection-total">
          {t("Selected")}: {selection.selected.length}
        </span>
      }
      <CollectionSelectionReset />
      <CollectionSelectionAll />
    </div>
  );
}

function CollectionSelectionReset(props) {
  const {t} = useTranslation("ui321");
  const selection = React.useContext(CollectionSelectionContext);
  if (!selection.updateSelection) {
    return null;
  }
  return (
    <span className="collection-selection-reset">
      <Button type="button"
        onClick={() => {
          selection.updateSelection({type: "DESELECT_ALL"});
        }}
      >
        {t("Reset selection")}
      </Button>
    </span>
  );
}

function CollectionSelectionAll(props) {
  const {t} = useTranslation("ui321");
  const selection = React.useContext(CollectionSelectionContext);
  const [started, setStarted] = React.useState(false);
  const navState = React.useContext(NavStateContext);
  if (!selection.updateSelection) {
    return null;
  }
  return (
    <span className="collection-selection-all">
      { !started &&
        <Button type="button"
          loading={started}
          disabled={started}
          onClick={() => {
            if (navParamsAreEquals(navState.params, navState.editedParams)
              || window.confirm(t("Filter before changing will be used")))
            {
              setStarted(true);
            }
          }}
        >
          {t("Load and select all")}
        </Button>
      }
      { started &&
        <CollectionSelectionPagedDownloader
          setFinished={() => setStarted(false)}
        />
      }
    </span>
  );
}

function CollectionSelectionPagedDownloader(props) {
  const {t} = useTranslation("ui321");
  const selection = React.useContext(CollectionSelectionContext);
  const navState = React.useContext(NavStateContext);
  const [pageNumber, setPageNumber] = React.useState(1);
  const dataRef = React.useRef([]);
  const pageSize = process.env.REACT_APP_SELECTION_DOWNLOAD_PAGE_SIZE || 1000;
  const navState1 = {
    ...navState,
    defaultPageSize: pageSize,
    pageNumber: pageNumber,
    params: {
      ...navState.params,
      pageSize: pageSize,
    },
  };
  const url = selection.updateSelection ? navState1.urlBuilder(navState1) : null;
  const updateSelection = selection.updateSelection;
  const setFinished = props.setFinished;
  const launchOptions = React.useMemo(() => ({
    onComplete: response => {
      dataRef.current = [...dataRef.current, ...response.data];
      if (response.links.next) {
        setPageNumber(num => num + 1);
      } else {
        setFinished();
        updateSelection({type: "SELECT", value: dataRef.current});
      }
    },
  }), [updateSelection, setFinished]);
  const {error, isLoading} = useRequest(url, undefined, undefined, launchOptions);
  if (!selection.updateSelection) {
    return null;
  }
  return (
    <span className="collection-selection-downloader">
      { isLoading &&
        <>
          <span>
            <Loader active inline size="tiny" className="collection-selection-loader" />
            {" "}{t("Loading")}&hellip;
            {dataRef.current.length || ""}
          </span>
          {" "}
          <Button type="button"
            icon
            onClick={() => props.setFinished()}
          >
            <Icon name="stop" />
            {" "}
            {t("Stop")}
          </Button>
        </>
      }
      { error &&
        <Message error content={error} onDismiss={props.setFinished} /> }
    </span>
  );
}

const CollectionSelectionContext = React.createContext({});

export {CollectionSelectionPanel, CollectionSelectionContext};
