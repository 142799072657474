import i18n from "i18n.js";
import en_i18n from "./i18n/en.ExtendedOrders.json";
import ru_i18n from "./i18n/ru.ExtendedOrders.json";
import FullView from "./FullView.js";

const resourceType = "ExtendedOrders";

i18n.addResourceBundle("en", resourceType, en_i18n);
i18n.addResourceBundle("ru", resourceType, ru_i18n);

const ExtendedOrders = {
  resourceType,
  FullView,
  titleField: "name",
  defaultSort: [["date_entered", "desc"]],
  fieldNames: [
    "name",
    "status",
    "order_type",
    "account_name",
    "amount",
    "date_closed",
    "assigned_user_id",
    "manager_user_id",
    "check_result",
    "check_comment",
    "check_user_id",
    "description",
    "date_entered",
    "created_by",
    "date_modified",
    "modified_user_id",
  ],
  itemViewFields: {
    "meta": [
        "status",
        "assigned_user_id"
    ],
    "description": [
        "name",
        "description"
    ],
    "extra": [
        "date_entered",
        "created_by",
        "date_modified",
        "modified_user_id",
    ]
  },
  reverseRelationships: {
    "record_id-securitygroupsrecords": "record_id",
  },
  creationAccess: true,
};

export default ExtendedOrders
