import React from "react";
import {useTranslation} from "react-i18next";
import Folder from "ui321/Folder.js";
import Field from "ui321/fields/Field.js";

function FullView(props) {
  const {t} = useTranslation("ACLRolesUsers");
  return (
    <>
      <Folder title={t("Overview", {context: "folderTitle"})}>
        <Field name="role_id" />
        <Field name="user_id" />
      </Folder>
      <Folder title={t("Other", {context: "folderTitle"})}>
        <Field name="date_modified" />
      </Folder>
    </>
  );
}


export default FullView
