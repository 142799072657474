import React from "react";
import {useTranslation} from "react-i18next";
import {Dropdown, Form, Segment} from "semantic-ui-react";

function Profile(props) {
  const {t, i18n} = useTranslation("app");

  const languages = {
    en: "English",
    ru: "Русский",
  };
  const initLang = window.localStorage ? localStorage.getItem("i18nextLng") || "" : "";
  const [language, setLanguage] = React.useState(initLang);
  const chooseLanguage = lang => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    if (window.localStorage) {
      localStorage.setItem("i18nextLng", lang);
    }
  }
  // TODO: synchronize between tabs

  const themes = window.themes;
  const initTheme = window.localStorage ? localStorage.getItem("theme") || "default" : "default";
  const [theme, setTheme] = React.useState(initTheme);
  const chooseTheme = theme => {
    setTheme(theme);
    window.applyTheme(theme);
    if (window.localStorage) {
      localStorage.setItem("theme", theme);
    }
  }

  return (
    <>
      <h2>{t("Interface settings")}</h2>
      <Segment>
        <Form>
          <Form.Field inline>
            <label>{t("Language")}</label>
            <Dropdown trigger={languages[language] || t("Default")}>
              <Dropdown.Menu>
              { Object.keys(languages).map(code =>
                <Dropdown.Item key={code}
                  onClick={() => chooseLanguage(code)}
                  active={code === language}>
                  {languages[code]}
                </Dropdown.Item>
              )}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Field>
          <Form.Field inline>
            <label>{t("Theme")}</label>
            <Dropdown trigger={ theme ?
              themes[theme] && themes[theme].label :
              themes["default"].label }>
              <Dropdown.Menu>
              { Object.keys(themes).map(name =>
                <Dropdown.Item key={name}
                  onClick={() => chooseTheme(name)}
                  active={name === theme}>
                  {themes[name].label}
                </Dropdown.Item>
              ) }
              </Dropdown.Menu>
            </Dropdown>
          </Form.Field>
          <p><a href="https://semantic-ui.com/introduction/getting-started.html" target="_blank" rel="noopener noreferrer">Semantic-UI</a>
            , <a href="http://semantic-ui-forest.com/themes/" target="_blank" rel="noopener noreferrer">Semantic-UI-Forest</a>.
          </p>
        </Form>
      </Segment>
    </>
  );
}

export default Profile
