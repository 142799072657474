import React from "react";
import {useTranslation} from "react-i18next";
import {Route, Switch} from "react-router-dom";
import ResourceRoutes from "ui321/ResourceRoutes.js";
import CalendarPage from "./CalendarPage.js";
import Home from "./Home.js";
import PageNotImplemented from "./PageNotImplemented.js";
import Profile from "./Profile.js";
import {AuditPage} from "./resources/Audit/Audit.js";

const appTitle = "ui321-crm-demo1"; //TODO: appTitle is defined in App.js

function AppRoutes(props) {
  const {t} = useTranslation("app");
  return (
    <Switch location={props.location}>
      <Route exact path="/" render={props =>
        <Home title={
          <span>{t("Welcome to")} <q>{appTitle}</q></span>} />} />
      <Route exact path="/calendar" component={CalendarPage} />
      <Route path="/myprofile" component={Profile} />
      <Route path="/:resource/:id/audit" render={ route =>
        <AuditPage resourceType={route.match.params.resource} id={route.match.params.id} />
      }/>
      <Route path="/TODO*" component={PageNotImplemented} />
      <ResourceRoutes />
    </Switch>
  );
}

export default AppRoutes
