/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Icon} from "semantic-ui-react";
import {useResourceSettings} from "ui321/ResourceSettings.js";
import FieldTemplate from "ui321/form/FieldTemplate.js";
import SingleResource, {ResourceContext} from "./SingleResource.js";
import AutoFullView from "./AutoFullView.js";

const defaultCreationState = {
  opened: false,
  createdResources: [],
  creationAccess: undefined,
  resourceType: undefined,
};

function WithResourceCreation(props) {
  const initialState = {
    ...defaultCreationState,
    resourceType: props.resourceType,
  };
  const [state, dispatch] = React.useReducer(creationReducer, initialState);
  const settings = useResourceSettings(state.resourceType);

  return (
    <ResourceCreationDispatchContext.Provider value={dispatch}>
    <ResourceCreationContext.Provider value={state}>
      { typeof settings.creationAccess === "boolean"
        ? <BoolCreationAccess value={settings.creationAccess} />
        : (settings.creationAccess ? React.createElement(settings.creationAccess) : null)
      }
      { state.opened ?
        <SingleResource
          key={state.createdResources.length}
          resourceType={state.resourceType}
          id={null}
          editing
          fullView={CreationOnlyForm}
          constData={props.constData}
          defaultData={props.defaultData}
          onEditingCancel={() => {
            dispatch(["OPENED", false]);
          }}
          onCreate={res => {
            dispatch(["ADD_CREATED", res]);
            dispatch(["OPENED", false]);
          }}
        />
        : props.children
      }
    </ResourceCreationContext.Provider>
    </ResourceCreationDispatchContext.Provider>
  );
}

/** Shows form only when id is empty. This prevents unnecessary to-many-relationship loading. */
function CreationOnlyForm(props) {
  const resource = React.useContext(ResourceContext);
  const settings = useResourceSettings(resource.type);
  return resource.id ? null : React.createElement(settings.FullView || AutoFullView, props);
}

function creationReducer(state, action) {
  if (Array.isArray(action)) {
    return creationReducer(state, {type: action[0], value: action[1]});
  }
  if (action.type === "OPENED") {
    return {
      ...state,
      opened: typeof action.value === "function" ? action.value(state.opened) : action.value,
    };
  }
  if (action.type === "ADD_CREATED") {
    return {
      ...state,
      createdResources: [...state.createdResources, action.value],
    };
  }
  if (action.type === "CREATION_ACCESS") {
    return {
      ...state,
      creationAccess: typeof action.value === "function" ? action.value(state.creationAccess) : action.value,
    };
  }
  throw new Error(`Unknown action type "${action.type}" in creationReducer.`);
}

function CreationButton(props) {
  const {t} = useTranslation("ui321");
  const state = React.useContext(ResourceCreationContext);
  const dispatch = React.useContext(ResourceCreationDispatchContext);
  return (
    <Button type="button" positive className="create"
      disabled={!state.creationAccess}
      onClick={() => {
        dispatch(["OPENED", true]);
      }}
    >
      <Icon name="plus" /> {t([`${state.resourceType}:Create`, "Create"])}
    </Button>
  );
}

function BoolCreationAccess(props) {
  const value = props.value;
  const dispatch = React.useContext(ResourceCreationDispatchContext);
  React.useEffect(() => {
    if (typeof value === "boolean") {
      dispatch(["CREATION_ACCESS", value]);
    }
  }, [value, dispatch]);
  return null;
}

function useCreatedResourcesFilter() {
  const state = React.useContext(ResourceCreationContext);
  return state.createdResources.length ? ["EqualsAny", "id", state.createdResources.map(res => res.id)] : null;
}

function JustCreatedFilterField(props) {
  const {t} = useTranslation("ui321");
  return (
    <FieldTemplate>
      <label className="just-created">{t("ui321:Just created")}</label>
    </FieldTemplate>
  );
}

const ResourceCreationContext = React.createContext(defaultCreationState);
const ResourceCreationDispatchContext = React.createContext(() => {
  console.warn("Consuming ResourceCreationDispatchContext without Provider");
});

export {WithResourceCreation, CreationButton, BoolCreationAccess,
  ResourceCreationContext, ResourceCreationDispatchContext,
  useCreatedResourcesFilter, JustCreatedFilterField};
