import React from "react";
import {useTranslation} from "react-i18next";
import {Segment} from "semantic-ui-react";
import Folder from "ui321/Folder.js";
import {ButtonEdit, ButtonDelete} from "ui321/single/Buttons.js";
import {ResourceContext, ResourceEditorContext} from "ui321/single/SingleResource.js";
import Editable from "ui321/single/Editable.js";
import Field, {Break} from "ui321/fields/Field.js";
import {StateEventToolbar, EventForm, NextStateAfterEvent,
  useStateEvent, StateEventContext, StateEventDispatchContext} from "ui321/biz/StateEvent.js";
import {ResourceAuditButton} from "app/resources/Audit/Audit.js";
import RecordGroupsFolder from "app/resources/SecurityGroupsRecords/RecordGroupsFolder.js";
import "./ExtendedOrders.css";

const eventFields = ["filling_completed_event", "checking_event", "bookkeeping_check", "cancel_event"];

function FullView(props) {
  const editor = React.useContext(ResourceEditorContext);
  const isEditing = editor.editing;
  const {t} = useTranslation("ExtendedOrders");
  const [stateEvent, stateEventDispatch] = useStateEvent();
  return (
    <StateEventDispatchContext.Provider value={stateEventDispatch}>
    <StateEventContext.Provider value={stateEvent}>
    <Editable>
      <OrderButtons />
      { !isEditing &&
        <EventForm fullView={OrderEventForm} />
      }
      <Folder title={t("Overview", {context: "folderTitle"})}>
        <Field name="name" />
        {/* <Field name="status" /> */}
        <Field name="order_type" />
        <Field name="account_name" />
        <Break />
        <Field name="amount" fieldProps={{locale: ''}} />
        <Field name="currency_code" />
        <Field name="amount_converted" fieldProps={{locale: ''}} />
        <Break />
        <Field name="date_closed" />
        <Field name="description" />
        <Field name="manager_user_id" />
        <Break />
        <Field name="filling_completed_event" />
      </Folder>
      <Folder title={t("Check", {context: "folderTitle"})}>
        <Field name="check_result" />
        <Field name="check_comment" />
        <Field name="check_user_id" />
        <Field name="check_date" />
        <Field name="bookkeeping_check" />
      </Folder>
      <Folder title={t("Other", {context: "folderTitle"})}>
        <Field name="date_entered" />
        <Field name="created_by" />
        <Field name="date_modified" />
        <Field name="modified_user_id" />
        <Field name="assigned_user_id" />
        <Field name="error_message" />
      </Folder>
      <RecordGroupsFolder />
      {/* <Folder title={t("Groups again", {context: "folderTitle"})} folded> //groups subpanel
        <Field name="record_id-securitygroupsrecords" />
      </Folder> */}
    </Editable>
    </StateEventContext.Provider>
    </StateEventDispatchContext.Provider>
  );
}

function OrderButtons(props) {
  const resource = React.useContext(ResourceContext);
  const editor = React.useContext(ResourceEditorContext);
  const isEditing = editor.editing;
  if (!resource) {
    return null;
  }
  if (isEditing) {
    return null;
  }
  return (
    <Segment basic vertical className="single-view-buttons">
      <ButtonEdit />
      <StateEventToolbar eventFields={eventFields} />
      <ResourceAuditButton />
      <ButtonDelete />
    </Segment>
  );
}

function OrderEventForm() {
  const stateEvent = React.useContext(StateEventContext);
  if (stateEvent.name === "checking_event" && (stateEvent.value === "negative" || stateEvent.value === "data_necessary")) {
    return (
      <>
        <NextStateAfterEvent stateField="status" />
        <Editable buttonsAt="bottom">
          <Field name={stateEvent.name} />
          <Field name="check_comment" />
        </Editable>
      </>
    );
  }
  return (
    <Editable buttonsAt="bottom">
      <Field name={stateEvent.name} />
      <NextStateAfterEvent stateField="status" />
    </Editable>
  );
}

export default FullView
