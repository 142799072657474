/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {Message} from "semantic-ui-react";
import Ajv from "ajv";
import {updateResourcePatch, updateCollectionPatch, mergeData} from "ui321/single/Edit.js";
import {useMetadata} from "ui321/ResourceMetadata.js";
import {ResourceSettingsContext} from "ui321/ResourceSettings.js";
import AutoFullView from "ui321/single/AutoFullView.js";
import Loading from "ui321/Loading.js";
import JsonApiSchema from "ui321/json/JsonApiSchema.js";
import {ApiConfigContext} from "ui321/json/Request.js";
import {err401, responseErrorMessage} from "ui321/Request.js";
import {ConnectionContext} from "ui321/Connection.js";

const NO_PROP_NAME = "_"

const defaultResourceState = {
  data: null,
  included: [],
  isNew: null,
  status: "IDLE",
  successMessages: [],
  errorMessages: [],
  localVersion: 0,
};

function SingleResource(props) {
  const {t, i18n} = useTranslation("ui321");
  const apiConfig = React.useContext(ApiConfigContext);
  const connection = React.useContext(ConnectionContext);
  const reconnect = connection.reconnect;
  const [metadata, metadataIsLoading, metadataFetchingErrors] = useMetadata(props.resourceType);
  const contextIncluded = React.useContext(ResourceCollectionContext);
  const initialResourceState = {
    ...defaultResourceState,
    data: props.resource || {
      type: props.resourceType,
      id: props.id,
    },
    included: contextIncluded,
    isNew: !props.id,
  };
  const [resourceState, resourceDispatch] = React.useReducer(resourceReducer, initialResourceState);
  const resource = resourceState.data;
  const setResource = res => {resourceDispatch({type: "RESOURCE", value: res})};
  const included = resourceState.included;
  const addIncluded = included => {resourceDispatch({type: "INCLUDED", value: included0 => included.concat(included0)})};
  const allSettings = React.useContext(ResourceSettingsContext);
  const resourceSettings = allSettings.get(props.resourceType);
  const validate = React.useMemo(() => {
    if (!metadata) {
      return null;
    }
    const ajv = new Ajv({allErrors: true, jsonPointers: true});
    require("ajv-errors")(ajv);
    return ajv.compile((metadata || {}).jsonSchema || {});
  }, [metadata]);
  const initialEditing = props.editing !== undefined ? props.editing : false;
  const initialEditor = {
    ...defaultEditor,
    editing: initialEditing,
    patch: mergeData(props.defaultData, props.constData) || {},
  };

  const editorReducer = React.useMemo(() => {
    return (state, action) => {
      if (Array.isArray(action)) {
        return editorReducer(state, {type: action[0], value: action[1]});
      }
      switch (action.type) {
        case "TURN": switch (action.value) {
          case "ON":
            return {...state, editing: true};
          case "OFF":
            return {...state, editing: false, patch: {}, errors: {}};
          default:
            throw new Error(`Unknown action value "${action.value}" for TURN action type.`);
        }
        case "CHANGE": {
          const {fieldName, inputErrors} = action;
          let value = action.value;
          //TODO: accept value as function with editor.patch argument, or even accept only function
          const data = updateResourcePatch(resource, state.patch, fieldName, value);
          let attributes = data.attributes || {};
          let relationships = data.relationships || {};
          // const attrSchema = findAttributeSchema(fieldName, resource.meta.jsonSchema);
          // const relSchema = findRelationshipSchema(fieldName, resource.meta.jsonSchema);
          // let data = null;
          // const resourceAttributes = resource.attributes || {};
          // let attributes = state.patch.attributes || {};
          // let relationships = state.patch.relationships || {};
          // if (relSchema) {
          //   const prevValue = ((resource.relationships || {})[fieldName] || {}).data;
          //   if ((value && prevValue && value.id === prevValue.id && value.type === prevValue.type)
          //     || (!value && !prevValue)) {
          //     delete relationships[fieldName];
          //   }
          //   else {
          //     relationships[fieldName] = {data: value};
          //   }
          //   data = {relationships: {...{[fieldName]: (resource.relationships || {})[fieldName]}, ...relationships}};
          // }
          // else if (attrSchema) {
          //   if (attrSchema.type === "number") {
          //     if (value.trim() === "") {
          //       value = value === "" ? null : value;
          //     }
          //     else {
          //       let num = Number(value);
          //       value = isNaN(num) ? value : num;
          //     }
          //   }
          //   if (value === resourceAttributes[fieldName]
          //     || (attrSchema.type === "string" && !value && !resourceAttributes[fieldName])
          //   ) {
          //     delete attributes[fieldName];
          //   }
          //   else {
          //     attributes[fieldName] = value;
          //   }
          //   data = {attributes: {...{[fieldName]: resourceAttributes[fieldName] || undefined}, ...attributes}};
          // }
          // else {
          //   console.warn("No schema for field " + fieldName);
          // }
          let errors = {};
          if ((inputErrors || []).length) {
            errors[fieldName] = inputErrors;
          }
          else if ((data.attributes || {})[fieldName] !== undefined
          || (data.relationships || {})[fieldName] !== undefined) {
            const valid = validate(data);
            if (!valid) {
              errors = processAjvErrors(validate.errors, i18n.ajv);
            }
            const validConst = true; // validateConst(data);
            if (!validConst) {
              // const errorsConst = processAjvErrors(validateConst.errors);
              // errors = {...errors, ...errorsConst};
            }
          }
          let patch1 = {};
          if (Object.keys(attributes).length) {
            patch1.attributes = attributes;
          }
          if (Object.keys(relationships).length) {
            patch1.relationships = relationships;
          }
          return {
            ...state,
            patch: patch1,
            // errorMap: {
            errors: {
              ...state.errors,
              [fieldName]: errors[fieldName],
            },
          };
        }
        case "CHANGE_COLLECTION": {
          const {fieldName, key, value, collection} = action;
          let collectionPatch = ((state.patch.relationships || {})[fieldName] || {}).data;
          const collectionPatch1 = updateCollectionPatch(collection, collectionPatch, key, value);
          return editorReducer(state, {type: "CHANGE", fieldName: fieldName, value: collectionPatch1});
        }
        case "VALIDATE": {
          // is it used? see Validate.js instead
          let attributes = state.patch.attributes || {};
          let relationships = state.patch.relationships || {};
          const allData = {
            attributes: {...resource.attributes, ...attributes},
            relationships: {...resource.relationships, ...relationships},
          };
          const valid = validate(allData);
          let errors = {};
          if (!valid) {
            errors = processAjvErrors(validate.errors, i18n.ajv);
            console.log("validate.errors", validate.errors);
          }
          // const validConst = validateConst(allData);
          // if (!validConst) {
          //   const errorsConst = processAjvErrors(validateConst.errors, visibleFields || []);
          //   errors = {...errors, ...errorsConst};
          // }
          return {
            ...state,
            errors: errors,
          };
        }
        default:
          throw new Error(`Unknown action type "${action.type}".`);
      }
    };
  },[resource, validate, i18n.ajv]);

  const [editor, editorDispatch] = React.useReducer(editorReducer, initialEditor);
  const isEditing = editor.editing;
  const resourceVersion = resourceState.localVersion;
  useEditingCancelListener(editor, props.onEditingCancel);
  useCreateListener(resourceState, res => {
    resourceDispatch(["CLEAR_MESSAGES"]);
    editorDispatch(["TURN", "OFF"]);
    if (props.onCreate) {
      props.onCreate(res);
    }
  });
  useUpdateListener(resourceState, () => {
    if (props.onUpdate) {
      props.onUpdate();
    }
    else {
      editorDispatch(["TURN", "OFF"]);
    }
  });

  React.useEffect(() => {
    let ignore = false;
    async function fetchResource() {
      resourceDispatch(["STATUS", "FETCHING"]);
      const includeResources =
        Object.keys((((metadata.jsonSchema.properties || {}).relationships || {}).properties || {}))
          .filter(field => {
            const relSchema = JsonApiSchema.findRelationshipSchema(field, metadata.jsonSchema);
            // const relatedResourceType = getRelationshipResourceType(relSchema);
            // const relatedResourceType1 = typeof relatedResourceType === "object" ? relatedResourceType[0] : relatedResourceType;
            return relSchema.type !== "array"
              // && !!allSettings.get(relatedResourceType1).titleField
              // && fieldNames.indexOf(field) !== -1
          });
      const url = `${apiConfig.urlPrefix}/${props.resourceType}/${props.id}`
        + (includeResources.length ? "?include=" + includeResources.join(",") : "")
      const response = await fetch(url, apiConfig.init);
      if (!response.ok) {
        const message = await responseErrorMessage(response, t);
        resourceDispatch(["STATUS", "IDLE"]);
        resourceDispatch(["ERROR", message]);
        if (message === err401) {
          reconnect(() => {
            resourceDispatch(["CLEAR_MESSAGES"]);
            fetchResource();
          }, t("401", {context: "statusCode"}));
        }
        return;
      }
      const json = await response.json();
      // TODO: catch and set resource = null
      resourceDispatch(["STATUS", "IDLE"]);
      if (!ignore) {
        if (json.data) {
          let jsonSchema = (metadata || {}).jsonSchema && (json.meta || {}).jsonSchema
                ? {...metadata.jsonSchema, ...json.meta.jsonSchema} //it should be "allOf", but findAttributeSchema will fail
                : (metadata || {}).jsonSchema || (json.meta || {}).jsonSchema;
          if (props.constData) {
            jsonSchema = JsonApiSchema.appendSchema(
              jsonSchema,
              JsonApiSchema.makeReadOnly(props.constData) );
          }
          const res = {
            ...json.data,
            meta: {
              ...json.meta,
              ...metadata,
              jsonSchema: jsonSchema,
            },
          };
          addIncluded([res].concat(json.included || []));
          setResource(res);
          resourceDispatch(["IS_NEW", false]);
          // TODO: update in resource collection
        }
        else {
          resourceDispatch(["ERROR", t("Not found")]);
        }
      }
    }
    if (metadata) {
      if (props.id) {
        fetchResource();
      }
      else {
        const metadata1 = props.constData ? {
          ...metadata,
          jsonSchema: JsonApiSchema.appendSchema(
            metadata.jsonSchema,
            JsonApiSchema.makeReadOnly(props.constData) ),
        } : metadata;
        const metadata2 = {
          ...metadata1,
          jsonSchema: JsonApiSchema.appendSchema(
            metadata1.jsonSchema,
            metadata.creationJsonSchema ),
        };
        const resourceDefaults = JsonApiSchema.resourceFromDefaults(props.resourceType, metadata2);
        setResource(resourceDefaults);
        const relNames = Object.keys((resourceDefaults || {}).relationships || {});
        for (const relName of relNames) {
          const rel = resourceDefaults.relationships[relName].data;
          fetch(`${apiConfig.urlPrefix}/${rel.type}/${rel.id}`, apiConfig.init)
            .then(res => res.ok ? res.json() : null)
            .then(json => addIncluded([json.data]))
          // ToOneRelationship loads absent data too (see valueAsInResponseById) and data can be fetched twice
          // ToOneRelationship can load more cases, i.e. when defaults was set programmatically
          // this defaults loading is useful when relationship is readonly (ToOneRelationshipLink is displayed instead of ToOneRelationship)
          // ToOneRelationshipLink does not load data or else list view can generate too much requests
          // TODO: remove automatic data loading from ToOneRelationship, update manual defaults assigning to set included data too
        }
      }
    }
    return () => {
      ignore = true;
    };
  }, [props.id, props.resourceType, resourceVersion, metadata, props.constData,
    reconnect, t, apiConfig.urlPrefix, apiConfig.init]);


  if (!isEditing && resourceState.errorMessages.length > 0) {
    return <Message error list={resourceState.errorMessages} />
  }

  if (metadataIsLoading) {
    return <Loading />
  }

  if (metadataFetchingErrors.length) {
    return JSON.stringify(metadataFetchingErrors);
  }

  return (
    <>
      { resourceState.successMessages.length > 0 &&
        <Message positive list={resourceState.successMessages} /> }
      { resourceState.status === "FETCHING" &&
        <Loading placeholder="paragraph" rows={resource && resource.meta ? 1 : 14} /> }

      { !!resource && !!resource.meta &&
        <ResourceContext.Provider value={resource}>
        <ResourceStateContext.Provider value={resourceState}>
        <ResourceDispatchContext.Provider value={resourceDispatch}>
        <ResourceCollectionContext.Provider value={included}>
        <ResourceEditorDispatchContext.Provider value={editorDispatch}>
        <ResourceEditorContext.Provider value={editor}>

          { React.createElement(props.fullView || resourceSettings.FullView || AutoFullView
            , {...props.viewProps, key: resourceVersion}) }

        </ResourceEditorContext.Provider>
        </ResourceEditorDispatchContext.Provider>
        </ResourceCollectionContext.Provider>
        </ResourceDispatchContext.Provider>
        </ResourceStateContext.Provider>
        </ResourceContext.Provider>
      }
    </>
  );
}

function useEditingCancelListener(editor, onEditingCancel) {
  const editing = editor.editing;
  const ref = React.useRef(editing);
  React.useEffect(() => {
    if (ref.current !== editing && !editing && onEditingCancel) {
      onEditingCancel();
    }
    ref.current = editing;
  });
}

function useCreateListener(resourceState, onCreate) {
  const resource = resourceState.data;
  const id = (resource || {}).id;
  const ref = React.useRef(id);
  React.useEffect(() => {
    if (ref.current !== id && id && onCreate) {
      onCreate(resource);
    }
    ref.current = id;
  });
}

function useUpdateListener(resourceState, onUpdate) {
  const localVersion = resourceState.localVersion;
  const ref = React.useRef(localVersion);
  React.useEffect(() => {
    if (ref.current < localVersion && onUpdate) {
      onUpdate();
    }
    ref.current = localVersion;
  });
}

// TODO: Нет уверенности, что после запуска этой функции resource останется в непротиворечивом состоянии.
// ToManyRelationships могут соответствовать старому, но не новому, состоянию.
function useResourceRefetch() {
  const apiConfig = React.useContext(ApiConfigContext);
  const resource = React.useContext(ResourceContext);
  const resourceDispatch = React.useContext(ResourceDispatchContext);

  const includeResources = JsonApiSchema.getToOneRelationshipFields((resource.meta || {}).jsonSchema || {});
  const url = `${apiConfig.urlPrefix}/${resource.type}/${resource.id}`
    + (includeResources.length ? "?include=" + includeResources.join(",") : "")

  const refetch = async () => {
    const response = await fetch(url, apiConfig.init);
    const json = await response.json();
    if (json.data) {
      const resourceUpdate = res => ({
        ...res,
        attributes: {
          ...res.attributes,
          ...json.data.attributes,
        },
        relationships: {
          ...res.relationships,
          ...json.data.relationships,
        },
        meta: {
          ...res.meta,
          ...json.meta,
        },
      });
      const includedUpdate = included =>
        json.included && json.included.length ? included.concat(json.included) : included;
      resourceDispatch(["INCLUDED", includedUpdate]);
      resourceDispatch(["RESOURCE", resourceUpdate]);
    }
  }
  return refetch;
}

function resourceReducer(state, action) {
  if (Array.isArray(action)) {
    return resourceReducer(state, {type: action[0], value: action[1]});
  }
  if (action.type === "RESOURCE") {
    return {
      ...state,
      data: typeof action.value === "function" ? action.value(state.data) : action.value,
    };
  }
  if (action.type === "INCLUDED") {
    return {
      ...state,
      included: typeof action.value === "function" ? action.value(state.included) : action.value,
    };
  }
  if (action.type === "IS_NEW") {
    return {
      ...state,
      isNew: action.value,
    };
  }
  if (action.type === "STATUS") {
    return {
      ...state,
      status: action.value,
    };
  }
  if (action.type === "CLEAR_MESSAGES") {
    return {
      ...state,
      successMessages: [],
      errorMessages: [],
    };
  }
  if (action.type === "SUCCESS") {
    return {
      ...state,
      successMessages: Array.isArray(action.value) ? action.value : [action.value],
      errorMessages: [],
    };
  }
  if (action.type === "ERROR") {
    return {
      ...state,
      successMessages: [],
      errorMessages: Array.isArray(action.value) ? action.value : [action.value],
    };
  }
  if (action.type === "REFRESH") {
    const state1 = resourceReducer(state, ["CLEAR_MESSAGES"]);
    return {
      ...state1,
      localVersion: state1.localVersion + 1,
    };
  }
  throw new Error(`Unknown action type "${action.type}" in resourceReducer.`);
}

function processAjvErrors(ajvErrors, ajvLocalize, visibleFields) {
  // see src/ui321/single/Validate.js
  if (ajvLocalize) {
    ajvLocalize(ajvErrors);
  }
  let errors = {};
  for (var err of ajvErrors) {
    let fieldName = "";
    if (err.schemaPath === "#/properties/attributes/required"
      || err.schemaPath === "#/properties/relationships/required") {
      fieldName = err.params.missingProperty;
    }
    else if (err.dataPath) {
      const matches = err.dataPath.match(/^\.(attributes|relationships)\.([^\\.\\[\]]+).*$/)
        || err.dataPath.match(/^\/(attributes|relationships)\/([^\\.\\/\\[\]]+).*$/)
      if (matches) {
        fieldName = matches[2];
      }
    }
    const errKey = fieldName && (!visibleFields || visibleFields.indexOf(fieldName) !== -1)
      ? fieldName
      : NO_PROP_NAME;
    let messages = errors[errKey] || [];
    messages.push(err.message);
    errors[errKey] = messages;
    //TODO: add fieldName to message when NO_PROP_NAME
    if (errKey === NO_PROP_NAME) {
      // console.log(err);
    }
  }
  return errors;
}

const ResourceContext = React.createContext({});
const ResourceStateContext = React.createContext(defaultResourceState);
const ResourceDispatchContext = React.createContext(() => {
  console.warn("Running dispatch on no resource");
});
const ResourceCollectionContext = React.createContext([]);
const defaultEditor = {
  editing: false,
  patch: {},
  errors: {},
};
const ResourceEditorContext = React.createContext(defaultEditor);
const ResourceEditorDispatchContext = React.createContext(() => {
  console.warn("Running dispatch on no editor");
});

export {ResourceContext, ResourceStateContext, ResourceDispatchContext,
  ResourceCollectionContext,
  defaultEditor, ResourceEditorContext, ResourceEditorDispatchContext,
  useResourceRefetch};
export default SingleResource
