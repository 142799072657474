/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

function repairLinks(links, selfLink) {
  const selfLinkBase = pathBase(selfLink);
  return links && ["first", "prev", "next", "last"].reduce((links, prop) =>
    links[prop] && typeof links[prop] === "string" && selfLinkBase.endsWith(pathBase(links[prop]))
      ? {...links, [prop]: replacePath(links[prop], selfLinkBase)}
      : links
  , links);
}

function pathBase(path) {
  return ((path || "").match(/(.*)\?/) || [])[1] || "";
}

function replacePath(link, newBase) {
  const [, base, params] = (link || "").match(/(.+)\?(.*)/) || [];
  return base ? newBase + "?" + params : link;
}

export {repairLinks};
