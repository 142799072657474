/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Icon, Label} from "semantic-ui-react";

function Pagination(props) {
  const {t} = useTranslation("ui321");
  const {self, first, last, prev, next} = props.links;
  const pageNumber = self.meta.page.number || 1;
  return (
    <div className="paginator">
    { !!self.meta.page.number && props.count !== 0 &&
    <Label horizontal basic>
      {t("Page", {context: "short"})} {pageNumber}
      <>
        ,{' '}
        <span>
        {t("Number", {context: "sign"})}
        {(pageNumber - 1) * self.meta.page.size + 1}
        &ndash;
        { props.count ? (pageNumber - 1) * self.meta.page.size + props.count : <>&hellip;</> }
        </span>
      </>
    </Label>
    }
    <Button.Group>
      <Button type="button"
        icon title={t("First", {context: "page"})}
        onClick={() => props.fetch(first)}
        disabled={!first}>
        <Icon name="left double angle" />
      </Button>
      <Button type="button"
        onClick={() => props.fetch(prev)}
        disabled={!prev}>
        <Icon name="left angle" /> {t("Previous", {context: "page"})}
      </Button>
      <Button type="button"
        onClick={() => props.fetch(next)}
        disabled={!next}>
        {t("Next", {context: "page"})} <Icon name="right angle" />
      </Button>
      { last &&
      <Button type="button"
        icon title={t("Last", {context: "page"})}
        onClick={() => props.fetch(last)}
        disabled={!last}>
        <Icon name="right double angle" />
      </Button>
      }
    </Button.Group>
    </div>
  );
}

export default Pagination
