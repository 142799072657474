/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import Folder from "ui321/Folder.js"
import {ResourceSettingsContext} from "ui321/ResourceSettings.js";
import JsonApiSchema from "ui321/json/JsonApiSchema.js";
import Field from "ui321/fields/Field.js"
import {ResourceContext} from "./SingleResource.js";

function AutoFullView(props) {
  const {t} = useTranslation("ui321");
  const resource = React.useContext(ResourceContext);
  const allSettings = React.useContext(ResourceSettingsContext);
  React.useEffect(() => {
    console.warn("AutoFullView is used");
  }, []);

  if (!resource || !resource.type || !resource.meta) {
    return null;
  }

  const resourceSettings = allSettings.get(resource.type);
  const fieldNames = resourceSettings.fieldNames
    ? resourceSettings.fieldNames
      .filter(fieldName => !!JsonApiSchema.findAttributeSchema(fieldName, resource.meta.jsonSchema)
        || !!JsonApiSchema.findRelationshipSchema(fieldName, resource.meta.jsonSchema))
    : Object.keys((((resource.meta.jsonSchema || {}).properties || {}).attributes || {}).properties || {})
      .concat(Object.keys((((resource.meta.jsonSchema || {}).properties || {}).relationships || {}).properties || {}))
      .filter((v, i, a) => a.indexOf(v) === i) //distinct

  const toManyRelNames = JsonApiSchema.getToManyRelationshipNames(resource.meta.jsonSchema);

  return (
    <>
      {/* <Folder title={t("Attributes")}> */}
        {fieldNames.map(name => <Field key={name} name={name} />)}
      {/* </Folder> */}
      { toManyRelNames.map(name =>
        <Folder key={name} title={t(resource.type + ":" + name, {context: "fieldLabel"})} folded>
          <Field key={name} name={name} />
        </Folder>
      )}
    </>
  );
}

export default AutoFullView
