/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {Placeholder, Segment} from "semantic-ui-react";

function Loading(props) {
  if (props.placeholder === "paragraph") {
    return (
      <Placeholder fluid>
        <Placeholder.Paragraph>
          {count(props.rows || 7).map(i => <Placeholder.Line key={i} />)}
        </Placeholder.Paragraph>
      </Placeholder>
    );
  }
  return (
    <Segment basic loading></Segment>
  );
}

function count(n) {
  const arr = [];
  for (let i = 0; i < n; i++) {
    arr.push(i);
  }
  return arr;
}

export default Loading
