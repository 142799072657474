/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Icon} from "semantic-ui-react";

function ViewModeSwitch(props) {
  const {t} = useTranslation("ui321");
  return (
  <Button.Group icon>
    <Button type="button"
      active={props.value === ViewMode.ITEMS}
      onClick={() => props.onChange(ViewMode.ITEMS)}
      title={t("View as items")} >
      <Icon name="list layout"></Icon>
    </Button>
    <Button type="button"
      active={props.value === ViewMode.TABLE}
      onClick={() => props.onChange(ViewMode.TABLE)}
      title={t("View as table")}>
      <Icon name="table"></Icon>
    </Button>
  </Button.Group>
  );
}

class ViewMode {
  static ITEMS = "items";
  static TABLE = "table";
  static STATISTIC = "statistic";
  static Switch = ViewModeSwitch;
  // static options = [
  //   { key: "", value: "", text: "" },
  //   { key: ViewMode.ITEMS, value: ViewMode.ITEMS, text: "В виде списка" },
  //   { key: ViewMode.TABLE, value: ViewMode.TABLE, text: "В виде таблицы" },
  // ];
}

export default ViewMode