/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";

function EmptinessLabel(props) {
  const {t} = useTranslation("ui321");
  return (
    <label className="emptiness-label">
      {props.children || t("Not found")}
    </label>
  );
}

export default EmptinessLabel
