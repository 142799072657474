/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {ResourceContext} from "ui321/single/SingleResource.js";
import {Dropdown} from "semantic-ui-react";

function BoolEnum(props) {
  const resource = React.useContext(ResourceContext);
  const {t} = useTranslation(resource.type);
  const emptyOption = {key: 0, value: undefined, text: t(["Empty", "ui321:Empty_fieldValue"], {context: props.name + "_fieldValue"})};
  const trueOption = {key: 1, value: true, text: t(["Yes", "ui321:Yes"], {context: props.name + "_fieldValue"})};
  const falseOption = {key: 2, value: false, text: t(["No", "ui321:No"], {context: props.name + "_fieldValue"})};
  const options = props.order === "asc" ? [emptyOption, falseOption, trueOption] : [emptyOption, trueOption, falseOption];
  const onChange = (event, data) => {
    props.onChange(props.name, data.value);
  };
  return (
    <Dropdown selection
      value={props.editedValue !== undefined ? props.editedValue : null}
      options={options}
      onChange={onChange}
    />
  );
}

BoolEnum.componentName = "BoolEnum";

export default BoolEnum
