/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation, withTranslation} from "react-i18next";
import {Button, Divider, Form, Grid, Icon, Input, Modal, Radio, Segment, Select} from "semantic-ui-react";


function textFilterOptions(t) {
  return [
  { text: t("starts with"), value: "StartsWith" },
  { text: t("starts with (ci)"), value: "StartsWith_CI" },
  { text: "=", value: "Equals" },
  { text: t("ends with"), value: "EndsWith" },
  { text: t("contains"), value: "Contains" },
];
}

function fieldLabel(fieldName, resourceType, t) {
  return t(resourceType + ":" + fieldName, {context: "fieldLabel"});
}

/** use ui321/fields/FilterField.js instead */
function FilterChooserTrigger(props) {
  const {t} = useTranslation("ui321");
  return (
  <span className="ui dropdown" onClick={props.open}>
    { isTrueFilter(props.currentFilter) ?
    <span>{t("No filters")} <Icon name="filter" size="small"/></span> :
    <span>{t("Add filter")} <Icon name="filter" size="small"/></span> }
  </span>
  );
}

const trueCondition = ["TrueCondition"];

function isTrueCondition(f) {
  return !f || (f.length === 1 && f[0] === "TrueCondition");
}
function isTrueConditionFilterList(list) {
  return !list.length || list.every(f => isTrueCondition(f));
}
  //пусть будет trueCondition, а не trueFilter
const trueFilter = ["TrueCondition"]

const isTrueFilter = f => !f || (f.length === 1 && f[0] === "TrueCondition")

class FilterChooserT extends React.Component {
  state = {
    opened: false,
    fieldName: null,
    filterType: textFilterOptions(this.props.t)[0].value,
    textValue: "",
  }

  handleChange = (e, {name, value}) => this.setState({[name]: value })

  createFilter = () => {
    const {fieldName, filterType, textValue} = this.state;
    this.props.addFilter([filterType, fieldName, textValue]);
    this.setState({filterType: textFilterOptions(this.props.t)[0].value, fieldName: null, textValue: ""})
  }

  render() {
    const t = this.props.t;
    const {fieldName, filterType, textValue} = this.state;
    const {filterableFields, resourceType} = this.props;
    return <Modal open={this.props.opened} onClose={this.props.close}>
      <Modal.Header>{!fieldName ? t("New filter") : fieldLabel(fieldName, resourceType, t)}</Modal.Header>
      <Modal.Content>
        <Form onSubmit={this.createFilter}>
          {!fieldName && filterableFields.map(field =>
            <Form.Field key={field}>
              <Radio
                label={fieldLabel(field, resourceType, t)}
                name="fieldName"
                value={field}
                checked={fieldName === field}
                onChange={this.handleChange}
              />
            </Form.Field>
          )}
          {!!fieldName &&
            <Form.Field>
              <Input action={
                  <Select
                    options={textFilterOptions(this.props.t)}
                    defaultValue={filterType}
                    name="filterType"
                    onChange={this.handleChange} /> }
                actionPosition="left"
                name="textValue"
                onChange={this.handleChange} />
            </Form.Field>
          }
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button"
          icon floated="left"
          disabled={!fieldName} onClick={() => this.setState({fieldName: null})}>
          <Icon name="left angle" /> {t("Back")}
        </Button>
        <Button.Group>
          <Button type="button"
            icon primary
            onClick={this.createFilter}
            disabled={!textValue}>
            <Icon name="filter" /> {t("Add filter")}
          </Button>
          <Button type="button"
            onClick={this.props.close}>
            {t("Close")}
          </Button>
        </Button.Group>
      </Modal.Actions>
    </Modal>
  }
}

const FilterChooser = withTranslation("ui321")(FilterChooserT);

function FilterCondition(props) {
  const {t} = useTranslation("ui321");
  const {cond, resourceType, renderColumn} = props
  if (!cond || !cond.length) {
    return "";
  }
  const type = cond[0];
  if (type === "TrueCondition") {
    return "";
  }
  if (type === "And") {
    return <>
      <FilterCondition cond={cond[1]} resourceType={resourceType} renderColumn={renderColumn} />
      <FilterCondition cond={cond[2]} resourceType={resourceType} renderColumn={renderColumn} />
    </>
  }
  if (type === "Or") {
    return <Grid.Column width={16}>
      <Segment.Group>
        <Segment basic>
          <Grid>
            <FilterCondition cond={cond[1]} resourceType={resourceType} renderColumn={renderColumn} />
          </Grid>
        </Segment>
        <Divider horizontal>{t("Or")}</Divider>
        <Segment basic>
          <Grid>
            <FilterCondition cond={cond[2]} resourceType={resourceType} renderColumn={renderColumn} />
          </Grid>
        </Segment>
      </Segment.Group>
    </Grid.Column>
  }

  if (type === "Equals") {
    return renderColumn(
      <>
        <strong>{fieldLabel(cond[1], resourceType, t)}</strong>
        {" "}={" "}
        <q>{cond[2]}</q>
      </>
    );
  }
  if (type === "IsNull") {
    return renderColumn(
      <>
        <strong>{fieldLabel(cond[1], resourceType, t)}</strong>
        {" "}{t("is not set")}
      </>
    );
  }
  if (type === "IsNotNull") {
    return renderColumn(
      <>
        <strong>{fieldLabel(cond[1], resourceType, t)}</strong>
        {" "}{t("is set")}
      </>
    );
  }
  if (type === "StartsWith") {
    return renderColumn(
      <>
        <strong>{fieldLabel(cond[1], resourceType, t)}</strong>
        {" "}{t("starts with")}{" "}
        <q>{cond[2]}</q>
      </>
    );
  }
  if (type === "StartsWith_CI") {
    return renderColumn(
      <>
        <strong>{fieldLabel(cond[1], resourceType, t)}</strong>
        {" "}{t("starts with (ci)")}{" "}
        <q>{cond[2]}</q>
      </>
    );
  }
  if (type === "EndsWith") {
    return renderColumn(
      <>
        <strong>{fieldLabel(cond[1], resourceType, t)}</strong>
        {" "}{t("ends with")}{" "}
        <q>{cond[2]}</q>
      </>
    );
  }
  if (type === "Contains") {
    return renderColumn(
      <>
        <strong>{fieldLabel(cond[1], resourceType, t)}</strong>
        {" "}{t("contains")}{" "}
        <q>{cond[2]}</q>
      </>
    );
  }
  return renderColumn(
    <>
      <span title={JSON.stringify(cond)}>
        &hellip;
      </span>
    </>
  );
}

function filterToAndList(filter) {
  if (filter[0] === "And") {
    return [...filterToAndList(filter[1]), ...filterToAndList(filter[2])];
  }
  return [filter];
}

function andListToFilter(filterList) {
  if (filterList.length === 0) {
    return trueCondition;
  }
  if (filterList.length === 1) {
    return filterList[0];
  }
  return filterList.slice(1).reduce((filter, f) => ["And", filter, f], filterList[0]);
}

function filterAndListDistinctByColumn(filterList) {
  let filterList1 = [];
  for (let filter of filterList) {
    if (isColumnFilter(filter) && filterList1.find(f => f[1] === filter[1] && isColumnFilter(f))) {
    // TODO: and filter is not "less than", "greater than"
      // console.log("Filter is removed by filterAndListDistinctByColumn", filter);
    } else {
      filterList1.push(filter);
    }
  }
  return filterList1;
}


function filterToQueryString(filter) {
  return encodeURIComponent(JSON.stringify(filter));
}

function filterIsEmpty(f) {
  return isColumnFilter(f) && (f[2] === "" || f[2] === null || f[2] === undefined);
}

const columnFilterTypes = [
  "Equals",
  "IsNull",
  "IsNotNull",
  "StartsWith",
  "StartsWith_CI",
  "EndsWith",
  "Contains",
  "Contains_CI",
  "EqualsAny",
];

function isColumnFilter(filter) {
  return columnFilterTypes.includes(filter[0]);
}

function filterAndListsAreEquals(filterList1, filterList2) {
  const list1 = filterList1.filter(f => !isTrueCondition(f));
  const list2 = filterList2.filter(f => !isTrueCondition(f));
  return JSON.stringify(list1) === JSON.stringify(list2);
}

export {FilterChooser, FilterChooserTrigger, FilterCondition, trueFilter, isTrueFilter,
  trueCondition, isTrueCondition, isTrueConditionFilterList,
  filterToQueryString, filterIsEmpty, isColumnFilter,
  filterAndListDistinctByColumn, filterAndListsAreEquals,
  filterToAndList, andListToFilter};
