/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";

function FieldTemplate(props) {
  const className = "form-field"
    + (props.editing ? " editing" : "")
    + (props.required ? " required" : "")
    + (props.empty ? " empty-value" : "")
  return (
    <div className={className} data-field={props.name} data-type={props.type}>
      <div className="field-input">
        {props.label && <label>{props.label}</label>}
        {props.children}
      </div>
    </div>
  );
}

export default FieldTemplate
