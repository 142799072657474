/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {Header} from "semantic-ui-react";

function Folder(props) {
  const initial = props.folded !== undefined ? props.folded : false;
  const [folded, setFolded] = React.useState(initial);
  return (
    <div className={`folder ${folded ? "folded" : "unfolded"}`}>
      <Header className="folder-title clickable" onClick={() => setFolded(!folded)}>
        {props.title}
      </Header>

      { !folded &&
        <div className="folder-content">
         {props.children}
        </div> }
    </div>
  );
}

export default Folder
