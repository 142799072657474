/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import ToOneRelationship, {ToOneRelationshipLink} from "ui321/fields/ToOneRelationship.js";

function ToManyRelationshipTitles(props) {
  return (
    props.isEditing
      ? <ToOneRelationship {...props} multiple />
      : <ToManyRelationshipTitlesLinks {...props} />
  );
}

function ToManyRelationshipTitlesLinks(props) {
  return (
    <div className="field-value">
      <ul className="array-values">
        { (props.value || []).map(idObj =>
          <li key={idObj.id}>
            <ToOneRelationshipLink value={idObj} template={props.template} />
          </li>
        )}
      </ul>
    </div>
  );
}

ToManyRelationshipTitles.componentName = "ToManyRelationshipTitles";

export default ToManyRelationshipTitles
