/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {withTranslation} from "react-i18next";
import {Redirect, Route, Switch} from "react-router-dom";
import Login, {LoginModal} from "./Login.js";
import {responseErrorMessage} from "./Request.js";
import {ApiConfigContext, checkStatus_} from "./json/Request.js";

function connect(apiConfig) {
  return fetch(`${apiConfig.urlPrefix}/auth`, apiConfig.init)
    .then(checkStatus_(true))
    .then(response => response.json())
    .then(json => (json || {}).data || false)
}

/** Connection component must be inside of Router */
class Connection extends React.Component {
  static contextType = ApiConfigContext;

  state = {
    connectionError: "",

    /** auth
     * undefined - request not started
     * null - request started, no response
     * false - responded with no authenticated
     * object - responded with authenticated */
    auth: undefined,
    authBackup: undefined,
    reconnectCallbacks: [],
    loginModalOpened: false,
    close: () => this.logout(),
    reconnect: (callback, message) => this.reconnect(callback, message),
  };

  componentDidMount() {
    this.connect();
  }

  connect = () => {
    this.setState({auth: null, connectionError: ""});
    const apiConfig = this.context;
    (this.props.connect || connect)(apiConfig)
    .then(data => this.setAuth(data))
    .catch(e => this.setState({auth: false, connectionError: e.message}))
  }

  connectAgain = () => {
    const apiConfig = this.context;
    (this.props.connect || connect)(apiConfig)
    .then(data => this.setAuth(data))
    .then(() => this.runReconnectCallback())
    .catch(e => this.setState({auth: false, connectionError: e.message}))
  }

  logout = () => {
    this.setState({authBackup: null, reconnectCallbacks: []})
    const apiConfig = this.context;
    const t = this.props.t;
    fetch(`${apiConfig.urlPrefix}/auth`, {...apiConfig.init, method: 'DELETE'})
    .then(response => {
      if (!response.ok) {
        return responseErrorMessage(response, t).then(message => {
          throw new Error(message);
        })
      }
      return response;
    })
    .then(() => this.setAuth(undefined, this.connect))
    .catch(e => {
      this.setState({authBackup: this.state.auth});
      alert(e.message);
    })
  }

  setAuth = (auth, callback) => {
    if (auth) {
      this.setState({
        auth: auth,
        authBackup: auth,
        loginModalOpened: false
      }, callback || this.runReconnectCallback);
    }
    else {
      this.setState({auth: auth}, callback)
    }
  }

  reconnect = (callback, message) => {
    var reconnectCallbacks = this.state.reconnectCallbacks;
    if (callback) {
      reconnectCallbacks.push(callback);
    }
    //TODO: unsubscribe on componentWillUnmount()
    this.setState({
      auth: undefined,
      reconnectCallbacks: reconnectCallbacks,
      loginModalOpened: true,
      connectionError: message,
    })
  }

  runReconnectCallback = () => {
    for (var c of this.state.reconnectCallbacks) {
      c();
    }
    this.setState({reconnectCallbacks: []})
  }

  loginModalClose = () => this.setState({loginModalOpened: false})

  render() {
    const {auth, authBackup} = this.state;
    const t = this.props.t;
    if ((auth === undefined && !authBackup) || auth === null) {
      return this.props.loader || (
        <div className="connection ui active inverted dimmer">
          <div className="ui text loader">{t("Connecting")}</div>
        </div>
      );
    }
    return (
      <Switch>
        <Route path="/login#:url" render={ route =>
          <Login route={route}
            auth={auth} onAuth={this.setAuth} error={this.state.connectionError}
            title={this.props.loginTitle} />
        } />
        <Route path="/login" render={ route => {
          let pathname = "";
          try {
            pathname = decodeURIComponent(route.location.hash.replace(/^#/, ""));
          } catch(e) {
          }
          return (
            <Login route={{location: {state: { from: { pathname: pathname } }}}}
              auth={auth} onAuth={this.setAuth} error={this.state.connectionError}
              title={this.props.loginTitle} />
          );
        }} />
        <Route render={ route =>
          !auth && !authBackup
          ? this.props.whenFailed || <Redirect to={{
              pathname: "/login#" + encodeURIComponent(route.location.pathname),
              state: {from: route.location}
            }} />
          : <>
              { React.createElement(this.props.renderReconnect || ReconnectModal, {
                authBackup: authBackup,
                setAuth: this.setAuth,
                connectionError: this.state.connectionError,
                opened: this.state.loginModalOpened,
                close: this.loginModalClose,
                connectAgain: this.connectAgain,
                title: this.props.loginTitle,
              }) }
              { this.props.children(this.state) }
            </>
          } />
      </Switch>
    );
  }
}

function ReconnectModal(props) {
  return (
    <LoginModal
      auth={props.authBackup}
      onAuth={props.setAuth}
      error={props.connectionError}
      open={props.opened}
      onClose={props.close}
      title={props.title} />
  );
}

export const ConnectionContext = React.createContext({
  connectionError: "",
  auth: undefined,
  authBackup: undefined,
  reconnectCallbacks: [],
  loginModalOpened: false,
  close: () => {},
  reconnect: () => console.warn("void reconnect function"),
});

export {connect};
export default withTranslation("ui321")(Connection)
