import ACLRoles from "./resources/ACLRoles/ACLRoles.js";
import ACLRolesUsers from "./resources/ACLRolesUsers/ACLRolesUsers.js";
import Calls from "./resources/Calls/Calls.js";
import ExtendedOrders from "./resources/ExtendedOrders/ExtendedOrders.js";
import Leads from "./resources/Leads/Leads.js";
import Orders from "./resources/Orders/Orders.js";
import SecurityGroups from "./resources/SecurityGroups/SecurityGroups.js";
import SecurityGroupsRecords, {SecurityGroups_Modules} from "./resources/SecurityGroupsRecords/SecurityGroupsRecords.js";
import Users from "./resources/Users/Users.js";

const AppResources = {
  [ACLRoles.resourceType]: ACLRoles,
  [ACLRolesUsers.resourceType]: ACLRolesUsers,
  [Calls.resourceType]: Calls,
  [ExtendedOrders.resourceType]: ExtendedOrders,
  [Leads.resourceType]: Leads,
  [Orders.resourceType]: Orders,
  [SecurityGroups.resourceType]: SecurityGroups,
  [SecurityGroupsRecords.resourceType]: SecurityGroupsRecords,
  ...SecurityGroups_Modules,
  [Users.resourceType]: Users,
}

export default AppResources
