/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useRequest} from "ui321/Request.js";
import {ResourceCreationContext, ResourceCreationDispatchContext} from "ui321/single/ResourceCreation.js";

function FetchCreationAccess(props) {
  const creationState = React.useContext(ResourceCreationContext);
  const url = `/${creationState.resourceType}/creation_access`;
  const {response, error} = useRequest(url);
  const dispatch = React.useContext(ResourceCreationDispatchContext);
  React.useEffect(() => {
    if (response && response.meta) {
      dispatch(["CREATION_ACCESS", response.meta.creationAccess]);
    }
  }, [response, dispatch]);
  React.useEffect(() => {
    if (error) {
      console.warn("Ошибка получения прав на создание", error);
    }
    if (response && (!response.meta || typeof response.meta.creationAccess !== "boolean")) {
      console.warn("Ошибка получения прав на создание в ответе", response);
    }
  }, [error, response]);
  return null;
}

export default FetchCreationAccess
