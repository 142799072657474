/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Icon, Menu, Message, Popup} from "semantic-ui-react";
import EmptinessLabel from "ui321/EmptinessLabel.js";
import {repairLinks} from "ui321/JsonApiSpec.js";
import {useAuxFrameToggler} from "ui321/PageLayout.js";
import AutoFullView from "ui321/single/AutoFullView.js";
import {updateResourcePatch, updatedCollection} from "ui321/single/Edit.js";
import {useMetadata} from "ui321/ResourceMetadata.js";
// import {ResourceSettingsContext} from "ui321/ResourceSettings.js";
import Loading from "ui321/Loading.js";
import {ResourceContext, ResourceDispatchContext, ResourceStateContext, ResourceCollectionContext,
  ResourceEditorContext, ResourceEditorDispatchContext} from "ui321/single/SingleResource.js";
import JsonApiSchema from "ui321/json/JsonApiSchema.js";
import {ApiConfigContext} from "ui321/json/Request.js";
import {ConnectionContext} from "ui321/Connection.js";
import {err401, responseErrorMessage} from "ui321/Request.js";

const emptyCollection = [];

function ToManyRelationshipAll(props) {
  const {t} = useTranslation("ui321");
  const apiConfig = React.useContext(ApiConfigContext);
  const connection = React.useContext(ConnectionContext);
  const reconnect = connection.reconnect;
  const resource = React.useContext(ResourceContext);
  const resourceDispatch = React.useContext(ResourceDispatchContext);
  const resourceState = React.useContext(ResourceStateContext);
  const contextIncluded = React.useContext(ResourceCollectionContext);
  const [collection, setCollection] = React.useState(null);
  const [errors, setErrors] = React.useState(null);
    // TODO: store collection in resource?
  const [linkNext, setLinkNext] = React.useState(undefined);
  const [pageToLoad, setPageToLoad] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [included, setIncluded] = React.useState(contextIncluded);
  const relName = props.name;
  const relSchema = JsonApiSchema.findRelationshipSchema(relName, resource.meta.jsonSchema);
  const relatedResourceTypes = relSchema ? JsonApiSchema.getRelationshipResourceType(relSchema) : null;
  const relatedResourceType = Array.isArray(relatedResourceTypes) ? relatedResourceTypes[0] : relatedResourceTypes;
  // const allSettings = React.useContext(ResourceSettingsContext);
  // const resourceSettings = allSettings.get(resource.type);
  const [relMetadata, metadataIsLoading, metadataFetchingErrors] = useMetadata(relatedResourceType);
  const editorDispatchFromContext = React.useContext(ResourceEditorDispatchContext);
  const isEditing = props.isEditing && !linkNext;
  // const relSchema = findRelationshipSchema(relName, resource.meta.jsonSchema);
  // const relatedResourceType = relSchema ? getRelationshipResourceType(relSchema) : null;
  // const toResourceType = typeof relatedResourceType === "object" ? relatedResourceType[0] : relatedResourceType;

  // const reverseRelationship = (resourceSettings.reverseRelationships || {})[relName];
  // const reverseRelationshipData = reverseRelationship ? {
  //     [reverseRelationship]: {
  //       data: {
  //         type: resource.type,
  //         id: resource.id,
  //       }
  //     }
  //   } : null;

  // TODO: validate relationship to parent is not set or is not changed

  // clear state when resource changed
  // TODO: store collection/links.next in resource so no such hack required
  const resourceId = (resource || {}).id;
  React.useEffect(() => {
    if (relMetadata) {
      if (resourceId) {
        setCollection(null);
        setLinkNext(undefined);
        setPageToLoad(1);
      }
    }
  }, [resourceId, relName, relMetadata /*, included, props.sorting, apiConfig.urlPrefix */]);

  React.useEffect(() => {
    // let ignore = false;
    async function fetchCollection() {
      let url = null;
      if (pageToLoad === 1) {
        const include =
          (Object.keys((((relMetadata.jsonSchema.properties || {}).relationships || {}).properties || {})))
            .filter(field => {
              const relSchema = JsonApiSchema.findRelationshipSchema(field, relMetadata.jsonSchema);
              // const relatedResourceType = getRelationshipResourceType(relSchema);
              // const relatedResourceType1 = typeof relatedResourceType === "object" ? relatedResourceType[0] : relatedResourceType;
              return relSchema.type !== "array"
                // && !!this.props.allSettings.get(relatedResourceType1).titleField
                // && this.props.fieldNames.indexOf(field) !== -1
            });
        const filtering = null;
        const sorting = props.sorting;
        url = `${apiConfig.urlPrefix}/${resource.type}/${resource.id}/${relName}`
          + (filtering || sorting || include ? "?" : "")
          + (filtering ? "filter=" + encodeURIComponent(JSON.stringify(filtering)) : "")
          + (sorting ? "&sort=" + (
              typeof sorting === "string" ? sorting : sorting.map(s => s[1] === "desc" ? "-" + s[0] : s[0]).join(",")
          ) : "")
          + (include ? "&include=" + include.join(",") : "")
      }
      else {
        url = pageToLoad;
      }
      setIsLoading(true);
      const response = await fetch(url, apiConfig.init);
      setIsLoading(false);
      setPageToLoad(null);
      // if (!ignore) { //при редактировании посылается два запроса fetchCollection (меняется resource) и ignore ломает поле
        if (!response.ok) {
          const message = await responseErrorMessage(response, t);
          setErrors([message]);
          if (message === err401) {
            reconnect(() => {
              setErrors([]);
              fetchCollection();
            }, t("401", {context: "statusCode"}));
          }
          return;
        }
        const json = await response.json();
        const cs = json.data.map(res => ({...res, meta: relMetadata}));
        const cs2 = (collection || []).concat(cs);
        setIncluded(cs2.concat(json.included || []).concat(included || []).concat([resource]));
        setCollection(cs2);
        setLinkNext((repairLinks(json.links, url) || {}).next || null);
      // }
    }
    if (resource && relMetadata) {
      if (resource.id) {
        if (pageToLoad) {
          fetchCollection();
        }
      }
      else {
        setCollection(emptyCollection);
      }
    }
    // return () => {
    //   ignore = true;
    // };
  }, [resource, relName, relMetadata, pageToLoad, collection, included, props.sorting, reconnect, t, apiConfig.urlPrefix, apiConfig.init]);

  const collectionWithIncluded = React.useMemo(() =>
    collection && collection.map(res => ({
      ...res,
      relationships: res.relationships && Object.keys(res.relationships).reduce((rels, relName) => ({
        ...rels,
        [relName]: {
          ...res.relationships[relName],
          data: res.relationships[relName].data && (
            JsonApiSchema.findResource(res.relationships[relName].data, included)
            || res.relationships[relName].data
          ),
        }
      }), {}),
    }))
  , [collection, included]);

  React.useEffect(() => {
    if (resource && collectionWithIncluded && linkNext === null
      && !((resource || {}).relationships || {})[relName])
    {
      resourceDispatch({type: "RESOURCE", value: {
        ...resource,
        relationships: {
          ...resource.relationships,
          [relName]: {
            data: collectionWithIncluded,
          },
        },
      }});
    }
  }, [collectionWithIncluded, linkNext, resource, relName, resourceDispatch]);

  const localResourceDispatch = React.useMemo(() => {
    return a => {
      const action = Array.isArray(a) ? {type: a[0], value: a[1]} : a;
      if (action.type === "INCLUDED") {
        setIncluded(typeof action.value === "function" ? action.value : inc => [...inc, action.value]);
      }
      resourceDispatch(action);
    }
  }, [resourceDispatch]);
  const collectionEditorDispatch = React.useMemo(() => {
    return action => {
      switch (action.type) {
        case "CHANGE": {
          const {key, value} = action;
          return editorDispatchFromContext({type: "CHANGE_COLLECTION", fieldName: relName, key: key, value: value, collection: collection});
        }
        case "REMOVE": {
          const {key} = action;
          return editorDispatchFromContext({type: "CHANGE_COLLECTION", fieldName: relName, key: key, value: null, collection: collection});
        }
        default: {
          return editorDispatchFromContext(action);
        }
      }
    }
  }, [editorDispatchFromContext, collection, relName]);

  const editedData = (props.editedValue || {}).data;
  React.useEffect(() => {
    if (props.toBeDeletedField && collection && collection.length && editedData) {
      const removed = collection.filter(res => res.id && !JsonApiSchema.findResource(res, editedData));
      editorDispatchFromContext({type: "CHANGE", fieldName: props.toBeDeletedField, value: removed});
    }
  }, [editedData, collection, editorDispatchFromContext, props.toBeDeletedField]);

  const templateObject = React.useMemo(() =>
    ({type: relatedResourceType, meta: relMetadata}), [relatedResourceType, relMetadata]);

  const stateFieldErrors = (resourceState.errorMessages || [])
    .filter(err => typeof err === "object"
      && err.fieldName === relName
      && !!err.message)
    .map(err => err.message)
    .map((msg, i) => msg + " ".repeat(i)) //attempt to make messages unique, because MessageList uses messages itself as keys

  if (!resource) {
    return null;
  }
  if (metadataIsLoading) {
    return null;
  }
  if (metadataFetchingErrors.length) {
    return (
      <Message error content={metadataFetchingErrors.join("; ")} />
    );
  }
  if (errors && errors.length) {
    return (
      <Message error content={errors.join("; ")} />
    );
  }
  if (collection === null) {
    return <Loading placeholder="paragraph" />;
  }

  let collection1 = collection;
  let templateIndex = -1;
  if (isEditing) {
    const collectionPatch = (props.editedValue || {}).data;
    collection1 = [...updatedCollection(collection, collectionPatch, templateObject)];
    collection1.push(templateObject);
    templateIndex = collection1.length - 1;
  }
  const removed = props.toBeDeletedTemplate
    && collection && collection.length
    && props.editedValue && props.editedValue.data
    ? collection.filter(res => res.id && !JsonApiSchema.findResource(res, props.editedValue.data))
    : [];
  return (
    <ResourceDispatchContext.Provider value={localResourceDispatch}>
    <ResourceCollectionContext.Provider value={included}>
    <ResourceEditorDispatchContext.Provider value={collectionEditorDispatch}>
    <div className="to-many-relationship-all">
      { stateFieldErrors.length > 0 &&
        <Message error list={stateFieldErrors} /> }
      { collection1.map((res, idx) => {
        const isTemplate = idx === templateIndex;
        const patch = ((props.editedValue || {}).data || [])[idx];
        return (
          <MemoNestedResource key={idx}
            isEditing={isEditing}
            isTemplate={isTemplate}
            resource={res}
            resourcePatch={patch}
            collectionIndex={idx}
            parentField={props.parentField}
            parentResource={resource}
            template={props.template}
            disableRemoving={props.disableRemoving}
          />
        );
      })}
      { !!props.toBeDeletedTemplate &&
        <ToBeDeletedList template={props.toBeDeletedTemplate} items={removed} /> }
      { !collection1.length &&
        <EmptinessLabel>{props.emptinessLabel}</EmptinessLabel> }
      { !!linkNext && props.isEditing &&
        <Message info content={t("You should load all data")} /> }
      { linkNext &&
        <Button type="button"
          size="tiny"
          content={t("Load more")}
          onClick={() => setPageToLoad(linkNext)}
          loading={isLoading}
          disabled={isLoading}
        /> }
    </div>
    </ResourceEditorDispatchContext.Provider>
    </ResourceCollectionContext.Provider>
    </ResourceDispatchContext.Provider>
  );
}

ToManyRelationshipAll.componentName = "ToManyRelationshipAll";

const MemoNestedResource = React.memo(props => <NestedResource {...props} />);

function NestedResource(props) {
  const {isEditing, isTemplate, resource, resourcePatch, collectionIndex, template} = props;
  const emptyPatch = {type: resource.type};
  // const editor = {
  //   patch: resourcePatch || emptyPatch,
  //   editing: isEditing,
  // };
  const editor = React.useMemo(() => ({
    patch: resourcePatch || emptyPatch,
    editing: isEditing,
  }), [resourcePatch, emptyPatch, isEditing]);
  return (
    <div className={"nested-resource"
        + ` ${isEditing ? "editing" : "reading"}`
        + `${isTemplate ? " nested-resource-template" : ""}`}>
      <ResourceEditorContext.Provider value={editor}>
        <NestedResourceControl
          resource={resource}
          isEditing={isEditing}
          isTemplate={isTemplate}
          collectionIndex={collectionIndex}
          disableRemoving={props.disableRemoving}
        />
        <NestedResourceContent resource={resource} collectionIndex={collectionIndex}
          parentField={props.parentField} parentResource={props.parentResource}
          >
          {template ? React.createElement(template) : <AutoFullView />}
        </NestedResourceContent>
      </ResourceEditorContext.Provider>
    </div>
  );
}

function NestedResourceContent(props) {
  const resource = props.resource;
  const editor = React.useContext(ResourceEditorContext);
  const editorDispatchFromContext = React.useContext(ResourceEditorDispatchContext);

  function editorDispatch(action) {
    switch (action.type) {
      case "CHANGE": {
        const {fieldName/* , inputErrors */} = action;
        const fieldValue = typeof action.value === "function" ? action.value(editor.patch) : action.value;
        const resourcePatch = updateResourcePatch(resource, editor.patch, fieldName, fieldValue);
        return editorDispatchFromContext({type: "CHANGE", key: props.collectionIndex, value: resourcePatch});
      }
      default: {
        return editorDispatchFromContext(action);
      }
    }
  }

  const resource1 = React.useMemo(() =>
    props.parentField
      ? {...resource, relationships: {...resource.relationships, [props.parentField]: props.parentResource}}
      : resource
  , [resource, props.parentField, props.parentResource]);

  return (
    <div className="nested-resource-content">
      <ResourceContext.Provider value={resource1}>
      <ResourceEditorDispatchContext.Provider value={editorDispatch}>
        {props.children}
      </ResourceEditorDispatchContext.Provider>
      </ResourceContext.Provider>
    </div>
  );
}

function NestedResourceControl(props) {
  const isEditing = props.isEditing;
  const resource = props.resource;
  const isTemplate = props.isTemplate;
  const collectionIndex = props.collectionIndex;
  const elemProps = {
    className: "nested-resource-control",
  };
  if (isEditing) {
    return (
      <div {...elemProps}>
        <NestedResourcePopup
          resource={resource}
          isTemplate={isTemplate}
          collectionIndex={collectionIndex}
          disableRemoving={props.disableRemoving}
        />
      </div>
    );
  }
  return (
    <div {...elemProps}>
      <NestedResourceSelection
        resource={resource}
        collectionIndex={collectionIndex}
      />
    </div>
  );
}

function NestedResourceSelection(props) {
  const {t} = useTranslation("ui321");
  const resource = props.resource;
  const [isOpened, toggleAuxFrame] = useAuxFrameToggler(`/${resource.type}/${resource.id}`);
  const collectionIndex = props.collectionIndex;
  return (
    <Button type="button"
      basic
      className="nested-resource-button"
      title={t("Select")}
      active={isOpened}
      onClick={toggleAuxFrame}>
      {collectionIndex + 1}
    </Button>
  );
}

function NestedResourcePopup(props) {
  const {t} = useTranslation("ui321");
  const editorDispatch = React.useContext(ResourceEditorDispatchContext);
  const [popupState, setPopupState] = React.useState(false);
  const resource = props.resource;
  const isTemplate = props.isTemplate;
  const collectionIndex = props.collectionIndex;
  const className = "nested-resource-button";
  if (isTemplate) {
    return (
      <Button type="button" icon="plus"
        basic
        className={className}
        onClick={() => {editorDispatch({type: "CHANGE", key: collectionIndex, value: resource})}}
      />
    );
  }
  return (
    <div className="quick-menu nested-resource-buttons">
    <Popup className="menu-popup"
      position="bottom left"
      on="click"
      open={popupState}
      onClose={() => setPopupState(false)}
      onOpen={() => setPopupState(true)}
      trigger={
        <Button type="button" className={className} basic icon>
          {collectionIndex + 1}
        </Button>
      }
      content={
        <Menu vertical>
          <Menu.Item disabled={props.disableRemoving && !!resource.id}
            onClick={() => {
              editorDispatch({type: "REMOVE", key: collectionIndex});
              setPopupState(false);
            }}>
            <Icon name="trash" /> {t("To delete")}
          </Menu.Item>
        </Menu>
      }
    />
    <Button type="button" icon
      disabled={props.disableRemoving && !!resource.id}
      className="quick-menu-item delete-btn"
      title={t("To delete")}
      onClick={() => {
        editorDispatch({type: "REMOVE", key: collectionIndex});
        setPopupState(false);
      }}>
      <Icon name="trash" />
    </Button>
    </div>
  );
}

function ToBeDeletedList(props) {
  if (!props.items || !props.items.length) {
    return null;
  }
  return (
    <div className={props.className + " to-be-deleted-list"}>
      { props.items.map(data =>
        React.createElement(props.template, {key: data.id, data: data})
      )}
    </div>
  );
}

export {ToBeDeletedList};
export default ToManyRelationshipAll
