/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {ApiConfigContext} from "./json/Request.js";

function ResourceTypeList(props) {
  const apiConfig = React.useContext(ApiConfigContext);
  const [resources, setResources] = React.useState([]);
  React.useEffect(() => {
    let destroyed = false;
    async function fetchResources() {
      const url = `${apiConfig.urlPrefix}/list-resources`;
      const response = await fetch(url, apiConfig.init);
      const json = await response.json();
      if (!destroyed) {
        if (json.data) {
          setResources(json.data);
        }
        // else {
        //   setErrors(["Запись не найдена"]);
        // }
      }
    }
    fetchResources();
    return () => {
      destroyed = true;
    };
  }, [apiConfig.urlPrefix, apiConfig.init]);

  return props.children(resources);
}

// const ResourcesList = props =>
//   <ConnectionContext.Consumer>
//     { connection => <ConnectionResourcesList {...props} reconnect={connection.reconnect} /> }
//   </ConnectionContext.Consumer>

// class ConnectionResourcesList extends React.Component {
//   state = {
//     response: null,
//     error: null,
//   };

//   componentDidMount() {
//     this.makeRequest();
//   }

//   makeRequest = () => {
//     this.setState({response: null});
//     q321.listResources()
//       .then(response => this.setState({response: response}))
//       .catch(e => {
//         if (e.name === "Error403") {
//           this.props.reconnect(this.makeRequest, e.message)
//         } else {
//           this.setState({error: e.message, response: false})
//         }
//       });
//   }

//   render() {
//     // if (this.state.error) {
//     //   return ""; //<Message error content={this.state.error} />;
//     // }
//     // if (this.state.response === null) {
//     //   return ""; //<SegmentLoading />;
//     // }
//     // return this.props.children(this.state.response.data);
//     return this.props.children(this.state.response !== null ? this.state.response.data : this.state.response);
//   }
// }

export default ResourceTypeList
