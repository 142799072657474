/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {Link} from "react-router-dom";
import {Translation, useTranslation} from "react-i18next";
import {Button, Icon, Grid, Menu, Segment, Sidebar} from "semantic-ui-react";
import "./PageLayout.css";

const sidebarVisibility = sidebarVisible =>
  sidebarVisible === undefined ? "default-visibility" : (sidebarVisible ? "visible" : "hidden");

function MenuButton(props) {
  const {t} = useTranslation("ui321");
  return (
    <Button type="button"
      basic
      title={t("Toggle side menu")}
      onClick={event => {event.preventDefault(); props.layout.toggleSidebar()}}
      className={sidebarVisibility(props.layout.sidebarVisible) + " ui item toggle-sidebar"}>
      <Icon name="bars" fitted />
    </Button>
  );
}

class AuxFrame {
  static RIGHT = "right";
  static BOTTOM = "bottom";

  constructor(path) {
    this.path = path;
  }
}

class PageLayout extends React.Component {
  setAuxFrame = path => this.setState({
    auxFrame: path ? new AuxFrame(path) : null,
  }, this.scrollToAuxFrame);

  toggleSidebar = show =>
    this.setState({sidebarVisible: show !== undefined ? show : !this.state.sidebarVisible})

  MenuButton = () => <MenuButton layout={this.state} />

  columnsCount = () =>
    this.state.auxFrame && this.state.auxFramePosition === AuxFrame.RIGHT ? 2 : 1;

  state = {
    sidebarVisible: undefined,
    toggleSidebar: this.toggleSidebar,
    MenuButton: this.MenuButton,

    auxFrame: null,
    auxFramePosition: AuxFrame.RIGHT,
    setAuxFrame: this.setAuxFrame,
    columnsCount: this.columnsCount,
  };

  componentDidUpdate(prevProps) {
    if (this.props.pagePath !== prevProps.pagePath) {
      this.setState({
          auxFrame: null,
      });
    }
  }

  handleAuxFrameClose = event => {
    event.preventDefault();
    this.setState({auxFrame: null});
  }

  handleAuxFrameLayout = event => {
    event.preventDefault();
    this.setState(state => ({
      auxFramePosition: state.auxFramePosition === AuxFrame.RIGHT ? AuxFrame.BOTTOM : AuxFrame.RIGHT,
    }), this.scrollToAuxFrame);
  }

  scrollToAuxFrame = () => {
    if (this.state.auxFrame) {
      const elem = document.querySelector(".first-aux-frame"); //TODO: ref
      const elemTop = window.scrollY + elem.getBoundingClientRect().top;
      if (elemTop > window.scrollY + window.innerHeight * 0.9 || elemTop < window.scrollY) {
        window.scroll({top: elemTop, behavior: "smooth"})
      }
    }
  }

  render() {
    const props = this.props;
    return <>
      { props.sideMenu &&
      <Menu vertical inverted size="large"
        className={sidebarVisibility(this.state.sidebarVisible) + " left sidebar"}>
        { props.sideMenu }
      </Menu>
      }
      <Sidebar.Pusher>
        <Segment basic>
          <PageLayoutContext.Provider value={this.state}>

            {!!this.props.header
              && this.props.header(this.state)}

            <Grid divided padded
              className={
                (this.state.auxFrame ?
                  "with-aux-frame"
                  + (this.state.auxFramePosition === AuxFrame.RIGHT ?
                    " with-right-aux-frame" : "")
                  + (this.state.auxFramePosition === AuxFrame.BOTTOM ?
                    " with-bottom-aux-frame" : "")
                : "")
              }>

              <Grid.Column className="main-frame"
                tablet={16}
                computer={this.columnsCount() === 2 ? 8 : 16}
                >
                {React.createElement(props.content)}
              </Grid.Column>

              {!!this.state.auxFrame &&
                <Grid.Column className="first-aux-frame"
                  tablet={16}
                  computer={this.columnsCount() === 2 ? 8 : 16}
                  >
                  <Translation ns="ui321">{ t =>
                  <Button.Group floated="right">
                    <Button type="button"
                      basic icon
                      title={t("Aux frame layout")}
                      onClick={this.handleAuxFrameLayout}
                      >
                      <Icon name="columns" />
                    </Button>
                    <Button type="button"
                      basic icon
                      title={t("Close aux frame")}
                      onClick={this.handleAuxFrameClose}
                      >
                      <Icon name="x" />
                    </Button>
                  </Button.Group>
                  }</Translation>

                  { React.createElement(props.content, {
                    location: {pathname: this.state.auxFrame.path},
                  })}

                </Grid.Column>
              }
            </Grid>

          </PageLayoutContext.Provider>
        </Segment>
      </Sidebar.Pusher>
    </>
  }
}

function LinkToAuxFrame(props) {
  const pageLayout = React.useContext(PageLayoutContext);
  return (
    <Link to={props.to}
      onClick={event => {
        event.preventDefault();
        pageLayout.setAuxFrame(props.to);
      }}
    >
      {props.children}
    </Link>
  );
}

function useAuxFrameToggler(path) {
  const pageLayout = React.useContext(PageLayoutContext);
  const isOpened = !!pageLayout.auxFrame && pageLayout.auxFrame.path === path;
  const toggleAuxFrame = show => {
    const show1 = typeof show === "boolean" ? show : !isOpened;
    pageLayout.setAuxFrame(show1 ? path : null);
  };
  return [isOpened, toggleAuxFrame];
}

const PageLayoutContext = React.createContext({
  auxFrame: null,
  auxFramePosition: AuxFrame.RIGHT,
  setAuxFrame: () => {},
  columnsCount: () => 1,
});

export {PageLayoutContext, LinkToAuxFrame, useAuxFrameToggler};
export default PageLayout
