/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import JsonApiSchema from "ui321/json/JsonApiSchema.js";
import FilterField from "ui321/fields/FilterField.js"
import {FormDataArrayItem, FormDataContext} from "ui321/form/FormData.js";
import {FormDropdown} from "ui321/form/Form.js";
import {filterIsEmpty, isColumnFilter} from "ui321/nav/Filtering.js";
import FieldTemplate from "ui321/form/FieldTemplate.js";
import AuditMetadata from "./AuditMetadata.json";

function AuditFilter(props) {
  const {t} = useTranslation("Audit");
  const filter = React.useContext(FormDataContext);
  const fieldName = isColumnFilter(filter) ? filter[1] : null;
  const labelText = fieldName && t(fieldName, {context: "fieldLabel"});
  const isEmpty = filterIsEmpty(filter);
  let component = null;
  if (filter[0] === "Equals" && filter[1] === "field_name") {
    component = (
      <FieldNameEnumFilter
        name={fieldName}
        attrSchema={{
          "type": "string",
          "enum": props.auditedFields,
        }}
        resourceType={props.resourceType}
      />
    );
  }
  return component ?
    <FieldTemplate name={fieldName} label={labelText} editing empty={isEmpty}>
      <FormDataArrayItem index={2}>
        {component}
      </FormDataArrayItem>
    </FieldTemplate>
    :
    <FilterField resourceType="Audit" jsonSchema={AuditMetadata.jsonSchema} />
}

function FieldNameEnumFilter(props) {
  const resourceType = props.resourceType;
  const {t} = useTranslation(resourceType);
  const fieldName = props.name;
  const itemProps = (value, content) => (
    {key: value, value: value, text: content, content: content}
  );
  const itemContent = value => (
    <span data-value={value}>
      {t(value, {context: "fieldLabel"})}
    </span>
  );
  const values = ((props.attrSchema || {}).enum || (JsonApiSchema.fromNullable(props.attrSchema) || {}).enum || [])
    .map(v => `${v}`)
  return (
    <FormDropdown fluid selection
      name={fieldName}
      options={[
        itemProps(null, ""),
        ...values.map(val => itemProps(val, itemContent(val))),
      ]}
    />
  );
}


export default AuditFilter
