/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {Translation, useTranslation} from "react-i18next";
import {Breadcrumb, Button, Form, Icon, Modal} from "semantic-ui-react";
import ResourceTypeLabel from "./ResourceTypeLabel.js";
import ViewMode from "./ViewMode.js";

const settingsAreEmpty = settings => !Object.keys(settings).filter(k => settings[k] !== undefined).length
const normalizeSettings = settings => Object.keys(settings).filter(k => settings[k] !== undefined)
  .reduce((st, k) => ({ ...st, [k]: settings[k] }), {})
const resourceSettingsKey = resourceType => `${resourceType}-settings`

const localSettings = {
  default: {},
  get: resourceType => JSON.parse(localStorage.getItem(resourceSettingsKey(resourceType))) || {},
  set: (resourceType, settings) => {
    const settingsKey = resourceSettingsKey(resourceType);
    if (settingsAreEmpty(settings)) {
      localStorage.removeItem(settingsKey)
    }
    else {
      localStorage.setItem(settingsKey, JSON.stringify(settings))
    }
  },
};

class ResourceSettings extends React.Component {
  state = {
    default: this.props.appSettings,
    get: resourceType => {
      if (this.settingsCache[resourceType] === undefined) {
        this.settingsCache[resourceType] = localSettings.get(resourceType);
      }
      return {
        ...(this.props.appSettings[resourceType] || {}),
        ...(this.settingsCache[resourceType])
      }
    },
    set: (resourceType, settings) => {
      localSettings.set(resourceType, settings);
      this.settingsCache[resourceType] = settingsAreEmpty(settings) ? undefined : normalizeSettings(settings);
      this.forceUpdate();
    },
  };

  settingsCache = {};

  render() {
    return this.props.children(this.state);
  }
}

class ResourceSettingsForm extends React.Component {
  state = JSON.parse(localStorage.getItem(resourceSettingsKey(this.props.resourceType))) || {}

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.props.settings.set(this.props.resourceType, this.state);
    }
  }

  handleChange = (event, { name, value }) => this.setState({ [name]: value === "" ? undefined : value })

  isEmpty = () => settingsAreEmpty(this.state)

  reset = () => this.setState(Object.keys(this.state).reduce((st, k) => ({ ...st, [k]: undefined }), {}))

  render() {
    const props = this.props;
    return <Translation ns="ui321">{ t =>
    <Form>
      <Button type="button" negative floated="right" size="mini" compact
        disabled={this.isEmpty()}
        onClick={() => this.reset()}>
        <Icon name="trash alternate" /> {t("Reset settings")}
      </Button>
      <Form.Input
        label={t("Resource name")}
        name="resourceLabel"
        value={this.state.resourceLabel || ""}
        placeholder={props.defaultSettings.resourceLabel || ""}
        onChange={this.handleChange}
      />
      <Form.Select
        label={t("Collection view mode")}
        name="collection_view_mode"
        value={this.state.collection_view_mode}
        placeholder={props.defaultSettings.collection_view_mode || ""}
        // options={ViewMode.options}
        options={[
          { key: "", value: "", text: "" },
          { key: ViewMode.ITEMS, value: ViewMode.ITEMS, text: t("View as items") },
          { key: ViewMode.TABLE, value: ViewMode.TABLE, text: t("View as table") },
        ]}
        onChange={this.handleChange}
      />
    </Form>
    }</Translation>
  }
}

function ResourceSettingsModal(props) {
  const {t} = useTranslation("ui321");
  return (
  <Modal closeIcon
    trigger={props.trigger || <Button icon type="button"><Icon name="cog" /></Button>}>
    <Modal.Header>
      <Breadcrumb>
        <Breadcrumb.Section>
          {t("Settings")}
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section active>
          <ResourceTypeLabel resourceType={props.resourceType} />
        </Breadcrumb.Section>
      </Breadcrumb>
    </Modal.Header>
    <Modal.Content>
      <ResourceSettingsContext.Consumer>
        { settings =>
        <ResourceSettingsForm resourceType={props.resourceType}
          defaultSettings={settings.default[props.resourceType] || {}}
          settings={settings}
        /> }
      </ResourceSettingsContext.Consumer>
    </Modal.Content>
  </Modal>
  );
}

function useResourceSettings(resourceType) {
  const allSettings = React.useContext(ResourceSettingsContext);
  if (!resourceType) {
    console.warn("useResourceSettings with no resource type");
  }
  return allSettings.get(resourceType);
}

const ResourceSettingsContext = React.createContext(localSettings);

export {ResourceSettingsContext, ResourceSettingsModal, useResourceSettings};
export default ResourceSettings
