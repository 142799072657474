import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Checkbox, Dropdown, Grid, Message, Modal, Tab, Table} from "semantic-ui-react";
import Loading from "ui321/Loading.js";
import {useRequest} from "ui321/Request.js";
import {useFieldValue} from "ui321/fields/Field.js";
import {ResourceContext, ResourceEditorContext, ResourceEditorDispatchContext} from "ui321/single/SingleResource.js";
import "./StateSchema.css";

export function fieldStateSchema(stateSchemas, fieldName) {
  for (const schema of stateSchemas) {
    if (!fieldName || schema.field === fieldName) {
      return schema;
    }
  }
  return null;
}

export function transitionEnum(schema, valueBefore) {
  if (valueBefore === undefined) {
    return schema.startStates;
  }
  const options = [];
  options.push(valueBefore);
  for (const transition of schema.transitions) {
    if (transition.state1 === valueBefore && !transition.event.length && options.indexOf(transition.state2) === -1) {
      options.push(transition.state2);
    }
  }
  return options;
}

export function StateFieldButton({fieldName}) {
  const [selectedSchema, setSelectedSchema] = React.useState(null);
  const resource = React.useContext(ResourceContext);
  const stateSchemas = (((resource || {}).meta || {}).stateSchemas || []).filter(m => m.field === fieldName);
  return (
    <>
      { stateSchemas.map((schema, i) =>
        <Button type="button" key={i}
          icon="sitemap"
          className="state-field-button"
          basic
          onClick={() => setSelectedSchema(schema)}
        />
      )}
      { !!stateSchemas.length &&
      <Modal
        closeIcon
        open={!!selectedSchema}
        onClose={() => setSelectedSchema(null)}
        size="fullscreen"
        centered={false}
      >
        <Modal.Content>
          <StateSchema
            schema={selectedSchema}
            onChooseFieldValue={() => setSelectedSchema(null)}
          />
        </Modal.Content>
      </Modal>
      }
    </>
  );
}

export function StateSchema({schema, onChooseFieldValue}) {
  const resource = React.useContext(ResourceContext);
  const stateBefore = ((resource || {}).attributes || {})[schema.field] || null;
  const [selectedState1, setSelectedState1] = React.useState(stateBefore);
  const editor = React.useContext(ResourceEditorContext);
  const editorDispatch = React.useContext(ResourceEditorDispatchContext);
  const isEditing = editor.editing;
  const fieldValue = useFieldValue(schema.field);
  const [eventsVisible, setEventsVisible] = React.useState(false);
  const editingEnabledStates = isEditing && transitionEnum(schema, resource.id ? stateBefore : undefined);
  const panes = [
  ...(isEditing ? [{
    menuItem: "Выбор",
    render: () => (
      <StateDiagram
        schema={schema}
        selectedState1={fieldValue}
        onStateClick={value => {
          if (editingEnabledStates.indexOf(value) !== -1) {
            editorDispatch({type: "CHANGE", fieldName: schema.field, value: value});
            if (onChooseFieldValue) {
              onChooseFieldValue(value);
            }
          }
        }}
        enabledStates={editingEnabledStates}
      />
    ),
  }] : []),
  {
    menuItem: "Правила",
    render: () => (
      <Grid columns={2} stackable className="state-schema">
        <Grid.Column computer={6}>
          <StateChooser
            schema={schema}
            selectedState1={selectedState1}
            setSelectedState1={setSelectedState1}
          />
          <StateTable
            schema={schema}
            selectedState1={selectedState1}
          />
          { !!schema.transitions.find(t => t.event.length) &&
            <Checkbox
              className="show-transition-events"
              name="show-events"
              checked={eventsVisible}
              onChange={() => {
                setEventsVisible(b => !b)
              }}
              value="1"
              label="Отобразить события"
            />
          }
        </Grid.Column>
        <Grid.Column computer={10}>
          <StateDiagram
            schema={schema}
            selectedState1={selectedState1}
            onStateClick={state => setSelectedState1(s => s === state ? null : state)}
            eventsVisible={eventsVisible}
          />
        </Grid.Column>
    </Grid>
    ),
  }];
  return (
    <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
  );
}

function StateChooser({schema, selectedState1, setSelectedState1}) {
  const resource = React.useContext(ResourceContext);
  const {t} = useTranslation(resource.type);
  const allStates = schema.states;

  const itemProps = (value, content) => (
    {key: value, value: value, text: content, content: content}
  );
  const itemContent = value => (
    <span data-value={value}>
      {t(value, {context: schema.field + "_fieldValue"})}
    </span>
  );
  const options = [
    itemProps(null, ""),
    ...allStates.map(val => itemProps(val, itemContent(val))),
  ];
  return (
    <Dropdown selection
      className="state-chooser"
      placeholder={t(schema.field, {context: "fieldLabel"})}
      name={schema.field}
      value={selectedState1 !== null ? selectedState1 : ""}
      options={options}
      onChange={(event, data) => {
        setSelectedState1(data.value);
      }}
    />
  );
}

function StateTable({schema, selectedState1}) {
  const resource = React.useContext(ResourceContext);
  const {t} = useTranslation(resource.type);
  const [selectedTransition, setSelectedTransition] = React.useState([]);
  const transitions = [];
  for (const start of schema.startStates) {
    if (!selectedState1 || selectedState1 === start) {
      transitions.push({
        state_before: {
          id: null, // __start
          label: " ",
        },
        state_after: {
          id: start,
          label: t(start, {context: schema.field + "_fieldValue"}),
        },
      });
    }
  }
  if (selectedState1) {
    transitions.push({
      state_before: {
        id: selectedState1,
        label: t(selectedState1, {context: schema.field + "_fieldValue"}),
      },
      state_after: {
        id: selectedState1,
        label: t(selectedState1, {context: schema.field + "_fieldValue"}),
      },
    });
  }
  for (const transition of schema.transitions) {
    if (!selectedState1 || selectedState1 === transition.state1 || selectedState1 === transition.state2) {
      transitions.push({
        state_before: {
          id: transition.state1,
          label: t(transition.state1, {context: schema.field + "_fieldValue"}),
        },
        state_after: {
          id: transition.state2,
          label: t(transition.state2, {context: schema.field + "_fieldValue"}),
        },
      });
    }
  }
  transitions.sort((t1, t2) => {
    const c1 = t1.state_before.id === null ? 0
      : ( t1.state_before.id !== selectedState1 && t1.state_after.id === selectedState1 ? 1
      : ( t1.state_before.id === t1.state_after.id ? 2
      : 3));
    const c2 = t2.state_before.id === null ? 0
      : ( t2.state_before.id !== selectedState1 && t2.state_after.id === selectedState1 ? 1
      : ( t2.state_before.id === t2.state_after.id ? 2
      : 3));
    return c1 - c2;
  });
  return (
    <Table celled selectable compact unstackable
      className="state-transition-table"
    >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            {t(schema.field, {context: "fieldLabel"})} 1
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t(schema.field, {context: "fieldLabel"})} 2
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      { !!transitions.length &&
      <Table.Body>
        { transitions.map((rowData, rowNum) => {
          const isSelected = selectedTransition[0] === rowData.state_before.id && selectedTransition[1] === rowData.state_after.id;
          return (
            <React.Fragment key={rowNum}>
              <Table.Row
                className={isSelected ? "selected" : ""}
                onClick={() => {
                  if (isSelected)
                    setSelectedTransition([]);
                  else
                    setSelectedTransition([rowData.state_before.id, rowData.state_after.id])
                }}
              >
                <Table.Cell
                  className={rowData.state_before.id === selectedState1 ? "selected" : ""}
                >
                  {rowData.state_before.label}
                </Table.Cell>
                <Table.Cell
                  className={rowData.state_after.id === selectedState1 ? "selected" : ""}
                >
                  {rowData.state_after.label}
                </Table.Cell>
              </Table.Row>
              { isSelected &&
                <Table.Row key={rowNum}
                  className="state-rules-tr"
                >
                  <Table.Cell colSpan={2}>
                    <StateRules
                      schema={schema}
                      state1={rowData.state_before.id}
                      state2={rowData.state_after.id}
                    />
                  </Table.Cell>
                </Table.Row>
              }
            </React.Fragment>
          );
        })}
      </Table.Body>
      }
      { !transitions.length &&
        <Table.Footer>
          <Table.Row>
            <Table.Cell colSpan={2}>
              {t(["Not found", "ui321:Not found"])}
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
      }
    </Table>
  );
}

function StateRules({schema, state1, state2}) {
  const resource = React.useContext(ResourceContext);
  const {t} = useTranslation(resource.type);
  const url = `/${resource.type}/-/stateRules?state1=${JSON.stringify(state1)}&state2=${JSON.stringify(state2)}`;
  const {response, error, isLoading} = useRequest(url);
  if (error) {
    return (
      <Message error content={error} />
    );
  }
  if (isLoading) {
    return (
      <Loading placeholder="paragraph" rows={1} />
    );
  }
  if (!response) {
    return null;
  }
  const fieldLabel = t(schema.field, {context: "fieldLabel"});
  const valueLabel1 = state1 && t(state1, {context: schema.field + "_fieldValue"});
  const valueLabel2 = t(state2, {context: schema.field + "_fieldValue"});
  return (
    <>
      <div className="conditions">{ state1 === null
        ? `Создание, ${fieldLabel} = "${valueLabel2}"`
        : `Редактирование, ${fieldLabel} 1 = "${valueLabel1}", ${fieldLabel} 2 = "${valueLabel2}"`
      }
      </div>
      <pre className="state-rules-description">{response.data}</pre>
    </>
  );
}

function StateDiagram({schema, selectedState1, onStateClick, enabledStates, eventsVisible}) {
  const resource = React.useContext(ResourceContext);
  const {t} = useTranslation(resource.type);
  const idRef = React.useRef(Math.floor(Math.random() * 10000));
  const elemRef = React.useRef();

  let content = ""; // https://mermaid-js.github.io/mermaid/#/flowchart
  content += "flowchart TB\n";
  content += "__start(( ))\n";
  const encodeState = state => "s" + schema.states.indexOf(state) + "_d" + idRef.current;
  const decodeState = React.useCallback(state => schema.states[state.match(/^s([0-9]+)_d[0-9]+$/)[1]], [schema.states]);
  for (const state of schema.states) {
    const stateEncoded = encodeState(state);
    const stateLabel = t(state, {context: schema.field + "_fieldValue"});
    const stateLabel1 = breakLine(stateLabel, 10);
    content += stateEncoded + "(" + quoteLabel(stateLabel1) + ")\n";
    if (!enabledStates || enabledStates.indexOf(state) !== -1) {
      content += "click " + stateEncoded + " onMermaidStateClick_d" + idRef.current + "\n";
    }
  }
  content += "%% start transitions\n";
  for (const start of schema.startStates) {
    content += "__start --> " + encodeState(start) + "\n";
  }
  if (eventsVisible) {
    const transitions = groupTransitionByEvents(schema.transitions);
    for (const transition of transitions) {
      // something strange with react compiler here;
      // cannot use 'else' or ternary operator;
      // 'React Hook "React.useEffect" is called conditionally' error appears
      if (transition.state1.gatewayId)
        content += "g" + transition.state1.gatewayId + "{ }";
      if (!transition.state1.gatewayId)
        content += encodeState(transition.state1.stateId);

      if (transition.eventString)
        content += " -- " + quoteLabel(breakLine(transition.eventString, 15)) +  " --> ";
      if (!transition.eventString)
        content += " --> ";

      if (transition.state2.gatewayId)
        content += "g" + transition.state2.gatewayId + "{ }";
      if (!transition.state2.gatewayId)
        content += encodeState(transition.state2.stateId);
      content += "\n";
    }
  } else {
    for (const transition of schema.transitions) {
      content += encodeState(transition.state1) + " --> " + encodeState(transition.state2) + "\n";
    }
  }
  content += "%% end transitions\n";

  React.useEffect(() => {
    const config = {
      startOnLoad: true,
      flowchart: { useMaxWidth: false, htmlLabels: true },
      securityLevel: 'loose',
    };
    window.mermaid.initialize(config);
  }, []);
  React.useEffect(() => {
    elemRef.current.removeAttribute("data-processed");
    window.mermaid.contentLoaded();
  }, [content]);
  React.useEffect(() => {
    window["onMermaidStateClick_d" + idRef.current] = state => {
      onStateClick(decodeState(state));
    };
  }, [decodeState, onStateClick]);
  return (
    <>
      <pre ref={elemRef} className={`state-diagram state-diagram-d${idRef.current} mermaid`}>
        {content}
      </pre>
      <pre className="state-diagram-code">{content}</pre>
      { selectedState1 &&
      <style>
{`[id^="flowchart-${encodeState(selectedState1)}-"] > rect {
  stroke-width: 4px !important;
}`}
      </style>
      }
      { enabledStates &&
      <style>
{`.state-diagram-d${idRef.current} .node {
  opacity: 0.5;
}
${enabledStates.map(s =>
  `.state-diagram-d${idRef.current} .node[id^="flowchart-${encodeState(s)}-"] {
    opacity: 1;
  }`).join("\n")}
`}
      </style>
      }
    </>
  );
}

function groupTransitionByEvents(transitions) {
  const transitions1 = [];
  let gatewayIdCounter = 0;
  const stateChildNodes = {};
  function getOrCreateNode(s1, events) {
    let node = {
      stateId: s1,
    };
    if (!stateChildNodes[s1]) {
      stateChildNodes[s1] = {};
    }
    let childNodes = stateChildNodes[s1];
    for (let i = 0; i < events.length; i++) {
      const eventString = events[i];
      if (!childNodes[eventString]) {
        const newGatewayId = ++gatewayIdCounter;
        childNodes[eventString] = {
          gatewayId: newGatewayId,
          childNodes: {},
        };
        transitions1.push({
          state1: node,
          state2: {
            gatewayId: newGatewayId,
          },
          eventString: eventString,
        });
      }
      node = {
        gatewayId: childNodes[eventString].gatewayId,
      }
      childNodes = childNodes[eventString].childNodes;
    }
    return node;
  }
  for (const transition of transitions) {
    if (!transition.event.length) {
      transitions1.push({
        state1: {
          stateId: transition.state1,
        },
        state2: {
          stateId: transition.state2,
        },
        eventString: "",
      });
    } else {
      transitions1.push({
        state1: getOrCreateNode(transition.state1, transition.event.slice(0, -1)),
        state2: {
          stateId: transition.state2,
        },
        eventString: transition.event[transition.event.length - 1],
      });
    }
  }
  return transitions1;
}

function breakLine(label, desiredLength) {
  function mergeLines(lines, mergeCondition) {
    if (lines.length <= 1)
      return lines;
    if (mergeCondition(lines[0], lines[1])) {
      return mergeLines([lines[0] + " " + lines[1], ...lines.slice(2)], mergeCondition);
    }
    return [lines[0], ...mergeLines(lines.slice(1), mergeCondition)];
  }
  function fitWidth(l1, l2) {
    return l1.length + l2.length < desiredLength;
  }
  function articlesOnNewLine(l1, l2) {
    return l1.length <= 3 && (fitWidth(l1, l2) || l2.indexOf(" ") === -1);
  }
  function quotesOnNewLine(l1, l2) {
    return fitWidth(l1, l2) && (l1.startsWith('"') || l1.startsWith("'"));
  }

  const words = label.split(" ").filter(l => !!l);
  const lines1 = mergeLines(words, articlesOnNewLine);
  const lines2 = mergeLines(lines1, quotesOnNewLine);
  const lines3 = mergeLines(lines2, fitWidth)
  return lines3.join("\n");
}

function quoteLabel(label) {
  return '"' + (label.replace(/"/g, "&quot;") || " ") + '"';
}
