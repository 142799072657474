import i18n from "i18n.js";
import en_i18n from "./i18n/en.ACLRolesUsers.json";
import ru_i18n from "./i18n/ru.ACLRolesUsers.json";
import FullView from "./FullView.js";

const resourceType = "ACLRolesUsers";

i18n.addResourceBundle("en", resourceType, en_i18n);
i18n.addResourceBundle("ru", resourceType, ru_i18n);

const ACLRolesUsers = {
  resourceType,
  FullView,
  titleField: "id",
  columns: [
    "role_id",
    "user_id",
    "date_modified",
  ],
};

export default ACLRolesUsers
