/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {Dropdown, Icon} from "semantic-ui-react";

const trigger = (field, i, label, t) => field ?
  <span title={`${t("Sorting")} ${i + 1}`}>
    <strong>{label}</strong>
    <Icon name={field[1] === "desc" ? "sort down" : "sort up"} />
  </span> :
  <span>
    {i ? `${t("Sorting")} ${i + 1}` : t("No sorting")}
    <Icon name="sort" />
  </span>

const handleSortChange = (i, sorting, setSorting) => (e, {value}) => {
  if (value) {
    sorting[i] = JSON.parse(value);
  }
  else {
    sorting.splice(i, 1);
  }
  setSorting(sorting.filter((field, i, arr) =>
    field && arr.slice(0, i).every(f => !f || field[0] !== f[0])));
}

function fieldLabel(fieldName, resourceType, t) {
  return t(resourceType + ":" + fieldName, {context: "fieldLabel"});
}

function Sorting(props) {
  const {t} = useTranslation("ui321");
  return (
  [...props.sorting, null].filter((field, i, arr) =>
    field || props.sortableFields.some(n => arr.every(f => !f || f[0] !== n)))
  .map((field, i) =>
  <Dropdown icon={null} key={i} scrolling
    trigger={trigger(field, i, field ? fieldLabel(field[0], props.resourceType, t) : "-", t)}>
    <Dropdown.Menu>
      <Dropdown.Item icon="close" text={t("No sorting")} value=""
        onClick={handleSortChange(i, props.sorting, props.setSorting)}
        active={!field} />
      {props.sortableFields.map(name =>
        <React.Fragment key={name}>
          <Dropdown.Divider />
          <Dropdown.Item
            value={JSON.stringify([name, "asc"])}
            active={JSON.stringify(field) === JSON.stringify([name, "asc"])}
            disabled={props.sorting.slice(0, i).some(f => f[0] === name)}
            onClick={handleSortChange(i, props.sorting, props.setSorting)}>
            <Icon name="sort up" /> {fieldLabel(name, props.resourceType, t)} &ndash; <i>{t("Ascending")}</i>
          </Dropdown.Item>
          <Dropdown.Item
            value={JSON.stringify([name, "desc"])}
            active={JSON.stringify(field) === JSON.stringify([name, "desc"])}
            disabled={props.sorting.slice(0, i).some(f => f[0] === name)}
            onClick={handleSortChange(i, props.sorting, props.setSorting)}>
            <Icon name="sort down" /> {fieldLabel(name, props.resourceType, t)} &ndash; <i>{t("Descending")}</i>
          </Dropdown.Item>
        </React.Fragment>
      )}
    </Dropdown.Menu>
  </Dropdown>
  )
  );
}

function sortToQueryString(sorting) {
  return sorting.map(s => s[1] === "desc" ? "-" + s[0] : s[0]).join(",");
}

function sortsAreEquals(sorting1, sorting2) {
  return JSON.stringify(sorting1) === JSON.stringify(sorting2);
}

export {sortToQueryString, sortsAreEquals};
export default Sorting
