/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {Translation, useTranslation, withTranslation} from "react-i18next";
import {Redirect} from "react-router-dom";
import {Button, Form, Grid, Header, Message, Modal, Segment} from "semantic-ui-react";
import "./Login.css";
import {ApiConfigContext, checkStatus} from "./json/Request.js";

class Login extends React.Component {
  state = {
    redirectToReferrer: false,
  };

  onAuth = (data) => this.props.onAuth(data, () =>
    this.setState({redirectToReferrer: true}) )

  render() {
    const { from } = this.props.route.location.state || { from: { pathname: "/" } };
    const { redirectToReferrer } = this.state;
    const t = this.props.t;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    if (this.props.auth && this.props.auth.username) {
      return <Redirect to={from} />;
      /*
      return (
        <Container text>
          <Segment basic>
            <Message>
              {t("Already logged in")}.<br />
              <Trans i18nKey="Go to the homepage" ns="ui321">
                Go to the <Link to="/">homepage</Link>
              </Trans>.
            </Message>
          </Segment>
        </Container>
      );
      */
    }

    return (
      <Grid className="login" textAlign="center" verticalAlign="middle">
        <Grid.Column className="login">
          <Segment className="login" raised>
            <Header as="h1" size="medium" textAlign="center">
              {this.props.title || t("Log in", {context: "title"})}
            </Header>
            { process.env.REACT_APP_LOGIN_MESSAGE &&
              <Message info content={process.env.REACT_APP_LOGIN_MESSAGE} /> }
            <LoginForm auth={this.props.auth} onAuth={this.onAuth} error={this.props.error} />
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

function LoginModal(props) {
  const {t} = useTranslation("ui321");
  return (
    <Modal open={props.open} className="login" onClose={props.onClose} closeIcon closeOnDimmerClick={false}>
      <Modal.Header>{t("Log in", {context: "title"})}</Modal.Header>
      <Modal.Content>
        <LoginForm auth={props.auth} onAuth={props.onAuth} error={props.error} />
      </Modal.Content>
    </Modal>
  );
}

class LoginForm extends React.Component {
  static contextType = ApiConfigContext;

  state = {
    username: this.props.auth.username || "",
    password: "",
    error: this.props.error || ""
  };

  validateForm() {
    const {username, password, error} = this.state;
    return username.length > 0 && password.length > 0 && error !== undefined;
  }

  handleChange = event => this.setState({
      [event.target.name]: event.target.value
    })

  handleSubmit = event => {
    event.preventDefault();
    const apiConfig = this.context;
    this.setState({error: undefined}, () =>
      fetch(`${apiConfig.urlPrefix}/auth`, {
        ...apiConfig.init,
        method: 'POST',
        body: JSON.stringify({
          "username": this.state.username,
          "password": this.state.password,
        })
      })
      .then(checkStatus)
      .then(response => response.json())
      .then(this.props.onAuth)
      .catch(error => {
        this.setState({error: error.message || "Error"})
      })
    );
  }

  render() {
    const {username, password, error} = this.state;
    return (
      <Translation ns="ui321">{ t =>
      <Form onSubmit={this.handleSubmit} error={!!error}>
        <Form.Input
          name="username"
          value={username}
          placeholder={t("Username")}
          fluid icon="user" iconPosition="left"
          onChange={this.handleChange}
          required
          autoFocus
          />
        <Form.Input
          name="password"
          value={password}
          placeholder={t("Password")}
          type="password"
          fluid icon="key" iconPosition="left"
          onChange={this.handleChange}
          required
          />
        { error &&
          <Message error content={error} /> }
        <Button type="submit" fluid primary disabled={!this.validateForm()} loading={error === undefined}>
          {t("Log in")}
        </Button>
      </Form>
      }</Translation>
    );
  }
}

export {LoginModal};
export default withTranslation("ui321")(Login)
