/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import Field from "ui321/fields/Field.js";
import {ResourceContext} from "ui321/single/SingleResource.js";

function FieldCell(props) {
  return (
    <ResourceContext.Provider value={props.rowData}>
      <Field name={props.columnKey} fieldProps={props.fieldProps} />
    </ResourceContext.Provider>
  );
}

export default FieldCell
