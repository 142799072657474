import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Dropdown, Icon, Message, Modal} from "semantic-ui-react";
import {fieldStateSchema, StateSchema} from "ui321/biz/StateSchema.js";
import JsonApiSchema from "ui321/json/JsonApiSchema.js";
import SingleResource, {ResourceContext, ResourceDispatchContext} from "ui321/single/SingleResource.js";
import Editable from "ui321/single/Editable.js";
import Field from "ui321/fields/Field.js";
import {useRequest} from "ui321/Request.js";
import Loading from "ui321/Loading.js";

export function StateEventToolbar(props) {
  const resource = React.useContext(ResourceContext);
  const {t} = useTranslation(resource.type);
  const [selectedSchema, setSelectedSchema] = React.useState(null);
  const stateSchema = fieldStateSchema(((resource || {}).meta || {}).stateSchemas || []);
  if (!stateSchema) {
    return null;
  }
  const fieldName = stateSchema.field;
  const fieldValue = (resource.attributes || {})[fieldName];
  return (
    <>
      <Button.Group className="state-event-buttons">
        <Button type="button"
          active
          title={t(fieldName, {context: "fieldLabel"})}
          onClick={() => setSelectedSchema(stateSchema)}
          >
          <Icon name="sitemap" /> {t(fieldValue, {context: fieldName + "_fieldValue"})}
        </Button>
        { props.eventFields.filter(name => !JsonApiSchema.isReadOnly(name, resource.meta.jsonSchema)).map(name =>
          <StateEventButton key={name} field={name} />
        )}
      </Button.Group>
      <Modal
        closeIcon
        open={!!selectedSchema}
        onClose={() => setSelectedSchema(null)}
        size="fullscreen"
        centered={false}
      >
        <Modal.Content>
          <StateSchema
            schema={selectedSchema}
            onChooseFieldValue={() => setSelectedSchema(null)}
          />
        </Modal.Content>
      </Modal>
    </>
  );
}

function StateEventButton(props) {
  const resource = React.useContext(ResourceContext);
  const {t} = useTranslation(resource.type);
  const stateEventDispatch = React.useContext(StateEventDispatchContext);
  const fieldName = props.field;
  const attrSchemaRaw = JsonApiSchema.findAttributeSchema(fieldName, resource.meta.jsonSchema);
  const attrSchema = JsonApiSchema.fromNullable(attrSchemaRaw);
  if (attrSchema && attrSchema.enum) {
    const options = attrSchema.enum.map(v => ({
      key: v,
      value: v,
      text: t(v, {context: fieldName + "_fieldValue"}),
    }));
    return (
      <Dropdown
        className={`button ${fieldName}-state-event-button state-event-button state-event-dropdown`}
        simple item
        text={t(fieldName, {context: "fieldLabel"})}
        options={options}
        onChange={(event, dropdown) => {
          stateEventDispatch({type: "SET", value: {name: fieldName, value: dropdown.value}});
        }}
      />
    )
  }
  return (
    <Button type="button"
      className={`${fieldName}-state-event-button state-event-button`}
      onClick={() => {
        stateEventDispatch({type: "SET", value: {name: fieldName, value: true}});
      }}
    >
      {t(fieldName, {context: "fieldLabel"})}
    </Button>
  );
}

export function EventForm(props) {
  const resource = React.useContext(ResourceContext);
  const resourceDispatch = React.useContext(ResourceDispatchContext);
  const stateEvent = React.useContext(StateEventContext);
  const stateEventDispatch = React.useContext(StateEventDispatchContext);
  if (!stateEvent.name || !stateEvent.value) {
    return null;
  }
  return (
    <div className="event-form">
    <SingleResource
      key={`${stateEvent.name}-_-${stateEvent.value}`}
      resourceType={resource.type}
      id={resource.id}
      resource={resource}
      editing
      fullView={props.fullView || EventFormContent}
      constData={{
        attributes: {
          [stateEvent.name]: stateEvent.value,
        },
      }}
      onEditingCancel={() => {
        stateEventDispatch({type: "SET", value: {name: null, value: null}});
      }}
      onUpdate={() => {
        stateEventDispatch({type: "SET", value: {name: null, value: null}});
        resourceDispatch(["REFRESH"]);
      }}
    />
    </div>
  );
}

export function EventFormContent() {
  const stateEvent = React.useContext(StateEventContext);
  return (
    <Editable buttonsAt="bottom">
      <Field name={stateEvent.name} />
    </Editable>
  );
}

export function NextStateAfterEvent({stateField}) {
  const resource = React.useContext(ResourceContext);
  const stateEvent = React.useContext(StateEventContext);
  const {t} = useTranslation(resource.type);
  const stateBefore = (resource.attributes || {})[stateField];
  const url = `/${resource.type}/-/nextStates?stateField=${stateField}&stateBefore=${JSON.stringify(stateBefore)}&eventField=${stateEvent.name}&eventAfter=${JSON.stringify(stateEvent.value)}`;
  const {response, error, isLoading} = useRequest(url);
  if (error) {
    return (
      <Message error content={error} />
    );
  }
  if (isLoading) {
    return (
      <Loading placeholder="paragraph" rows={1} />
    );
  }
  if (!((response || {}).data || []).length) {
    return null;
  }
  return (
    <div className="next-state-after-event">
      Далее {t(stateField, {context: "fieldLabel"})} {response.data.map((s2, i) => (
        <React.Fragment key={i}>
          {i > 0 && " или "}
          <strong>{t(s2, {context: stateField + "_fieldValue"})}</strong>
        </React.Fragment>
      ))}
    </div>
  );
}

export function useStateEvent() {
  return React.useReducer(stateEventReducer, {name: null, value: null});
}

export const StateEventContext = React.createContext({});
export const StateEventDispatchContext = React.createContext(() => {
  console.warn("Running default StateEventDispatchContext");
});

function stateEventReducer(state, action) {
  if (action.type === "SET") {
    return typeof action.value === "function" ? action.value(state) : action.value;
  }
  throw new Error(`Unknown action type "${action.type}" in stateEventReducer.`);
}
