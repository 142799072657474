import React from "react";
import {useTranslation} from "react-i18next";
import {Segment} from "semantic-ui-react";
import Folder from "ui321/Folder.js";
import {ButtonEdit, ButtonDelete} from "ui321/single/Buttons.js";
import {ResourceContext, ResourceEditorContext} from "ui321/single/SingleResource.js";
import Editable from "ui321/single/Editable.js";
import Field from "ui321/fields/Field.js";
import {ResourceAuditButton} from "app/resources/Audit/Audit.js";
import RecordGroupsFolder from "app/resources/SecurityGroupsRecords/RecordGroupsFolder.js";
import "./Orders.css";

function FullView(props) {
  const {t} = useTranslation("Orders");
  return (
    <Editable>
      <OrderButtons />
      <Folder title={t("Overview", {context: "folderTitle"})}>
        <Field name="name" />
        <Field name="status" />
        <Field name="order_type" />
        <Field name="account_name" />
        <Field name="amount" fieldProps={{locale: ''}} />
        <Field name="currency_code" />
        <Field name="amount_converted" fieldProps={{locale: ''}} />
        <Field name="date_closed" />
        <Field name="description" />
        <Field name="manager_user_id" />
      </Folder>
      <Folder title={t("Check", {context: "folderTitle"})}>
        <Field name="check_result" />
        <Field name="check_comment" />
        <Field name="check_user_id" />
        <Field name="check_date" />
      </Folder>
      <Folder title={t("Other", {context: "folderTitle"})}>
        <Field name="date_entered" />
        <Field name="created_by" />
        <Field name="date_modified" />
        <Field name="modified_user_id" />
        <Field name="assigned_user_id" />
        <Field name="error_message" />
      </Folder>
      <RecordGroupsFolder />
      {/* <Folder title={t("Groups again", {context: "folderTitle"})} folded> //groups subpanel
        <Field name="record_id-securitygroupsrecords" />
      </Folder> */}
    </Editable>
  );
}

function OrderButtons(props) {
  const resource = React.useContext(ResourceContext);
  const editor = React.useContext(ResourceEditorContext);
  const isEditing = editor.editing;
  if (!resource) {
    return null;
  }
  if (isEditing) {
    return null;
  }
  return (
    <Segment basic vertical className="single-view-buttons">
      <ButtonEdit />
      <ResourceAuditButton />
      <ButtonDelete />
    </Segment>
  );
}

export default FullView
