import i18n from "i18n.js";
import en_i18n from "./i18n/en.Calls.json";
import ru_i18n from "./i18n/ru.Calls.json";
import FullView from "./FullView.js";
import FetchCreationAccess from "app/FetchCreationAccess.js";

const resourceType = "Calls";

i18n.addResourceBundle("en", resourceType, en_i18n);
i18n.addResourceBundle("ru", resourceType, ru_i18n);

const Calls = {
  resourceType,
  FullView,
  titleField: "name",
  defaultSort: [["date_entered", "desc"]],
  fieldNames: [
    "name",
    "direction",
    "status",
    "date_start",
    "duration_hours",
    "parent",
    "assigned_user_id",
    "email_reminder_sent",
    "description",
    "date_entered",
    "created_by",
    "date_modified",
    "modified_user_id",
  ],
  itemViewFields: {
    "meta": [
        "status",
        "assigned_user_id"
    ],
    "description": [
        "name",
        "duration_hours",
        "date_start",
        "direction",
        "parent",
        "description"
    ],
    "extra": [
        "date_entered",
        "created_by",
        "date_modified",
        "modified_user_id",
    ]
  },
  creationAccess: FetchCreationAccess,
  reverseRelationships: {
    "record_id-securitygroupsrecords": "record_id",
  },
};

export default Calls
