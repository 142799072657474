/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {Table} from "semantic-ui-react";

function DataTable(props) {
  const {t} = useTranslation("ui321");
  return (
    <div className="data-table-parent">
    <Table celled striped selectable compact unstackable
      className={(props.className ? props.className + " " : "") + "data-table"}
    >
      <Table.Header>
        <Table.Row>
          { props.columns.map(column =>
          <Table.HeaderCell key={column.key} className={column.className}>
            { typeof column.renderHead === "string"
              ? column.renderHead
              : column.renderHead && React.createElement(column.renderHead, {
                columnKey: column.key,
              })
            }
          </Table.HeaderCell>
          )}
        </Table.Row>
      </Table.Header>
      { !!props.data.length &&
      <Table.Body>
        { props.data.map((rowData, rowNum) =>
        <Table.Row key={props.keyFunc ? props.keyFunc(rowData) : rowData.id || rowNum}
          {...(typeof props.rowProps === "function" ? props.rowProps(rowData) : props.rowProps)}
        >
          { props.columns.map(column =>
          ((props.rowSpans || [])[rowNum] || {})[column.key] !== -1 &&
          <Table.Cell key={column.key}
            className={column.className}
            rowSpan={((props.rowSpans || [])[rowNum] || {})[column.key]}
          >
            { React.createElement(column.renderCell, {
              rowData: rowData,
              columnKey: column.key,
            })}
          </Table.Cell>
          )}
        </Table.Row>
        )}
      </Table.Body>
      }
      { !props.data.length &&
        <Table.Footer>
          <Table.Row>
            <Table.Cell colSpan={props.columns.length}>
              {props.emptinessLabel || t("Not found")}
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
      }
    </Table>
    </div>
  );
}

function dataRowSpans(dataRows, columnKeys) {
  const rowSpans = dataRows.map(row => columnKeys.reduce((obj, key) => ({...obj, [key]: 1}), {}));
  for (const column of columnKeys) {
    for (let iRow = dataRows.length - 2; iRow >= 0; iRow--) {
      if (dataRows[iRow][column] === dataRows[iRow + 1][column]) {
        rowSpans[iRow][column] += rowSpans[iRow + 1][column];
        rowSpans[iRow + 1][column] = -1;
      }
    }
  }
  return rowSpans;
}

export {dataRowSpans};
export default DataTable
