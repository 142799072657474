import React from "react";
import {useTranslation} from "react-i18next";
import Buttons from "ui321/single/Buttons.js";
import Editable from "ui321/single/Editable.js";
import Folder from "ui321/Folder.js";
import Field from "ui321/fields/Field.js";

function FullView(props) {
  const {t} = useTranslation("SecurityGroups");
  return (
    <Editable>
      <Buttons />
      <Folder title={t("Overview", {context: "folderTitle"})}>
        <Field name="name" />
      </Folder>
      <Folder title={t("Other", {context: "folderTitle"})}>
        <Field name="date_entered" />
        <Field name="created_by" />
        <Field name="date_modified" />
        <Field name="modified_user_id" />
      </Folder>
    </Editable>
  );
}

export default FullView
