/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {Message} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {ResourceContext} from "./SingleResource.js";

function Errors(props) {
  const {t} = useTranslation("ui321");
  const resource = React.useContext(ResourceContext);
  if (!props.errors || !props.errors.length) {
    return null;
  }
  return (
    <Message error>
      { props.title &&
        <p className="title">{props.title}</p> }
      <Message.List>
        {props.errors.map((err, key) =>
          <Message.Item key={key} data-field={err.fieldName}>
            { !!err && !!err.fieldName &&
              <span className="error-field">
                {t(resource.type + ":" + err.fieldName, {context: "fieldLabel"})}
              </span>
            }
            {' '}
            <span className="error-text">
              {(err || {}).message || err}
            </span>
          </Message.Item>
        )}
      </Message.List>
    </Message>
  );
}

export default Errors
