/*
 * Copyright Hardsoft321, Ltd.
 * Licensed under GPLv3 (https://hardsoft321.org/license/)
 * Author Evgeny Pervushin <pea@lab321.ru>
 */

import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Dimmer, Dropdown, Input} from "semantic-ui-react";
import {FormDataContext, FormDataDispatchContext} from "ui321/form/FormData.js";

function FormInput(props) {
  const formDataDispatch = React.useContext(FormDataDispatchContext);
  const formData = React.useContext(FormDataContext);
  return (
    <Input {...props}
      value={formData === undefined || formData === null ? "" : formData}
      onChange={(event, data) => {
        formDataDispatch(() => data.value);
      }}
    />
  );
}

function FormDropdown(props) {
  const formDataDispatch = React.useContext(FormDataDispatchContext);
  const formData = React.useContext(FormDataContext);
  return (
    <Dropdown {...props}
      value={formData === undefined || formData === null ? "" : formData}
      onChange={(event, data) => {
        formDataDispatch(() => data.value);
      }}
    />
  );
}

function FormArrayItem(props) {
  return (
    <div className="array-item-field form-field dimmable">
      { props.removable &&
      <div className="hover-buttons">
        <RemoveFormDataButton />
        <Dimmer />
      </div>
      }
      {props.children}
    </div>
  );
}

function RemoveFormDataButton(props) {
  const {t} = useTranslation("ui321");
  const formDataDispatch = React.useContext(FormDataDispatchContext);
  const handleClick = () => {
    formDataDispatch({type: "REMOVE"});
  };
  return (
    <Button type="button"
      className="remove-button"
      onClick={handleClick}
      icon="close"
      title={t("Delete")}
    />
  );
}

export {FormInput, FormDropdown, FormArrayItem, RemoveFormDataButton};
